import * as React from "react";
import {
  StyleSheet,
  View,
  Pressable,
  Text,
  ScrollView,
  TextInput,
  Alert,
} from "react-native";
import { useState, useEffect } from "react";
import { Image } from "expo-image";
import { DrawerNavigationProp } from "@react-navigation/drawer";
import { useNavigation, ParamListBase, useRoute } from "@react-navigation/native";
import { StackNavigationProp } from "@react-navigation/stack";
import { Color, Padding, Border, FontFamily, FontSize } from "../GlobalStyles";
import { supabase } from '../lib/supabase';
import { getLocationBasic } from "../lib/loc";
import { useOrg } from "../lib/useOrg";

const AddDeviceDetails = () => {
  const navigation = useNavigation<DrawerNavigationProp<ParamListBase>>();
  const route = useRoute();
  const { currentOrganization } = useOrg() || {};

  const [locationDetails, setLocationDetails] = useState(null);

  const locationid = route.params?.locationId;
  const deviceid = route.params?.deviceId;

  useEffect(() => {
    async function fetchData() {
      try {
        const details = await getLocationBasic(locationid);
        setLocationDetails(details);
      } catch (err) {
        //setError('Failed to fetch device details');
        console.error("Failed to fetch device details:", err);
      } finally {
        //setLoading(false);
      }
    }

    fetchData();
  }, [locationid]);

  const [deviceData, setDeviceData] = useState({
    deviceid: deviceid,
    orgid: currentOrganization?.orgid || '',
    locationid: locationid,
    devicename: '',
    devicedesc: '',
  });

  console.log("dev d ", deviceData);

  useEffect(() => {

    if (currentOrganization) {
      setDeviceData(prev => ({
        ...prev,
        orgid: currentOrganization.orgid,
      }));
    } else {

    }
  }, [currentOrganization, navigation]);

  const handleInputChange = (field, value) => {
    setDeviceData(prev => ({ ...prev, [field]: value }));
  };

  console.log("Organization ID in Location Data: ", deviceData.orgid);

  const handleSubmit = async () => {
    if (!deviceData.devicename.trim()) {
      Alert.alert('Validation Error', 'Device name is required', [{ text: 'OK' }]);
      return;
    }
  
    const deviceEntry = {
      deviceid: deviceData.deviceid,
      orgid: deviceData.orgid,
      locationid: deviceData.locationid,
      devicename: deviceData.devicename,
      devicedesc: deviceData.devicedesc,
    };
  
    try {

      const { data: existingDevice, error: fetchError } = await supabase
        .from('devices')
        .select('devicenames, locationids')
        .eq('deviceid', deviceData.deviceid)
        .single();
  
      if (fetchError) throw fetchError;
  
      const deviceNameSet = new Set(existingDevice ? existingDevice.devicenames : []);
      deviceNameSet.add(deviceData.devicename);
      deviceEntry.devicenames = Array.from(deviceNameSet); 
  
      const locationIdSet = new Set(existingDevice ? existingDevice.locationids : []);
      locationIdSet.add(deviceData.locationid);
      deviceEntry.locationids = Array.from(locationIdSet); 

      const { data, error } = await supabase
        .from('devices')
        .upsert(deviceEntry, { onConflict: "deviceid" });
  
      if (error) throw error;

      // update loc array of dev uuid
      const { data: locationData, error: locationDataError } = await supabase
        .from('locations')
        .select('devices')
        .eq('locationid', deviceData.locationid)
        .single();

      if (locationDataError) throw locationDataError;

      const locationDevices = locationData.devices || [];
      if (!locationDevices.includes(deviceData.deviceid)) {
        locationDevices.push(deviceData.deviceid);
        const { error: updateLocationError } = await supabase
          .from('locations')
          .update({ devices: locationDevices })
          .eq('locationid', deviceData.locationid);
      
        if (updateLocationError) throw updateLocationError;
      }

      // update org array of dev uuid
      const { data: orgData, error: orgDataError } = await supabase
        .from('orgs')
        .select('devices')
        .eq('orgid', deviceData.orgid)
        .single();

      if (orgDataError) throw orgDataError;

      const orgDevices = orgData.devices || [];
      if (!orgDevices.includes(deviceData.deviceid)) {
        orgDevices.push(deviceData.deviceid);
        const { error: updateOrgError } = await supabase
          .from('orgs')
          .update({ devices: orgDevices })
          .eq('orgid', deviceData.orgid);

        if (updateOrgError) throw updateOrgError;
      }

      navigation.navigate("AddDeviceConfirmation", { deviceid: deviceData.deviceid });
  
    } catch (e) {
      console.log('Error', `Failed to update device: ${e.message}`);
      Alert.alert('Error', `Failed to update device: ${e.message}`, [{ text: 'OK' }]);
    }
  };



  /*


  if (isLoading) {
    return (
      <View style={styles.centered}>
        <ActivityIndicator size="large" />
      </View>
    );
  }

  if (error) {
    return (
      <View style={styles.centered}>
        <Text>{error}</Text>
      </View>
    );
  }

  */

  console.log("locid ", locationid, " devid ", deviceid);

  console.log(JSON.stringify(locationDetails));

  return (
    <View style={styles.addDevicedetails}>
      <View style={styles.top}>
        <View style={[styles.statusbar, styles.topbarPosition]} />
        <View style={[styles.topbar, styles.topbarFlexBox]}>
          <View style={styles.topbarinner}>
          <Pressable
            style={{
              width: 60, // Set a specific width for the button
              height: 60, // Set a specific height for the button
              justifyContent: 'center',
              alignItems: 'center',
              padding: 10, // Optional: additional padding
              backgroundColor: 'transparent', // Ensure background is transparent
            }}
            onPress={() => navigation.goBack()}
          >
            <Image
              style={{
                width: '60%', // Make the image fill the button
                height: '60%', // Make the image fill the button
                resizeMode: 'contain', // Ensure the image maintains its aspect ratio
              }}
              source={require("../assets/calender.png")}
            />
          </Pressable>
            <View style={styles.details}>
              <Text style={[styles.toptext, styles.toptextFlexBox]}>
                Setup 6e43xY
              </Text>
            </View>
          </View>
        </View>
      </View>
      <ScrollView
        style={styles.frame}
        showsVerticalScrollIndicator={false}
        showsHorizontalScrollIndicator={false}
        contentContainerStyle={styles.frameScrollViewContent}
      >
        <View
          style={styles.locationbackcard}
        >
          <View style={styles.content}>
            <View style={styles.arrowleftParent}>
              <Pressable
                style={styles.arrowleft}
                onPress={() =>
                  navigation.navigate("DrawerRoot", { screen: "AddSelectLocation" })
                }
              >
                <Image
                  style={styles.icon}
                  contentFit="cover"
                  source={require("../assets/arrowleft.png")}
                />
              </Pressable>
              <Image
                style={styles.eventimgIcon}
                contentFit="cover"
                source={require("../assets/eventimg.png")}
              />
              <View style={styles.details1}>
                <Text style={[styles.locationName, styles.addDeviceFlexBox]}>
                  {locationDetails?.locationname}
                </Text>
                <View style={styles.yinzStBitsburghPa15001Wrapper}>
                  <Text
                    style={[styles.yinzStBitsburgh, styles.addDeviceFlexBox]}
                  >
                    {locationDetails?.locationaddress}, {locationDetails?.locationcity}, {locationDetails?.locationstate} {locationDetails?.locationzip}
                  </Text>
                </View>
              </View>
            </View>
          </View>
        </View>

        {/*
        <View style={[styles.errorcard, styles.errorcardBorder]}>
          <Text style={[styles.toptext, styles.toptextFlexBox]}>
            Error Title
          </Text>
          <Text style={[styles.errorBody, styles.toptextFlexBox]}>
            Error Body
          </Text>
        </View>
        */}


        <Text style={styles.deviceDetails}>Device Details</Text>
        <View style={[styles.devicedetailsform, styles.errorcardBorder]}>
          <Text style={[styles.name, styles.nameTypo]}>Device Name</Text>
          <TextInput
            style={[styles.devicename, styles.detailSpaceBlock]}
            placeholder="Machine Name"
            placeholderTextColor="rgba(60, 60, 67, 0.3)"
            value={deviceData.devicename}
            onChangeText={text => handleInputChange('devicename', text)}
          />
          <Text style={[styles.detail, styles.detailSpaceBlock]}>Description</Text>
          <TextInput
            style={[styles.devicename, styles.detailSpaceBlock]}
            placeholder="Description"
            placeholderTextColor="rgba(60, 60, 67, 0.3)"
            value={deviceData.devicedesc}
            onChangeText={text => handleInputChange('devicedesc', text)}
          />
          {/*}
          <Text style={[styles.detail, styles.detailSpaceBlock]}>Detail</Text>
          <TextInput
            style={[styles.devicename, styles.detailSpaceBlock]}
            placeholder="  Name"
            placeholderTextColor="rgba(60, 60, 67, 0.3)"
          />
          <Text style={[styles.detail, styles.detailSpaceBlock]}>Detail</Text>
          <TextInput
            style={[styles.devicename, styles.detailSpaceBlock]}
            placeholder="  Name"
            placeholderTextColor="rgba(60, 60, 67, 0.3)"
          />
          <Text style={[styles.detail, styles.detailSpaceBlock]}>Detail</Text>
          <TextInput
            style={[styles.devicename, styles.detailSpaceBlock]}
            placeholder="  Name"
            placeholderTextColor="rgba(60, 60, 67, 0.3)"
          />*/}
        </View>
        <Pressable
          style={[styles.adddevicebutton, styles.errorcardBorder]}
          onPress={handleSubmit}
        >
          <Text style={[styles.addDevice, styles.addDeviceFlexBox]}>
            Add Device
          </Text>
        </Pressable>
      </ScrollView>
    </View>
  );
};

const styles = StyleSheet.create({
  frameScrollViewContent: {
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "flex-start",
  },
  topbarPosition: {
    backgroundColor: Color.colorGold,
    left: "0%",
    right: "0%",
    position: "absolute",
    width: "100%",
  },
  topbarFlexBox: {
    overflow: "hidden",
    alignItems: "center",
  },
  toptextFlexBox: {
    textAlign: "left",
    letterSpacing: 0,
  },
  addDeviceFlexBox: {
    textAlign: "left",
    letterSpacing: 0,
  },
  errorcardBorder: {
    marginTop: 11,
    padding: Padding.p_base,
    borderWidth: 1.3,
    borderColor: Color.colorGainsboro_100,
    borderStyle: "solid",
    borderRadius: Border.br_smi,
    alignSelf: "stretch",
  },
  nameTypo: {
    lineHeight: 20,
    color: Color.colorGray_200,
    textAlign: "left",
    fontFamily: FontFamily.interSemiBold,
    fontWeight: "600",
    letterSpacing: 0,
    fontSize: FontSize.size_xl,
  },
  detailSpaceBlock: {
    marginTop: 10,
    alignSelf: "stretch",
  },
  statusbar: {
    height: "62.77%",
    top: "0%",
    bottom: "37.23%",
    alignItems: "flex-end",
    justifyContent: "center",
  },
  icon: {
    height: "100%",
    width: "100%",
  },
  hamburger: {
    height: 15,
    width: 20,
  },
  toptext: {
    color: Color.labelColorLightPrimary,
    fontFamily: FontFamily.interSemiBold,
    fontWeight: "600",
    lineHeight: 25,
    textAlign: "left",
    letterSpacing: 0,
    fontSize: FontSize.size_xl,
  },
  details: {
    paddingHorizontal: Padding.p_mini,
    paddingVertical: 0,
    flexDirection: "row",
    flex: 1,
  },
  topbarinner: {
    height: 35,
    justifyContent: "space-between",
    paddingLeft: Padding.p_8xs,
    paddingRight: Padding.p_xs,
    paddingBottom: Padding.p_3xs,
    flexDirection: "row",
    alignItems: "center",
    alignSelf: "stretch",
  },
  topbar: {
    height: "37.23%",
    top: "62.77%",
    bottom: "0%",
    alignItems: "center",
    backgroundColor: Color.colorGold,
    left: "0%",
    right: "0%",
    position: "absolute",
    width: "100%",
  },
  top: {
    height: 94,
    alignSelf: "stretch",
  },
  arrowleft: {
    height: 16,
    width: 20,
  },
  eventimgIcon: {
    borderRadius: Border.br_12xs,
    width: 30,
    height: 30,
    marginLeft: 8,
  },
  locationName: {
    fontSize: FontSize.size_smi,
    lineHeight: 13,
    fontFamily: FontFamily.interBold,
    fontWeight: "700",
    textAlign: "left",
    color: Color.labelColorLightPrimary,
  },
  yinzStBitsburgh: {
    fontSize: FontSize.size_xs,
    lineHeight: 15,
    color: Color.colorGray_300,
    fontFamily: FontFamily.interBold,
    fontWeight: "700",
    textAlign: "left",
  },
  yinzStBitsburghPa15001Wrapper: {
    width: 260,
    marginTop: 2,
    flexDirection: "row",
  },
  details1: {
    marginLeft: 8,
  },
  arrowleftParent: {
    width: 314,
    flexDirection: "row",
    alignItems: "center",
  },
  content: {
    flexDirection: "row",
    alignItems: "center",
    flex: 1,
  },
  locationbackcard: {
    padding: Padding.p_base,
    borderWidth: 1.3,
    borderColor: Color.colorGainsboro_100,
    borderStyle: "solid",
    borderRadius: Border.br_smi,
    backgroundColor: Color.systemBackgroundLightPrimary,
    flexDirection: "row",
    overflow: "hidden",
    alignSelf: "stretch",
  },
  errorBody: {
    fontSize: FontSize.size_base,
    lineHeight: 18,
    color: Color.labelColorLightSecondary,
    marginTop: 20,
    fontFamily: FontFamily.interRegular,
    textAlign: "left",
    letterSpacing: 0,
  },
  errorcard: {
    backgroundColor: Color.systemBackgroundLightPrimary,
    marginTop: 11,
    alignItems: "center",
    justifyContent: "center",
  },
  deviceDetails: {
    color: Color.colorGray_200,
    marginTop: 11,
    textAlign: "left",
    fontFamily: FontFamily.interSemiBold,
    fontWeight: "600",
    lineHeight: 25,
    letterSpacing: 0,
    fontSize: FontSize.size_xl,
  },
  name: {
    alignSelf: "stretch",
  },
  devicename: {
    borderRadius: Border.br_3xs,
    backgroundColor: Color.fillColorLightTertiary,
    paddingHorizontal: Padding.p_5xs,
    paddingVertical: Padding.p_6xs,
    fontSize: FontSize.size_mid,
    fontFamily: FontFamily.interRegular,
    flexDirection: "row",
    alignItems: "center",
  },
  detail: {
    lineHeight: 20,
    color: Color.colorGray_200,
    textAlign: "left",
    fontFamily: FontFamily.interSemiBold,
    fontWeight: "600",
    letterSpacing: 0,
    fontSize: FontSize.size_xl,
  },
  devicedetailsform: {
    backgroundColor: Color.systemBackgroundLightPrimary,
    marginTop: 11,
  },
  addDevice: {
    lineHeight: 22,
    fontWeight: "500",
    fontFamily: FontFamily.interMedium,
    color: Color.systemBackgroundLightPrimary,
    textAlign: "center",
    fontSize: FontSize.size_xl,
  },
  adddevicebutton: {
    backgroundColor: Color.colorDeepskyblue,
    height: 43,
    flexDirection: "row",
    alignItems: "center",
    overflow: "hidden",
    justifyContent: "center",
  },
  frame: {
    padding: Padding.p_2xs,
    alignSelf: "stretch",
    flex: 1,
  },
  addDevicedetails: {
    backgroundColor: Color.colorGhostwhite,
    width: "100%",
    flex: 1,
  },
});

export default AddDeviceDetails;
