import * as React from "react";
import { StyleProp, ViewStyle, StyleSheet, Text, Pressable } from "react-native";
import { Image } from "expo-image";
import { FontSize, FontFamily, Color } from "../GlobalStyles";
import { supabase } from '../lib/supabase';

export type MenuItem10Type = {
  style?: StyleProp<ViewStyle>;
};

const MenuItem10 = ({ style }: MenuItem10Type) => {
  return (
    <Pressable 
        style={[styles.menuItem, style]}
        onPress={() => supabase.auth.signOut()}>
      <Image
        style={styles.calenderIcon}
        contentFit="cover"
        source={require("../assets/calender.png")}
      />
      <Text style={styles.logOut}>Log Out</Text>
    </Pressable>
  );
};

const styles = StyleSheet.create({
  calenderIcon: {
    width: 24,
    height: 24,
    overflow: "hidden",
  },
  logOut: {
    fontSize: FontSize.size_base,
    lineHeight: 24,
    fontWeight: "500",
    fontFamily: FontFamily.robotoMedium,
    color: Color.black,
    textAlign: "left",
    marginLeft: 16,
  },
  menuItem: {
    flexDirection: "row",
    alignItems: "center",
    marginTop: 10
  },
});

export default MenuItem10;
