/* fonts */
export const FontFamily = {
  interMedium: "Inter-Medium",
  interBold: "Inter-Bold",
  interSemiBold: "Inter-SemiBold",
  interRegular: "Inter-Regular",
  robotoMedium: "Roboto-Medium",
  robotoBold: "Roboto-Bold",
};
/* font sizes */
export const FontSize = {
  size_xl: 20,
  size_smi: 13,
  size_mini: 15,
  size_base: 16,
  size_xs: 12,
  size_mid: 17,
  size_sm: 14,
  size_2xs: 11,
};
/* Colors */
export const Color = {
  colorGhostwhite: "#f2f2f7",
  colorWhitesmoke: "#ededed",
  colorGainsboro_100: "#e5e5e5",
  colorGainsboro_200: "#d9d9d9",
  colorGray_100: "#8a8a8e",
  black: "#191919",
  fillColorLightTertiary: "rgba(118, 118, 128, 0.12)",
  colorGray_200: "rgba(0, 0, 0, 0.6)",
  colorGray_300: "rgba(0, 0, 0, 0.5)",
  systemBackgroundLightPrimary: "#fff",
  labelColorLightSecondary: "rgba(60, 60, 67, 0.6)",
  labelColorLightPrimary: "#000",
  colorGold: "#edd820",
  colorLightgray: "#cfcfcf",
  colorDeepskyblue: "#47abf4",
  colorDimgray_100: "#585858",
  colorDimgray_200: "#565656",
};
/* Paddings */
export const Padding = {
  p_2xs: 11,
  p_base: 16,
  p_8xs: 5,
  p_3xs: 10,
  p_mini: 15,
  p_xs: 12,
  p_5xs: 8,
  p_6xs: 7,
};
/* Margins */
export const Margin = {
  m_9xl: 28,
};
/* border radiuses */
export const Border = {
  br_smi: 13,
  br_12xs: 1,
  br_3xs: 10,
};
