import * as React from "react";
import { StyleSheet, View, Pressable, Text, ScrollView } from "react-native";
import { Image } from "expo-image";
import { DrawerNavigationProp } from "@react-navigation/drawer";
import { useNavigation, ParamListBase } from "@react-navigation/native";
import { Color, FontFamily, FontSize, Padding, Border } from "../GlobalStyles";

const LogDetail = () => {
  const navigation = useNavigation<DrawerNavigationProp<ParamListBase>>();

  return (
    <View style={styles.logDetail}>
      <View style={styles.top}>
        <View style={[styles.statusbar, styles.topbarPosition]} />
        <View style={[styles.topbar, styles.topbarPosition]}>
          <View style={styles.topbarinner}>
            <Pressable
              style={styles.hamburger}
              onPress={() => navigation.toggleDrawer()}
            >
              <Image
                style={styles.icon}
                contentFit="cover"
                source={require("../assets/hamburger.png")}
              />
            </Pressable>
            <View style={styles.details}>
              <Text style={styles.toptextTypo}>Log: Event Title</Text>
            </View>
          </View>
        </View>
      </View>
      <ScrollView
        style={styles.scrollable}
        showsVerticalScrollIndicator={false}
        showsHorizontalScrollIndicator={false}
        contentContainerStyle={styles.scrollableScrollViewContent}
      >
        <View style={styles.devicedetailscard}>
          <View style={styles.title}>
            <Image
              style={styles.arrowuturnleftsquareIcon}
              contentFit="cover"
              source={require("../assets/arrowuturnleftsquare1.png")}
            />
            <Text style={[styles.eventTitle, styles.toptextTypo]}>
              Event Title
            </Text>
          </View>
          <View style={styles.detailitemgroup}>
            <Text style={styles.title1}>Title</Text>
            <Text style={[styles.bodyText, styles.detailTypo]}>Body Text</Text>
          </View>
          <Text style={[styles.detail, styles.detailTypo]}>Detail</Text>
        </View>
      </ScrollView>
    </View>
  );
};

const styles = StyleSheet.create({
  scrollableScrollViewContent: {
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "flex-start",
  },
  topbarPosition: {
    backgroundColor: Color.colorGold,
    left: "0%",
    right: "0%",
    position: "absolute",
    width: "100%",
  },
  toptextTypo: {
    textAlign: "left",
    color: Color.labelColorLightPrimary,
    fontFamily: FontFamily.interSemiBold,
    fontWeight: "600",
    lineHeight: 25,
    letterSpacing: 0,
    fontSize: FontSize.size_xl,
  },
  detailTypo: {
    fontFamily: FontFamily.interRegular,
    color: Color.labelColorLightSecondary,
    lineHeight: 18,
    fontSize: FontSize.size_base,
    textAlign: "left",
    letterSpacing: 0,
  },
  statusbar: {
    height: "62.77%",
    top: "0%",
    bottom: "37.23%",
    alignItems: "flex-end",
    justifyContent: "center",
  },
  icon: {
    height: "100%",
    width: "100%",
  },
  hamburger: {
    width: 20,
    height: 15,
  },
  details: {
    paddingHorizontal: Padding.p_mini,
    paddingVertical: 0,
    flexDirection: "row",
    flex: 1,
  },
  topbarinner: {
    height: 35,
    justifyContent: "space-between",
    paddingLeft: Padding.p_8xs,
    paddingRight: Padding.p_xs,
    paddingBottom: Padding.p_3xs,
    flexDirection: "row",
    alignItems: "center",
    alignSelf: "stretch",
  },
  topbar: {
    height: "37.23%",
    top: "62.77%",
    bottom: "0%",
    overflow: "hidden",
    alignItems: "center",
  },
  top: {
    height: 94,
    alignSelf: "stretch",
  },
  arrowuturnleftsquareIcon: {
    width: 16,
    height: 16,
  },
  eventTitle: {
    marginLeft: 10,
  },
  title: {
    flexDirection: "row",
    alignItems: "center",
  },
  title1: {
    fontWeight: "700",
    fontFamily: FontFamily.interBold,
    color: Color.labelColorLightSecondary,
    lineHeight: 18,
    fontSize: FontSize.size_base,
    textAlign: "left",
    letterSpacing: 0,
  },
  bodyText: {
    marginTop: 5,
  },
  detailitemgroup: {
    marginTop: 20,
    justifyContent: "center",
  },
  detail: {
    marginTop: 20,
  },
  devicedetailscard: {
    borderRadius: Border.br_smi,
    backgroundColor: Color.systemBackgroundLightPrimary,
    borderStyle: "solid",
    borderColor: Color.colorGainsboro_100,
    borderWidth: 1.3,
    padding: Padding.p_base,
    justifyContent: "center",
    alignSelf: "stretch",
  },
  scrollable: {
    padding: Padding.p_2xs,
    alignSelf: "stretch",
    flex: 1,
  },
  logDetail: {
    backgroundColor: Color.colorGhostwhite,
    width: "100%",
    flex: 1,
  },
});

export default LogDetail;
