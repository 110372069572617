import * as React from "react";
import { StyleSheet, View, Pressable, Text, ScrollView } from "react-native";
import { Image } from "expo-image";
import { DrawerNavigationProp } from "@react-navigation/drawer";
import { useNavigation, ParamListBase, useRoute } from "@react-navigation/native";
import { StackNavigationProp } from "@react-navigation/stack";
import { Color, FontFamily, FontSize, Padding, Border } from "../GlobalStyles";

const AddConnectWifi = () => {
  const navigation = useNavigation<DrawerNavigationProp<ParamListBase>>();
  const route = useRoute();
  console.log(" route ", route.params?.locationid);


  return (
    <View style={styles.addConnectwifi}>
      <View style={styles.top}>
        <View style={styles.statusbar} />
        <View style={[styles.topbar, styles.topbarLayout]}>
          <View style={styles.topbarinner}>
          <Pressable
            style={{
              width: 60, // Set a specific width for the button
              height: 60, // Set a specific height for the button
              justifyContent: 'center',
              alignItems: 'center',
              padding: 10, // Optional: additional padding
              backgroundColor: 'transparent', // Ensure background is transparent
            }}
            onPress={() => navigation.goBack()}
          >
            <Image
              style={{
                width: '60%', // Make the image fill the button
                height: '60%', // Make the image fill the button
                resizeMode: 'contain', // Ensure the image maintains its aspect ratio
              }}
              source={require("../assets/calender.png")}
            />
          </Pressable>
            <View style={styles.details}>
              <Text style={styles.toptextTypo}>Add Device</Text>
            </View>
          </View>
        </View>
      </View>
      <ScrollView
        style={styles.mainframe}
        showsVerticalScrollIndicator={false}
        showsHorizontalScrollIndicator={false}
        contentContainerStyle={styles.mainframeScrollViewContent}
      >
        { /*    ERROR CARD    
        <View style={styles.maincardBorder}>
          <Text style={styles.toptextTypo}>Error Title</Text>
          <Text style={[styles.errorBody, styles.errorBodyTypo]}>
            Error Body
          </Text>
        </View> */}
        <View style={[styles.maincard, styles.maincardBorder]}>
          <Text style={[styles.connectToDevice, styles.toptextTypo]}>
            Connect to Device Wifi
          </Text>
          <Pressable
            style={styles.openSettings}
            onPress={() => navigation.navigate("AddDeviceDetails", { locationId: route.params?.locationid})}
          >
            <Text style={styles.errorBodyTypo}>Open Settings</Text>
          </Pressable>
          <Image
            style={[styles.screenshot20240226At1409, styles.topbarLayout]}
            contentFit="cover"
            source={require("../assets/screenshot-20240226-at-1409-1.png")}
          />
          <Text style={[styles.networkAndPassword, styles.errorBodyTypo]}>
            Network and Password on device
          </Text>
          <Image
            style={styles.questionmarkcircleIcon}
            contentFit="cover"
            source={require("../assets/questionmarkcircle.png")}
          />
        </View>
        <Pressable
          style={[styles.alllocationsbtn, styles.dashCardBorder]}
          onPress={() =>
            navigation.navigate("AddQRSetup", { locationId: route.params?.locationid})
          }
        >
          <Text style={[styles.alllocationstxt, styles.locationNameLayout]}>
            Next Step
          </Text>
        </Pressable>
      </ScrollView>
    </View>
  );
};

const styles = StyleSheet.create({
  alllocationstxt: {
    fontWeight: "500",
    fontFamily: FontFamily.interMedium,
    color: "#fff",
    textAlign: "center",
    fontSize: FontSize.size_xl,
    lineHeight: 20,
    height: 17,
  },
  locationNameLayout: {
    lineHeight: 20,
    letterSpacing: 0,
  },
  dashCardBorder: {
    padding: Padding.p_base,
    borderWidth: 1.3,
    borderColor: Color.colorGainsboro_100,
    borderStyle: "solid",
    borderRadius: Border.br_smi,
    flexDirection: "row",
  },
  alllocationsbtn: {
    backgroundColor: '#3da8ff',
    height: 37,
    marginTop: 11,
    alignSelf: "stretch",
    alignItems: "center",
    overflow: "hidden",
    justifyContent: "center",
  },
  mainframeScrollViewContent: {
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "flex-start",
  },
  topbarLayout: {
    overflow: "hidden",
    width: "100%",
  },
  errorBodyTypo: {
    color: Color.labelColorLightSecondary,
    fontFamily: FontFamily.interRegular,
    lineHeight: 18,
    fontSize: FontSize.size_base,
    textAlign: "left",
    letterSpacing: 0,
  },
  maincardBorder: {
    padding: Padding.p_base,
    borderWidth: 1.3,
    borderColor: Color.colorGainsboro_100,
    borderStyle: "solid",
    backgroundColor: Color.systemBackgroundLightPrimary,
    borderRadius: Border.br_smi,
    alignItems: "center",
    justifyContent: "center",
    alignSelf: "stretch",
  },
  toptextTypo: {
    textAlign: "left",
    color: Color.labelColorLightPrimary,
    fontFamily: FontFamily.interSemiBold,
    fontWeight: "600",
    lineHeight: 25,
    letterSpacing: 0,
    fontSize: FontSize.size_xl,
  },
  statusbar: {
    height: "62.77%",
    top: "0%",
    bottom: "37.23%",
    alignItems: "flex-end",
    justifyContent: "center",
    backgroundColor: Color.colorGold,
    left: "0%",
    right: "0%",
    position: "absolute",
    width: "100%",
  },
  icon: {
    height: "100%",
    width: "100%",
  },
  hamburger: {
    width: 20,
    height: 15,
  },
  details: {
    paddingHorizontal: Padding.p_mini,
    paddingVertical: 0,
    flexDirection: "row",
    flex: 1,
  },
  topbarinner: {
    height: 35,
    justifyContent: "space-between",
    paddingLeft: Padding.p_8xs,
    paddingRight: Padding.p_xs,
    paddingBottom: Padding.p_3xs,
    flexDirection: "row",
    alignItems: "center",
    alignSelf: "stretch",
  },
  topbar: {
    height: "37.23%",
    top: "62.77%",
    bottom: "0%",
    alignItems: "center",
    backgroundColor: Color.colorGold,
    left: "0%",
    right: "0%",
    overflow: "hidden",
    position: "absolute",
  },
  top: {
    height: 94,
    alignSelf: "stretch",
  },
  errorBody: {
    marginTop: 20,
  },
  connectToDevice: {
    zIndex: 0,
  },
  openSettings: {
    zIndex: 1,
    marginTop: 20,
  },
  screenshot20240226At1409: {
    maxWidth: "100%",
    height: 80,
    zIndex: 2,
    marginTop: 20,
    alignSelf: "stretch",
  },
  networkAndPassword: {
    zIndex: 3,
    marginTop: 20,
  },
  questionmarkcircleIcon: {
    top: 16,
    right: 17,
    width: 18,
    height: 18,
    zIndex: 4,
    position: "absolute",
  },
  maincard: {
    height: 500,
    minHeight: 500,
    marginTop: 11,
  },
  mainframe: {
    padding: Padding.p_2xs,
    alignSelf: "stretch",
    flex: 1,
  },
  addConnectwifi: {
    backgroundColor: Color.colorGhostwhite,
    width: "100%",
    flex: 1,
  },
});

export default AddConnectWifi;
