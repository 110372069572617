import React, { useState } from "react";
import MenuItem from "./MenuItem";
import MenuItem1 from "./MenuItem1";
import MenuItem2 from "./MenuItem2";
import MenuItem3 from "./MenuItem3";
import MenuItem4 from "./MenuItem4";
import MenuItem5 from "./MenuItem5";
import MenuItem6 from "./MenuItem6";
import MenuItem7 from "./MenuItem7";
import MenuItem8 from "./MenuItem8";
import MenuItem9 from "./MenuItem9";
import MenuItem10 from "./MenuItem10";
import { Pressable, Text, StyleSheet, View } from "react-native";
import { Image } from "expo-image";
import { FontSize, Color, FontFamily } from "../GlobalStyles";
import { useOrg } from "../lib/useOrg";

export type DrawerMenuType = {
  state?: any;
  navigation?: any;
};

//const { currentOrganization } = useOrg();

const DrawerMenu = ({ state, navigation }: DrawerMenuType) => {
  const [drawerItemsNormal] = useState([
    <MenuItem />,
    <MenuItem1 />,
    <MenuItem2 />,
    <MenuItem3 />,
    <MenuItem4 />,
    <MenuItem5 />,
    <MenuItem6 />,
    <MenuItem7 />,
    <MenuItem8 />,
    <MenuItem9 />,
    <MenuItem10 />,
  ]);
  const [drawerItemsActive] = useState([
    <MenuItem />,
    <MenuItem1 />,
    <MenuItem2 />,
    <MenuItem3 />,
    <MenuItem4 />,
    <MenuItem5 />,
    <MenuItem6 />,
    <MenuItem7 />,
    <MenuItem8 />,
    <MenuItem9 />,
    <MenuItem10 />,
  ]);
  const stateIndex = !state ? 0 : state.index;

  return (
    <View style={styles.drawerMenu}>
      <View style={styles.menu}>
        {/*}
        <Pressable style={styles.profile}>
          <View>
            <Text style={styles.organizationName}>Org*currentOrganization?.orgid ?? 'No Organization'</Text>
            <Text style={styles.emailemailcom}>email@email.com</Text>
          </View>
        </Pressable>
        <View style={styles.menuChild} />*/}
        <View style={styles.drawerMenuItems}>
          {stateIndex === 0 ? drawerItemsActive[0] : drawerItemsNormal[0]}
          {stateIndex === 1 ? drawerItemsActive[1] : drawerItemsNormal[1]}
          {stateIndex === 2 ? drawerItemsActive[2] : drawerItemsNormal[2]}
          {stateIndex === 3 ? drawerItemsActive[3] : drawerItemsNormal[3]}
          {stateIndex === 4 ? drawerItemsActive[4] : drawerItemsNormal[4]}

          {stateIndex === 6 ? drawerItemsActive[6] : drawerItemsNormal[6]}
          {stateIndex === 7 ? drawerItemsActive[7] : drawerItemsNormal[7]}
          {stateIndex === 8 ? drawerItemsActive[8] : drawerItemsNormal[8]}

          {stateIndex === 10 ? drawerItemsActive[10] : drawerItemsNormal[10]}
        </View>
      </View>
      <View style={styles.appVersionDetails}>
        <Text style={styles.appVersion001}>App version 0.0.1</Text>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  mt28: {
    marginTop: 28,
  },
  rateUsTypo: {
    marginLeft: 16,
    fontSize: FontSize.size_base,
    lineHeight: 24,
    color: Color.black,
    textAlign: "left",
  },
  iconlylightfilterLayout: {
    height: 24,
    width: 24,
  },
  organizationName: {
    fontSize: FontSize.size_xs,
    lineHeight: 18,
    textAlign: "left",
    color: Color.labelColorLightPrimary,
    fontFamily: FontFamily.interBold,
    fontWeight: "700",
  },
  emailemailcom: {
    fontSize: FontSize.size_xl,
    lineHeight: 30,
    color: Color.black,
    fontFamily: FontFamily.robotoBold,
    textAlign: "left",
    fontWeight: "700",
  },
  profile: {
    alignItems: "center",
    flexDirection: "row",
  },
  menuChild: {
    borderStyle: "solid",
    borderColor: Color.labelColorLightPrimary,
    borderTopWidth: 1,
    height: 1,
    marginTop: 20,
    alignSelf: "stretch",
  },
  drawerMenuItems: {
    marginTop: 20,
  },
  menu: {
    alignSelf: "stretch",
  },
  appVersion001: {
    fontSize: FontSize.size_sm,
    lineHeight: 24,
    textAlign: "left",
    color: Color.labelColorLightPrimary,
    fontFamily: FontFamily.interBold,
    fontWeight: "700",
  },
  appVersionDetails: {
    flexDirection: "row",
    marginBottom: 40
  },
  drawerMenu: {
    backgroundColor: Color.colorGold,
    width: 320,
    height: 871,
    justifyContent: "space-between",
    paddingHorizontal: 30,
    paddingTop: 50,
    paddingBottom: 20,
  },
});

export default DrawerMenu;
