import * as React from "react";
import { useState, useEffect } from 'react';
import { getDeviceDetails } from "../lib/dev";
import { StyleSheet, View, Pressable, Text, ScrollView, ActivityIndicator } from "react-native";
import { Image } from "expo-image";
import { DrawerNavigationProp } from "@react-navigation/drawer";
import { useNavigation, ParamListBase, useRoute } from "@react-navigation/native";
import { StackNavigationProp } from "@react-navigation/stack";
import { Color, Padding, Border, FontSize, FontFamily } from "../GlobalStyles";
import { supabase } from "../lib/supabase";

async function getTotalsByLocation(locationId: string) { // Ensure locationId is a string
  const { data, error } = await supabase.rpc('get_totals_by_location', { location_id: locationId });
  if (error) {
    console.error('Error calling function:', error);
    return null;
  }
  return data;
}

const DeviceDetail = () => {
  const navigation = useNavigation<DrawerNavigationProp<ParamListBase>>();
  const route = useRoute();
  const deviceid = route.params?.deviceid;
  const [deviceDetails, setDeviceDetails] = useState(null);
  const [isLoading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const locationid = deviceDetails?.device?.locationid;
  const [totalsData, setTotalsData] = useState(null); 

  useEffect(() => {
    setLoading(true); 
    async function fetchData() {
      try {
        const details = await getDeviceDetails(deviceid);
        setDeviceDetails(details);

        const locationid = details?.device?.locationid; // Get locationid from device details
        if (locationid) { // Ensure locationid is defined before calling getTotalsByLocation
          const totals = await getTotalsByLocation(locationid); // Fetch totals
          setTotalsData(totals);
        } else {
          console.error('Location ID is not available');
        }
      } catch (err) {
        setError('Failed to fetch device details');
        console.error("Failed to fetch device details:", err);
      } finally {
        setLoading(false);
      }
    }

    fetchData();
  }, [deviceid]);

  console.log(deviceDetails);

  if (isLoading) {
    return (
      <View style={styles.centered}>
        <ActivityIndicator size="large" />
      </View>
    );
  }

  if (error) {
    return (
      <View style={styles.centered}>
        <Text>{error}</Text>
      </View>
    );
  }

  return (
    <View style={styles.deviceDetail}>
      <View style={styles.top}>
        <View style={[styles.statusbar, styles.topbarPosition]} />
        <View style={[styles.topbar, styles.topbarPosition]}>
          <View style={styles.topbarinner}>
          <Pressable
            style={{
              width: 60, // Set a specific width for the button
              height: 60, // Set a specific height for the button
              justifyContent: 'center',
              alignItems: 'center',
              padding: 10, // Optional: additional padding
              backgroundColor: 'transparent', // Ensure background is transparent
            }}
            onPress={() => navigation.goBack()}
          >
            <Image
              style={{
                width: '60%', // Make the image fill the button
                height: '60%', // Make the image fill the button
                resizeMode: 'contain', // Ensure the image maintains its aspect ratio
              }}
              source={require("../assets/calender.png")}
            />
          </Pressable>
            <View style={styles.details}>
              <Text style={styles.toptext}>{deviceDetails?.device?.devicename}</Text>
            </View>
            <Image
              style={styles.sliderhorizontal3Icon}
              contentFit="cover"
              source={require("../assets/sliderhorizontal3.png")}
            />
          </View>
        </View>
      </View>
      <ScrollView
        style={styles.scrollable}
        showsVerticalScrollIndicator={false}
        showsHorizontalScrollIndicator={false}
        contentContainerStyle={styles.scrollableScrollViewContent}
      >
        <Pressable
          style={[styles.locationcard, styles.locationcardBorder]}
          onPress={() => navigation.navigate("LocationDetail", { locationid: deviceDetails?.device?.locationid })}
        >
          <Image
            style={styles.locationimgIcon}
            contentFit="cover"
            source={require("../assets/locationimg2.png")}
          />
          <View style={styles.content}>
            <Text style={styles.locationtitle}>{ deviceDetails?.location?.locationname }</Text>
            <View style={styles.details1}>
              <View style={styles.details2}>
                <Text
                  style={[styles.text, styles.textTypo]}
                >{`Location `}</Text>
                <View style={styles.nodevicesframe}>
                  <Image
                    style={styles.lanyardcardIcon}
                    contentFit="cover"
                    source={require("../assets/lanyardcard.png")}
                  />
                  <Text style={[styles.nodevicesval, styles.textTypo]}>{deviceDetails?.location?.deviceCount}</Text>
                </View>
                <View style={styles.nodevicesframe}>
                  <Image
                    style={styles.pluscircleIcon}
                    contentFit="cover"
                    source={require("../assets/pluscircle1.png")}
                  />
                  <Text
                    style={[styles.nodevicesval, styles.textTypo]}
                  >${totalsData && totalsData[1]?.billin_total || '0'} /24h</Text>
                </View>
                <View style={styles.nodevicesframe}>
                  <Text style={[styles.nodevicesval, styles.textTypo]}>
                  ${totalsData && totalsData[3]?.billin_total || '0'} /Month
                  </Text>
                </View>
              </View>
              <View style={styles.details3}>
                <View style={styles.details2}>
                <Image
                    style={styles.pluscircleIcon}
                    contentFit="cover"
                    source={require("../assets/minuscircle1.png")}
                  />
                  <Text
                    style={[styles.nodevicesval, styles.textTypo]}
                  > ${totalsData && totalsData[1]?.ticketout_total || '0'} /24h</Text>
                </View>
                <View style={styles.moneyoutframe1}>
                  <Text style={[styles.nodevicesval, styles.textTypo]}>
                  ${totalsData && totalsData[3]?.ticketout_total || '0'} /Month
                  </Text>
                </View>
              </View>
            </View>
          </View>
        </Pressable>



    {   /*  Bill in card    */  }

        <Pressable
          style={[
            styles.revenuecardswinsbets,
            styles.devicedetailscardSpaceBlock,
          ]}
          onPress={() => navigation.navigate("Log", {deviceid: deviceid, billin: true, ticketout: false})}
        >
          <View style={[styles.dashWinsCard, styles.locationcardBorder]}>
            <Image
              style={[styles.pluscircleIcon1, styles.iconLayout]}
              contentFit="cover"
              source={require("../assets/pluscircle.png")}
            />
            <View style={styles.content1}>
              <View style={styles.details4}>
                <View>
                  <Text style={styles.today1}>Bill-in 24h</Text>
                  <Text style={styles.text1}>+${deviceDetails?.totals24h[0].total_billin_amount?.toFixed(2)}</Text>
                </View>
                <View style={styles.total}>
                  <Text style={styles.today1}>Weekly</Text>
                  <Text style={styles.text1}>${deviceDetails?.totalsWeek[0].total_billin_amount?.toFixed(2)}</Text>
                </View>
              </View>
              <View style={[{ marginTop: 10 }, styles.details4]}>
                <View>
                  <Text style={styles.today1}>Monthly</Text>
                  <Text style={styles.text1}>+${deviceDetails?.totalsMonth[0].total_billin_amount?.toFixed(2)}</Text>
                </View>
                <View style={styles.total}>
                  <Text style={styles.today1}>YTD</Text>
                  <Text style={styles.text1}>${deviceDetails?.totalsYTD[0].total_billin_amount?.toFixed(2)}</Text>
                </View>
              </View>
              {/*<View style={styles.graph} />*/}
            </View>
          </View>
        </Pressable>



        {   /*  Ticket out card    */  }

        <Pressable
          style={[
            styles.revenuecardswinsbets,
            styles.devicedetailscardSpaceBlock,
          ]}
          onPress={() => navigation.navigate("Log", {deviceid: deviceid, billin: false, ticketout: true})}
        >
          <View style={[styles.dashWinsCard, styles.locationcardBorder]}>
            <Image
              style={[styles.pluscircleIcon1, styles.iconLayout]}
              contentFit="cover"
              source={require("../assets/minuscircle.png")}
            />
            <View style={styles.content1}>
              <View style={styles.details4}>
                <View>
                  <Text style={styles.today1}>Ticket-out 24h</Text>
                  <Text style={styles.text1}>+${deviceDetails?.totals24h[0].total_ticketout_amount?.toFixed(2)}</Text>
                </View>
                <View style={styles.total}>
                  <Text style={styles.today1}>Weekly</Text>
                  <Text style={styles.text1}>${deviceDetails?.totalsWeek[0].total_ticketout_amount?.toFixed(2)}</Text>
                </View>
              </View>
              <View style={[{ marginTop: 10 }, styles.details4]}>
                <View>
                  <Text style={styles.today1}>Monthly</Text>
                  <Text style={styles.text1}>+${deviceDetails?.totalsMonth[0].total_ticketout_amount?.toFixed(2)}</Text>
                </View>
                <View style={styles.total}>
                  <Text style={styles.today1}>YTD</Text>
                  <Text style={styles.text1}>${deviceDetails?.totalsYTD[0].total_ticketout_amount?.toFixed(2)}</Text>
                </View>
              </View>
              {/*<View style={styles.graph} />*/}
            </View>
          </View>
        </Pressable>


        <View
          style={[styles.devicedetailscard, styles.devicedetailscardSpaceBlock]}
        >
          <Text style={styles.toptext}>{deviceDetails?.device.devicename} details</Text>
          <Text style={[styles.bodyText, styles.detailTypo]}>{ deviceDetails?.device?.devicedesc }</Text>
          <View style={styles.detailitemgroup}>
          
            <Text style={styles.today1}>{ deviceDetails?.location?.locationname }</Text>
            <Text style={[styles.bodyText, styles.detailTypo]}>{ deviceDetails?.location?.locationaddress }</Text>
            <Text style={[styles.bodyText, styles.detailTypo]}>{ deviceDetails?.location?.locationcity }</Text>
            <Text style={[styles.bodyText, styles.detailTypo]}>{ deviceDetails?.location?.locationstate }</Text>
            <Text style={[styles.bodyText, styles.detailTypo]}>{ deviceDetails?.location?.locationcountry }</Text>
          </View>
          {/*<Text style={[styles.detail, styles.detailTypo]}>Detail</Text>*/}
        </View>
      </ScrollView>
    </View>
  );
};

const styles = StyleSheet.create({
  scrollableScrollViewContent: {
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "flex-start",
  },
  topbarPosition: {
    backgroundColor: Color.colorGold,
    left: "0%",
    right: "0%",
    position: "absolute",
    width: "100%",
  },
  locationcardBorder: {
    padding: Padding.p_base,
    borderWidth: 1.3,
    borderColor: Color.colorGainsboro_100,
    borderStyle: "solid",
    backgroundColor: Color.systemBackgroundLightPrimary,
    borderRadius: Border.br_smi,
  },
  textTypo: {
    color: Color.labelColorLightSecondary,
    lineHeight: 18,
    fontSize: FontSize.size_smi,
    fontFamily: FontFamily.interBold,
    fontWeight: "700",
    textAlign: "left",
    letterSpacing: 0,
  },
  letterFlexBox: {
    textAlign: "center",
    color: Color.colorGray_100,
    zIndex: 1,
    lineHeight: 18,
    display: "flex",
    fontFamily: FontFamily.interSemiBold,
    fontWeight: "600",
    letterSpacing: 0,
    justifyContent: "center",
    position: "absolute",
    alignItems: "center",
  },
  devicedetailscardSpaceBlock: {
    marginTop: 11,
    alignSelf: "stretch",
  },
  iconLayout: {
    height: 20,
    zIndex: 0,
    width: 20,
  },
  leticon2Position: {
    right: 14,
    top: 14,
    position: "absolute",
  },
  detailTypo: {
    fontFamily: FontFamily.interRegular,
    fontSize: FontSize.size_base,
    color: Color.labelColorLightSecondary,
    lineHeight: 18,
    textAlign: "left",
    letterSpacing: 0,
  },
  statusbar: {
    height: "62.77%",
    top: "0%",
    bottom: "37.23%",
    alignItems: "flex-end",
    justifyContent: "center",
  },
  icon: {
    height: "100%",
    width: "100%",
  },
  hamburger: {
    height: 15,
    width: 20,
  },
  toptext: {
    textAlign: "left",
    color: Color.labelColorLightPrimary,
    fontFamily: FontFamily.interSemiBold,
    fontWeight: "600",
    lineHeight: 25,
    letterSpacing: 0,
    fontSize: FontSize.size_xl,
  },
  details: {
    paddingHorizontal: Padding.p_mini,
    paddingVertical: 0,
    flexDirection: "row",
    flex: 1,
  },
  sliderhorizontal3Icon: {
    width: 21,
    height: 18,
  },
  topbarinner: {
    height: 35,
    justifyContent: "space-between",
    paddingLeft: Padding.p_8xs,
    paddingRight: Padding.p_xs,
    paddingBottom: Padding.p_3xs,
    flexDirection: "row",
    alignSelf: "stretch",
    alignItems: "center",
  },
  topbar: {
    height: "37.23%",
    top: "62.77%",
    bottom: "0%",
    overflow: "hidden",
    alignItems: "center",
  },
  top: {
    height: 94,
    alignSelf: "stretch",
  },
  locationimgIcon: {
    borderRadius: Border.br_12xs,
    width: 60,
    height: 60,
  },
  locationtitle: {
    fontSize: FontSize.size_mini,
    lineHeight: 20,
    width: 262,
    display: "flex",
    fontFamily: FontFamily.interBold,
    fontWeight: "700",
    textAlign: "left",
    color: Color.labelColorLightPrimary,
    letterSpacing: 0,
    alignItems: "center",
  },
  text: {
    display: "none",
    width: 92,
    alignItems: "center",
  },
  lanyardcardIcon: {
    width: 11,
    height: 15,
  },
  nodevicesval: {
    marginLeft: 1,
  },
  nodevicesframe: {
    marginLeft: 10,
    flexDirection: "row",
    alignItems: "center",
  },
  pluscircleIcon: {
    width: 15,
    height: 15,
  },
  details2: {
    flexDirection: "row",
    alignItems: "center",
  },
  circleIcon: {
    zIndex: 0,
    width: 15,
    height: 15,
  },
  letter: {
    top: 2,
    left: 0,
    fontSize: FontSize.size_2xs,
    height: 12,
    zIndex: 1,
    width: 15,
  },
  leticon: {
    flexDirection: "row",
    alignItems: "center",
  },
  moneyoutframe1: {
    marginLeft: 20,
    flexDirection: "row",
    alignItems: "center",
  },
  details3: {
    marginTop: 1,
    flexDirection: "row",
    alignSelf: "stretch",
    alignItems: "center",
  },
  details1: {
    width: 288,
  },
  content: {
    marginLeft: 10,
    width: 288,
  },
  locationcard: {
    flexDirection: "row",
    overflow: "hidden",
    alignSelf: "stretch",
  },
  pluscircleIcon1: {
    right: 14,
    top: 14,
    position: "absolute",
  },
  today1: {
    fontSize: FontSize.size_base,
    color: Color.labelColorLightSecondary,
    lineHeight: 18,
    fontFamily: FontFamily.interBold,
    fontWeight: "700",
    textAlign: "left",
    letterSpacing: 0,
  },
  text1: {
    marginTop: 2,
    fontFamily: FontFamily.interBold,
    fontWeight: "700",
    textAlign: "left",
    color: Color.labelColorLightPrimary,
    lineHeight: 25,
    letterSpacing: 0,
    fontSize: FontSize.size_xl,
  },
  total: {
    marginLeft: 15,
  },
  details4: {
    flexDirection: "row",
  },
  graph: {
    backgroundColor: Color.colorGainsboro_200,
    height: 70,
    marginTop: 10,
    alignSelf: "stretch",
  },
  content1: {
    zIndex: 1,
    marginLeft: 10,
    flex: 1,
  },
  dashWinsCard: {
    flexDirection: "row",
    flex: 1,
  },
  revenuecardswinsbets: {
    flexDirection: "row",
    alignItems: "center",
  },
  letter2: {
    top: 1,
    right: 0,
    fontSize: FontSize.size_sm,
    height: 17,
    zIndex: 1,
    width: 20,
  },
  leticon2: {
    zIndex: 0,
    flexDirection: "row",
    alignItems: "center",
  },
  bodyText: {
    marginTop: 5,
  },
  detailitemgroup: {
    marginTop: 20,
    justifyContent: "center",
  },
  detail: {
    marginTop: 20,
  },
  devicedetailscard: {
    padding: Padding.p_base,
    borderWidth: 1.3,
    borderColor: Color.colorGainsboro_100,
    borderStyle: "solid",
    backgroundColor: Color.systemBackgroundLightPrimary,
    borderRadius: Border.br_smi,
    justifyContent: "center",
  },
  scrollable: {
    padding: Padding.p_2xs,
    maxWidth: "100%",
    width: "100%",
    flex: 1,
  },
  deviceDetail: {
    backgroundColor: Color.colorGhostwhite,
    alignItems: "center",
    width: "100%",
    flex: 1,
  },
  centered: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
});

export default DeviceDetail;