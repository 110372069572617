import { supabase } from './supabase';

async function getLogByDev(deviceid, billin, ticketout, start, end) {
    const limit = end - start + 1;

    // Function to format the date to a more readable form and adjust timezone
    const formatDate = (isoString) => {
        const date = new Date(isoString);
        return new Intl.DateTimeFormat('en-US', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
            hour12: true,
            timeZone: 'America/New_York'
        }).format(date);
    };

    try {
        let query = supabase
            .from('v2_analyticsevents')
            .select('*')
            .eq('deviceid', deviceid)
            .order('device_time', { ascending: false });

        // Build type filter based on billin and ticketout flags
        const typeFilters = [];
        if (billin) typeFilters.push('Bill-in');
        if (ticketout) typeFilters.push('Ticket-out');
        
        if (typeFilters.length > 0) {
            query = query.in('type', typeFilters);
        }

        const { data, error } = await query;

        if (error) throw error;

        // Transform the data to match the expected format
        const transformedData = data.map(item => ({
            eventid: item.eventid,
            deviceid: item.deviceid,
            type: item.type,
            value: item.value,
            bills: item.bills,
            device_time: item.device_time,
            function_time: item.function_time,
            ticket_hex: item.ticket_hex,
            terminal_number: item.terminal_number,
            header: item.header,
            address: item.address,
            ticket_time: item.ticket_time,
            // Add derived fields
            eventtype: item.type,
            time: formatDate(new Date(Number(item.device_time))), // Convert numeric timestamp to date
            color: item.type === 'Bill-in' ? 'green' : 'red'
        }));

        // Slice the results to get the requested range
        const slicedResults = transformedData.slice(start, start + limit);
        
        console.log(slicedResults);
        
        return slicedResults;
    } catch (error) {
        console.error('Error fetching logs:', error);
        throw error;
    }
}

export { getLogByDev };