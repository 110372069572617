import * as React from "react";
import {
  StyleSheet,
  View,
  Pressable,
  Text,
  ScrollView,
  TextInput,
} from "react-native";
import { Image } from "expo-image";
import { DrawerNavigationProp } from "@react-navigation/drawer";
import { useNavigation, ParamListBase } from "@react-navigation/native";
import { Padding, Color, Border, FontFamily, FontSize } from "../GlobalStyles";

const MyAccount = () => {
  const navigation = useNavigation<DrawerNavigationProp<ParamListBase>>();

  return (
    <View style={styles.myaccount}>
      <View style={styles.top}>
        <View style={styles.statusbar} />
        <View style={styles.topbar}>
          <View style={styles.topbarinner}>
            <Pressable
              style={styles.hamburger}
              onPress={() => navigation.toggleDrawer()}
            >
              <Image
                style={styles.icon}
                contentFit="cover"
                source={require("../assets/hamburger.png")}
              />
            </Pressable>
            <View style={styles.details}>
              <Text style={[styles.toptext, styles.toptextFlexBox]}>
                My Account
              </Text>
            </View>
          </View>
        </View>
      </View>
      <ScrollView
        style={styles.frame}
        showsVerticalScrollIndicator={false}
        showsHorizontalScrollIndicator={false}
        contentContainerStyle={styles.frameScrollViewContent}
      >
        <View style={[styles.errorcard, styles.formBorder]}>
          <Text style={[styles.toptext, styles.toptextFlexBox]}>
            Error Title
          </Text>
          <Text style={[styles.errorBody, styles.toptextFlexBox]}>
            Error Body
          </Text>
        </View>
        <Text style={styles.myAccount}>My Account</Text>
        <View style={[styles.form, styles.formBorder]}>
          <Text style={[styles.name, styles.nameTypo]}>Name</Text>
          <TextInput
            style={[styles.devicename, styles.detailSpaceBlock]}
            placeholder="  Slug (url)"
            placeholderTextColor="rgba(60, 60, 67, 0.3)"
          />
          <Text style={[styles.detail, styles.detailSpaceBlock]}>Detail</Text>
          <TextInput
            style={[styles.devicename, styles.detailSpaceBlock]}
            placeholder="  Name"
            placeholderTextColor="rgba(60, 60, 67, 0.3)"
          />
          <Text style={[styles.detail, styles.detailSpaceBlock]}>Detail</Text>
          <TextInput
            style={[styles.devicename, styles.detailSpaceBlock]}
            placeholder="  Name"
            placeholderTextColor="rgba(60, 60, 67, 0.3)"
          />
          <Text style={[styles.detail, styles.detailSpaceBlock]}>Detail</Text>
          <TextInput
            style={[styles.devicename, styles.detailSpaceBlock]}
            placeholder="  Name"
            placeholderTextColor="rgba(60, 60, 67, 0.3)"
          />
          <Text style={[styles.detail, styles.detailSpaceBlock]}>Detail</Text>
          <TextInput
            style={[styles.devicename, styles.detailSpaceBlock]}
            placeholder="  Name"
            placeholderTextColor="rgba(60, 60, 67, 0.3)"
          />
        </View>
        <Pressable style={[styles.bluebtn, styles.bluebtnBorder]}>
          <Text style={styles.txt}>Save</Text>
        </Pressable>
        <Pressable style={[styles.greybtn, styles.bluebtnBorder]}>
          <Text style={styles.txt}>Delete Account</Text>
        </Pressable>
      </ScrollView>
    </View>
  );
};

const styles = StyleSheet.create({
  frameScrollViewContent: {
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "flex-start",
  },
  toptextFlexBox: {
    textAlign: "left",
    letterSpacing: 0,
  },
  formBorder: {
    padding: Padding.p_base,
    borderWidth: 1.3,
    borderColor: Color.colorGainsboro_100,
    borderStyle: "solid",
    backgroundColor: Color.systemBackgroundLightPrimary,
    borderRadius: Border.br_smi,
    alignSelf: "stretch",
  },
  nameTypo: {
    lineHeight: 20,
    color: Color.colorGray_200,
    textAlign: "left",
    fontFamily: FontFamily.interSemiBold,
    fontWeight: "600",
    letterSpacing: 0,
    fontSize: FontSize.size_xl,
  },
  detailSpaceBlock: {
    marginTop: 10,
    alignSelf: "stretch",
  },
  bluebtnBorder: {
    height: 43,
    marginTop: 11,
    padding: Padding.p_base,
    borderWidth: 1.3,
    borderColor: Color.colorGainsboro_100,
    borderStyle: "solid",
    borderRadius: Border.br_smi,
    flexDirection: "row",
    alignItems: "center",
    overflow: "hidden",
    justifyContent: "center",
    alignSelf: "stretch",
  },
  statusbar: {
    height: "62.77%",
    top: "0%",
    bottom: "37.23%",
    alignItems: "flex-end",
    justifyContent: "center",
    backgroundColor: Color.colorGold,
    left: "0%",
    right: "0%",
    position: "absolute",
    width: "100%",
  },
  icon: {
    height: "100%",
    width: "100%",
  },
  hamburger: {
    width: 20,
    height: 15,
  },
  toptext: {
    color: Color.labelColorLightPrimary,
    fontFamily: FontFamily.interSemiBold,
    fontWeight: "600",
    lineHeight: 25,
    textAlign: "left",
    fontSize: FontSize.size_xl,
  },
  details: {
    paddingHorizontal: Padding.p_mini,
    paddingVertical: 0,
    flexDirection: "row",
    flex: 1,
  },
  topbarinner: {
    height: 35,
    justifyContent: "space-between",
    paddingLeft: Padding.p_8xs,
    paddingRight: Padding.p_xs,
    paddingBottom: Padding.p_3xs,
    flexDirection: "row",
    alignItems: "center",
    alignSelf: "stretch",
  },
  topbar: {
    height: "37.23%",
    top: "62.77%",
    bottom: "0%",
    alignItems: "center",
    overflow: "hidden",
    backgroundColor: Color.colorGold,
    left: "0%",
    right: "0%",
    position: "absolute",
    width: "100%",
  },
  top: {
    height: 94,
    alignSelf: "stretch",
  },
  errorBody: {
    fontSize: FontSize.size_base,
    lineHeight: 18,
    color: Color.labelColorLightSecondary,
    marginTop: 20,
    fontFamily: FontFamily.interRegular,
  },
  errorcard: {
    alignItems: "center",
    justifyContent: "center",
  },
  myAccount: {
    marginTop: 11,
    color: Color.colorGray_200,
    textAlign: "left",
    fontFamily: FontFamily.interSemiBold,
    fontWeight: "600",
    lineHeight: 25,
    letterSpacing: 0,
    fontSize: FontSize.size_xl,
  },
  name: {
    alignSelf: "stretch",
  },
  devicename: {
    borderRadius: Border.br_3xs,
    backgroundColor: Color.fillColorLightTertiary,
    paddingHorizontal: Padding.p_5xs,
    paddingVertical: Padding.p_6xs,
    fontSize: FontSize.size_mid,
    fontFamily: FontFamily.interRegular,
    flexDirection: "row",
    alignItems: "center",
  },
  detail: {
    lineHeight: 20,
    color: Color.colorGray_200,
    textAlign: "left",
    fontFamily: FontFamily.interSemiBold,
    fontWeight: "600",
    letterSpacing: 0,
    fontSize: FontSize.size_xl,
  },
  form: {
    marginTop: 11,
  },
  txt: {
    lineHeight: 22,
    fontWeight: "500",
    fontFamily: FontFamily.interMedium,
    color: Color.systemBackgroundLightPrimary,
    textAlign: "center",
    letterSpacing: 0,
    fontSize: FontSize.size_xl,
  },
  bluebtn: {
    backgroundColor: Color.colorDeepskyblue,
  },
  greybtn: {
    backgroundColor: Color.colorLightgray,
  },
  frame: {
    padding: Padding.p_2xs,
    alignSelf: "stretch",
    flex: 1,
  },
  myaccount: {
    backgroundColor: Color.colorGhostwhite,
    width: "100%",
    flex: 1,
  },
});

export default MyAccount;
