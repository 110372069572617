import * as React from "react";
import { useState, useEffect } from "react";
import { StyleSheet, View, Pressable, Text, ScrollView, ActivityIndicator } from "react-native";
import { Image } from "expo-image";
import { DrawerNavigationProp } from "@react-navigation/drawer";
import { useNavigation, ParamListBase, useRoute } from "@react-navigation/native";
import { StackNavigationProp } from "@react-navigation/stack";
import { FontFamily, Color, FontSize, Padding, Border } from "../GlobalStyles";
import { supabase } from '../lib/supabase';
import { getLocationBasic } from "../lib/loc";
import { useOrg } from "../lib/useOrg";
import { getDeviceDetails } from "../lib/dev";

const AddDeviceConfirmation = () => {
  const navigation = useNavigation<DrawerNavigationProp<ParamListBase>>();
  const route = useRoute();
  const { currentOrganization } = useOrg() || {};
  const [deviceDetails, setDeviceDetails] = useState(null);
  const [isLoading, setLoading] = useState(true);
  const [error, setError] = useState('');

  const deviceid = route.params?.deviceid;

  useEffect(() => {
    async function fetchData() {
      try {
        const details = await getDeviceDetails(deviceid);
        setDeviceDetails(details);
      } catch (err) {
        setError('Failed to fetch device details');
        console.error("Failed to fetch device details:", err);
      } finally {
        setLoading(false);
      }
    }

    fetchData();
  }, [deviceid]);

  if (isLoading) {
    return (
      <View style={styles.centered}>
        <ActivityIndicator size={50} />
      </View>
    );
  }

  if (error) {
    return (
      <View style={styles.centered}>
        <Text>{error}</Text>
      </View>
    );
  }

  console.log("new dev d ", deviceDetails);

  return (
    <View style={styles.addDeviceconfirmation}>
      <View style={styles.top}>
        <View style={styles.statusbar} />
        <View style={styles.topbar}>
          <View style={styles.topbarinner}>
            <Pressable
              style={styles.hamburger}
              onPress={() => navigation.toggleDrawer()}
            >
              <Image
                style={styles.icon}
                contentFit="cover"
                source={require("../assets/hamburger.png")}
              />
            </Pressable>
            <View style={styles.details}>
              <Text style={styles.toptext}>Device {deviceDetails?.device?.devicename} Added</Text>
            </View>
          </View>
        </View>
      </View>
      <ScrollView
        style={styles.scrollable}
        showsVerticalScrollIndicator={false}
        showsHorizontalScrollIndicator={false}
        contentContainerStyle={styles.scrollableScrollViewContent}
      >
        <View style={styles.devicedetailscard}>
          <Text style={styles.toptext}>{deviceDetails?.device?.devicename} is setup</Text>
          <View style={styles.detailitemgroup}>
            <Text style={styles.title}>{deviceDetails?.device?.devicename}</Text>
            <Text style={[styles.bodyText, styles.detailTypo]}>{deviceDetails?.device?.devicedesc}</Text>
          </View>
          {/*<Text style={[styles.detail, styles.detailTypo]}>Detail</Text>*/}
        </View>

        <Pressable
          style={[styles.locationcard, styles.locationcardBorder]}
          onPress={() => navigation.navigate("LocationDetail")}
        >
        <View style={styles.content}>
            <Text style={styles.locationtitle}>{ deviceDetails?.location?.locationname }</Text>
            <View style={styles.details1}>
              <View style={styles.details2}>
                <Text
                  style={[styles.text, styles.textTypo]}
                >{`Location `}</Text>
                <View style={styles.nodevicesframe}>
                  <Image
                    style={styles.lanyardcardIcon}
                    contentFit="cover"
                    source={require("../assets/lanyardcard.png")}
                  />
                  <Text style={[styles.nodevicesval, styles.textTypo]}>{deviceDetails?.location?.deviceCount}</Text>
                </View>
                <View style={styles.nodevicesframe}>
                  <Image
                    style={styles.pluscircleIcon}
                    contentFit="cover"
                    source={require("../assets/pluscircle1.png")}
                  />
                  <Text
                    style={[styles.nodevicesval, styles.textTypo]}
                  >${ deviceDetails?.location?.total24Location[0].total_billin_amount.toFixed(2) }/24</Text>
                </View>
                <View style={styles.nodevicesframe}>
                  <Image
                    style={styles.pluscircleIcon}
                    contentFit="cover"
                    source={require("../assets/minuscircle1.png")}
                  />
                  <Text style={[styles.nodevicesval, styles.textTypo]}>
                  ${ deviceDetails?.location?.total24Location[0].total_ticketout_amount.toFixed(2) }/24
                  </Text>
                </View>
              </View>
              <View style={styles.details3}>
                <View style={styles.details2}>
                  <View style={styles.leticon}>
                    <Image
                      style={styles.circleIcon}
                      contentFit="cover"
                      source={require("../assets/pluscircle.png")}
                    />
                  </View>
                  <Text
                    style={[styles.nodevicesval, styles.textTypo]}
                  >${ deviceDetails?.location?.monthTotalByLocation[0].total_billin_amount.toFixed(2) }/M</Text>
                </View>
                <View style={styles.moneyoutframe1}>
                  <View style={styles.leticon}>
                    <Image
                      style={styles.circleIcon}
                      contentFit="cover"
                      source={require("../assets/minuscircle.png")}
                    />
                  </View>
                  <Text style={[styles.nodevicesval, styles.textTypo]}>
                  ${ deviceDetails?.location?.monthTotalByLocation[0].total_billin_amount.toFixed(2) }/M
                  </Text>
                </View>
              </View>
            </View>
          </View>
          </Pressable>


        <Pressable
          style={[styles.connectbutton, styles.connectbuttonBorder]}
          onPress={() => navigation.navigate("Dash")}
        >
          <Text style={styles.connectToWifi}>Done</Text>
        </Pressable>


        <Pressable style={[styles.offlinebutton, styles.connectbuttonBorder]}
        onPress={() => navigation.navigate("AddSelectLocation")}>
          <Text style={styles.connectToWifi}>Add Another Device</Text>
        </Pressable>



      </ScrollView>
    </View>
  );
};

const styles = StyleSheet.create({
  locationcardBorder: {
    padding: Padding.p_base,
    borderWidth: 1.3,
    borderColor: Color.colorGainsboro_100,
    borderStyle: "solid",
    backgroundColor: Color.systemBackgroundLightPrimary,
    borderRadius: Border.br_smi,
  },
  locationcard: {
    flexDirection: "row",
    overflow: "hidden",
    alignSelf: "stretch",
    marginTop:10,
  },
  details1: {
    width: 288,
  },
  text: {
    display: "none",
    width: 92,
    alignItems: "center",
  },
  details: {
    paddingHorizontal: Padding.p_mini,
    paddingVertical: 0,
    flexDirection: "row",
    flex: 1,
  },
  locationtitle: {
    fontSize: FontSize.size_mini,
    lineHeight: 20,
    width: 262,
    display: "flex",
    fontFamily: FontFamily.interBold,
    fontWeight: "700",
    textAlign: "left",
    color: Color.labelColorLightPrimary,
    letterSpacing: 0,
    alignItems: "center",
  },
  lanyardcardIcon: {
    width: 11,
    height: 15,
  },
  pluscircleIcon: {
    width: 15,
    height: 15,
  },
  details3: {
    marginTop: 1,
    flexDirection: "row",
    alignSelf: "stretch",
    alignItems: "center",
  },
  details2: {
    flexDirection: "row",
    alignItems: "center",
  },
  circleIcon: {
    zIndex: 0,
    width: 15,
    height: 15,
  },
  leticon: {
    flexDirection: "row",
    alignItems: "center",
  },
  moneyoutframe1: {
    marginLeft: 20,
    flexDirection: "row",
    alignItems: "center",
  },
  textTypo: {
    color: Color.labelColorLightSecondary,
    lineHeight: 18,
    fontSize: FontSize.size_smi,
    fontFamily: FontFamily.interBold,
    fontWeight: "700",
    textAlign: "left",
    letterSpacing: 0,
  },
  nodevicesval: {
    marginLeft: 1,
  },
  nodevicesframe: {
    marginLeft: 10,
    flexDirection: "row",
    alignItems: "center",
  },
  content: {
    marginLeft: 10,
    width: 288,
  },
  scrollableScrollViewContent: {
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "flex-start",
  },
  detailTypo: {
    fontFamily: FontFamily.interRegular,
    color: Color.labelColorLightSecondary,
    lineHeight: 18,
    fontSize: FontSize.size_base,
    textAlign: "left",
    letterSpacing: 0,
  },
  connectbuttonBorder: {
    marginTop: 11,
    height: 43,
    padding: Padding.p_base,
    borderWidth: 1.3,
    borderColor: Color.colorGainsboro_100,
    borderStyle: "solid",
    borderRadius: Border.br_smi,
    flexDirection: "row",
    alignItems: "center",
    overflow: "hidden",
    justifyContent: "center",
    alignSelf: "stretch",
  },
  statusbar: {
    height: "62.77%",
    top: "0%",
    bottom: "37.23%",
    alignItems: "flex-end",
    justifyContent: "center",
    backgroundColor: Color.colorGold,
    left: "0%",
    right: "0%",
    position: "absolute",
    width: "100%",
  },
  icon: {
    height: "100%",
    width: "100%",
  },
  hamburger: {
    width: 20,
    height: 15,
  },
  toptext: {
    lineHeight: 25,
    fontWeight: "600",
    fontFamily: FontFamily.interSemiBold,
    color: Color.labelColorLightPrimary,
    textAlign: "left",
    letterSpacing: 0,
    fontSize: FontSize.size_xl,
  },
  details: {
    paddingHorizontal: Padding.p_mini,
    paddingVertical: 0,
    flexDirection: "row",
    flex: 1,
  },
  topbarinner: {
    height: 35,
    justifyContent: "space-between",
    paddingLeft: Padding.p_8xs,
    paddingRight: Padding.p_xs,
    paddingBottom: Padding.p_3xs,
    flexDirection: "row",
    alignItems: "center",
    alignSelf: "stretch",
  },
  topbar: {
    height: "37.23%",
    top: "62.77%",
    bottom: "0%",
    alignItems: "center",
    overflow: "hidden",
    backgroundColor: Color.colorGold,
    left: "0%",
    right: "0%",
    position: "absolute",
    width: "100%",
  },
  top: {
    height: 94,
    alignSelf: "stretch",
  },
  title: {
    fontWeight: "700",
    fontFamily: FontFamily.interBold,
    color: Color.labelColorLightSecondary,
    lineHeight: 18,
    fontSize: FontSize.size_base,
    textAlign: "left",
    letterSpacing: 0,
  },
  bodyText: {
    marginTop: 5,
  },
  detailitemgroup: {
    marginTop: 20,
    justifyContent: "center",
  },
  detail: {
    marginTop: 20,
  },
  devicedetailscard: {
    backgroundColor: Color.systemBackgroundLightPrimary,
    padding: Padding.p_base,
    borderWidth: 1.3,
    borderColor: Color.colorGainsboro_100,
    borderStyle: "solid",
    borderRadius: Border.br_smi,
    justifyContent: "center",
    alignSelf: "stretch",
  },
  connectToWifi: {
    lineHeight: 22,
    height: 19,
    fontWeight: "500",
    fontFamily: FontFamily.interMedium,
    color: Color.systemBackgroundLightPrimary,
    textAlign: "center",
    letterSpacing: 0,
    fontSize: FontSize.size_xl,
  },
  connectbutton: {
    backgroundColor: Color.colorDeepskyblue,
  },
  offlinebutton: {
    backgroundColor: Color.colorLightgray,
  },
  scrollable: {
    padding: Padding.p_2xs,
    alignSelf: "stretch",
    flex: 1,
  },
  addDeviceconfirmation: {
    backgroundColor: Color.colorGhostwhite,
    width: "100%",
    flex: 1,
  },
});

export default AddDeviceConfirmation;
