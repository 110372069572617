import * as React from "react";
import { useState, useCallback } from 'react';
import { StyleSheet, View, Pressable, Text, ScrollView, ActivityIndicator, RefreshControl, Alert, TextInput } from "react-native";
import { Image } from "expo-image";
import { DrawerNavigationProp } from "@react-navigation/drawer";
import { useNavigation, ParamListBase, useFocusEffect } from "@react-navigation/native";
import { Color, Padding, Border, FontSize, FontFamily } from "../GlobalStyles";
import { Platform } from 'react-native'; // Add this import

// Your API URL from Google Apps Script
const API_URL = 'https://script.google.com/macros/s/AKfycbw4vElmq7Yylc0u4s_xjCNZMssYQRHC5iR_mHR2FCz7jkxh_r9ISGO0mubHBY5rGVaWxQ/exec?username=chowx&key=xxx&numItems=20';

const Inventory = () => {
  const navigation = useNavigation<DrawerNavigationProp<ParamListBase>>();
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(true);
  const [refreshing, setRefreshing] = useState(false);
  const [error, setError] = useState('');
  const [search, setSearch] = useState('');

  const fetchData = async () => {
    setLoading(true);
    setRefreshing(true);

    try {
      const response = await fetch(API_URL);
      if (response.ok) {
        const data = await response.json();
        setItems(data);
        setError('');
      } else {
        const errorText = await response.text();
        setError(`Error: ${errorText}`);
      }
    } catch (e) {
      console.error(e);
      setError("Error fetching data, will retry...");
    } finally {
      setLoading(false);
      setRefreshing(false);
    }
  };

  useFocusEffect(
    useCallback(() => {
      fetchData();
    }, [])
  );

  const onRefresh = () => {
    fetchData();
  };

  const filteredItems = items.filter((item) =>
    Object.values(item).some(value =>
      value.toString().toLowerCase().includes(search.toLowerCase())
    )
  );

  if (loading) {
    return (
      <View style={styles.centered}>
        <ActivityIndicator size="large" color="#0000ff" />
      </View>
    );
  }

  if (error) {
    return (
      <View style={styles.centered}>
        <Text>{error}</Text>
      </View>
    );
  }

  return (
    <View style={styles.deviceDetail}>
      <View style={styles.top}>
        <View style={[styles.statusbar, styles.topbarPosition]} />
        <View style={[styles.topbar, styles.topbarPosition]}>
          <View style={styles.topbarinner}>
          <Pressable
            style={{
              width: 60, // Set a specific width for the button
              height: 60, // Set a specific height for the button
              justifyContent: 'center',
              alignItems: 'center',
              padding: 10, // Optional: additional padding
              backgroundColor: 'transparent', // Ensure background is transparent
            }}
            onPress={() => navigation.goBack()}
          >
            <Image
              style={{
                width: '60%', // Make the image fill the button
                height: '60%', // Make the image fill the button
                resizeMode: 'contain', // Ensure the image maintains its aspect ratio
              }}
              source={require("../assets/calender.png")}
            />
          </Pressable>
            <View style={styles.details}>
              <Text style={styles.toptext}>Inv</Text>
            </View>
            <Pressable
                onPress={() => navigation.navigate('InventoryQR')}>
            <Image
              style={styles.sliderhorizontal3Icon}
              contentFit="cover"
              source={require("../assets/sliderhorizontal3.png")}
            /></Pressable> 
          </View>
        </View>
      </View>
      <TextInput
        style={styles.searchBar}
        placeholder="Search by ID, Name, Type, or Description"
        value={search}
        onChangeText={setSearch}
      />
      <ScrollView
        style={styles.scrollable}
        showsVerticalScrollIndicator={false}
        refreshControl={
          <RefreshControl
            refreshing={refreshing}
            onRefresh={onRefresh}
          />
        }
      >
        {filteredItems.map((item, index) => (
          <Pressable
            key={index}
            style={styles.locationsFlexBox}
            onPress={() => navigation.navigate('ItemDetails', {
              id: item.ColumnA,
              name: item.ColumnB,
              serial: item.ColumnC,
              details: item.ColumnD,
            })}
          >
            <View style={[styles.locationcard, styles.dashCardBorder]}>
              <Image
                style={styles.locationimgIcon}
                contentFit="cover"
                source={require("../assets/deviceimg.png")}
              />
              <View style={styles.devicecardcontent}>
                <Text style={[styles.locationName, styles.locationNameLayout]} numberOfLines={1} ellipsizeMode="tail">
                  {item.ColumnB}
                </Text>
                <View style={styles.details3}>
                  <View style={styles.locframe}>
                    <Text style={styles.text4Typo}>{item.ColumnC}</Text>
                  </View>
                </View>
              </View>
            </View>
          </Pressable>
        ))}
        <View style={{ height: 40 }}></View>
      </ScrollView>
    </View>
  );
};

const styles = StyleSheet.create({
  topbarPosition: {
    backgroundColor: Color.colorGold,
    left: "0%",
    right: "0%",
    position: "absolute",
    width: "100%",
  },
  locationcardBorder: {
    padding: Padding.p_base,
    borderWidth: 1.3,
    borderColor: Color.colorGainsboro_100,
    borderStyle: "solid",
    backgroundColor: Color.systemBackgroundLightPrimary,
    borderRadius: Border.br_smi,
  },
  textTypo: {
    color: Color.labelColorLightSecondary,
    lineHeight: 18,
    fontSize: FontSize.size_smi,
    fontFamily: FontFamily.interBold,
    fontWeight: "700",
    textAlign: "left",
    letterSpacing: 0,
  },
  letterFlexBox: {
    textAlign: "center",
    color: Color.colorGray_100,
    zIndex: 1,
    lineHeight: 18,
    display: "flex",
    fontFamily: FontFamily.interSemiBold,
    fontWeight: "600",
    letterSpacing: 0,
    justifyContent: "center",
    position: "absolute",
    alignItems: "center",
  },
  devicedetailscardSpaceBlock: {
    marginTop: 11,
    alignSelf: "stretch",
  },
  iconLayout: {
    height: 20,
    zIndex: 0,
    width: 20,
  },
  leticon2Position: {
    right: 14,
    top: 14,
    position: "absolute",
  },
  detailTypo: {
    fontFamily: FontFamily.interRegular,
    fontSize: FontSize.size_base,
    color: Color.labelColorLightSecondary,
    lineHeight: 18,
    textAlign: "left",
    letterSpacing: 0,
  },
  statusbar: {
    height: "62.77%",
    top: "0%",
    bottom: "37.23%",
    alignItems: "flex-end",
    justifyContent: "center",
  },
  icon: {
    height: "100%",
    width: "100%",
  },
  hamburger: {
    height: 15,
    width: 20,
  },
  toptext: {
    textAlign: "left",
    color: Color.labelColorLightPrimary,
    fontFamily: FontFamily.interSemiBold,
    fontWeight: "600",
    lineHeight: 25,
    letterSpacing: 0,
    fontSize: FontSize.size_xl,
  },
  details: {
    paddingHorizontal: Padding.p_mini,
    paddingVertical: 0,
    flexDirection: "row",
    flex: 1,
  },
  sliderhorizontal3Icon: {
    width: 21,
    height: 18,
  },
  topbarinner: {
    height: 35,
    justifyContent: "space-between",
    paddingLeft: Padding.p_8xs,
    paddingRight: Padding.p_xs,
    paddingBottom: Padding.p_3xs,
    flexDirection: "row",
    alignSelf: "stretch",
    alignItems: "center",
  },
  topbar: {
    height: "37.23%",
    top: "62.77%",
    bottom: "0%",
    overflow: "hidden",
    alignItems: "center",
  },
  top: {
    height: 94,
    alignSelf: "stretch",
  },
  locationimgIcon: {
    borderRadius: Border.br_12xs,
    width: 60,
    height: 60,
  },
  locationtitle: {
    fontSize: FontSize.size_mini,
    lineHeight: 20,
    width: 262,
    display: "flex",
    fontFamily: FontFamily.interBold,
    fontWeight: "700",
    textAlign: "left",
    color: Color.labelColorLightPrimary,
    letterSpacing: 0,
    alignItems: "center",
  },
  text: {
    display: "none",
    width: 92,
    alignItems: "center",
  },
  lanyardcardIcon: {
    width: 11,
    height: 15,
  },
  nodevicesval: {
    marginLeft: 1,
  },
  nodevicesframe: {
    marginLeft: 10,
    flexDirection: "row",
    alignItems: "center",
  },
  pluscircleIcon: {
    width: 15,
    height: 15,
  },
  details2: {
    flexDirection: "row",
    alignItems: "center",
  },
  circleIcon: {
    zIndex: 0,
    width: 15,
    height: 15,
  },
  letter: {
    top: 2,
    left: 0,
    fontSize: FontSize.size_2xs,
    height: 12,
    zIndex: 1,
    width: 15,
  },
  centered: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  scrollableScrollViewContent: {
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "flex-start",
  },
  searchBar: {
    margin: 10,
    padding: 10,
    borderColor: '#ccc',
    borderWidth: 1,
    borderRadius: 8,
  },
  moneyinframe: {
    marginLeft: 10,
    flexDirection: "row",
    alignItems: "center",
  },
  locframe: {
    marginLeft: 0,
    flexDirection: "row",
    alignItems: "center",
  },
  text4Typo: {
    fontSize: FontSize.size_smi,
    color: Color.labelColorLightSecondary,
    fontFamily: FontFamily.interBold,
    fontWeight: "700",
    lineHeight: 18,
    textAlign: "left",
    letterSpacing: 0,
  },
  locationName: {
    fontSize: FontSize.size_mini,
    display: "flex",
    width: 262,
    fontFamily: FontFamily.interBold,
    fontWeight: "700",
    textAlign: "left",
    color: Color.labelColorLightPrimary,
    alignItems: "center",
  },
  locationNameLayout: {
    lineHeight: 20,
    letterSpacing: 0,
  },
  devicecardcontentLayout: {
    width: 288,
    marginLeft: 10,
  },
  devicecardcontent: {
    marginLeft: 10,
  },
  dashCardBorder: {
    padding: Padding.p_base,
    borderWidth: 1.3,
    borderColor: Color.colorGainsboro_100,
    borderStyle: "solid",
    borderRadius: Border.br_smi,
    flexDirection: "row",
  },
  locationsFlexBox: {
    marginTop: 11,
    alignSelf: "stretch",
    alignItems: "center",
  },
  scrollable: {
    padding: Padding.p_2xs,
    width: "100%",
    flex: 1,
  },
  deviceDetail: {
    backgroundColor: Color.colorGhostwhite,
    alignItems: "center",
    width: "100%",
    flex: 1,
  },
  contentFlexBox: {
    justifyContent: "space-between",
    flexDirection: "row",
  },
  
});

export default Inventory;