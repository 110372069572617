import * as React from 'react';
import { useState, useEffect } from 'react';
import { StyleSheet, View, Pressable, Text, ScrollView, RefreshControl } from 'react-native';
import { Image } from 'expo-image';
import { DrawerNavigationProp } from '@react-navigation/drawer';
import { useNavigation, ParamListBase, useRoute } from '@react-navigation/native';
import { FontFamily, Padding, Color, Border, FontSize } from '../GlobalStyles';
import { getLogByDev } from '../lib/getlog';

const Log = () => {
  const navigation = useNavigation<DrawerNavigationProp<ParamListBase>>();
  const route = useRoute();

  const [logs, setLogs] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [refreshing, setRefreshing] = useState(false);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    const deviceid = route.params?.deviceid;
    const billin = route.params?.billin;
    const ticketout = route.params?.ticketout;
    const start = 0;
    const end = 90;
  
    setIsLoading(true);
    return getLogByDev(deviceid, billin, ticketout, start, end)
      .then(data => {
        setLogs(data);
        setIsLoading(false);
      })
      .catch(err => {
        console.error('Failed to fetch logs:', err);
        setError(err.toString());
        setIsLoading(false);
      });
  };

  const onRefresh = React.useCallback(() => {
    if (!refreshing) { // Adding this check to ensure it does not trigger multiple times
      setRefreshing(true);
      fetchData().then(() => {
        setRefreshing(false); // Ensure this is executed after fetchData resolves
      });
    }
  }, [fetchData]); // Adding fetchData to the dependency list

  /*
  function checkParamsAndHandle(route) {
    if (route.params && route.params.deviceid) {
        handleX(route.params.x);
    } else if (route.params && route.params.locationid) {
        handleY(route.params.y);
    } else if (route.params && route.params.orgid) {
        handleY(route.params.y);
    } else {
        console.log("Neither parameter 'x' nor 'y' was found in route.params.");
    }
  }
    */

  


  return (
    <View style={styles.log}>
      <View style={styles.top}>
        <View style={styles.statusbar} />
        <View style={styles.topbar}>
          <View style={[styles.topbarinner, styles.topbarinnerFlexBox]}>
          <Pressable
            style={{
              width: 60, // Set a specific width for the button
              height: 60, // Set a specific height for the button
              justifyContent: 'center',
              alignItems: 'center',
              padding: 10, // Optional: additional padding
              backgroundColor: 'transparent', // Ensure background is transparent
            }}
            onPress={() => navigation.goBack()}
          >
            <Image
              style={{
                width: '60%', // Make the image fill the button
                height: '60%', // Make the image fill the button
                resizeMode: 'contain', // Ensure the image maintains its aspect ratio
              }}
              source={require("../assets/calender.png")}
            />
          </Pressable>
            <View style={[styles.details, styles.detailsSpaceBlock]}>
              <Text style={[styles.toptext, styles.titleTypo]}>Log</Text>
            </View>

            {/*}
            <Image
              style={styles.sliderhorizontal3Icon}
              contentFit="cover"
              source={require("../assets/sliderhorizontal3.png")}
            />*/}

          </View>
        </View>
      </View>

      {/*}
      <View style={styles.filtermenu}>
        <View style={[styles.filterinner, styles.detailsSpaceBlock]}>
          <View style={[styles.details, styles.detailsSpaceBlock]}>
            <View style={styles.sort}>
              <Image
                style={styles.sliderhorizontal3Icon1}
                contentFit="cover"
                source={require("../assets/sliderhorizontal31.png")}
              />
              <Text style={[styles.title, styles.titleTypo]}>Sort By</Text>
            </View>
            <View style={styles.filter}>
              <Image
                style={styles.sliderhorizontal3Icon1}
                contentFit="cover"
                source={require("../assets/sliderhorizontal32.png")}
              />
              <Text style={[styles.title, styles.titleTypo]}>Filter</Text>
            </View>
          </View>
        </View>
      </View>*/}

      <ScrollView
        style={styles.scrollable}
        refreshControl={
          <RefreshControl
            refreshing={refreshing}
            onRefresh={onRefresh}
          />
        }
        showsVerticalScrollIndicator={false}
        showsHorizontalScrollIndicator={false}
        contentContainerStyle={styles.scrollableScrollViewContent}
      >




        {isLoading ? (
                <Text>Loading...</Text>
            ) : error ? (
                <Text>Error: {error}</Text>
            ) : (
              <>
              {logs.map(log => (
              <View style={styles.logcardBorder} key={log.eventid}>
              <View style={styles.content}>
                <View>
                  <View style={styles.eventandtime}>
                    <Text style={[styles.logEvent, styles.logEventTypo, { color: log.color }]}>
                      ${log.amount} {log.eventtype}
                    </Text>
                    <Text style={[styles.timestamp, styles.logEventTypo]}>
                      {log.time.toString()}
                    </Text>
                  </View>
                  { /*}
                  <View style={styles.detailsall}>
                    <Text
                      style={[styles.details2, styles.logEventTypo]}
                    >{`Detailsdetailsdetails Detailsdetailsdetails `} {log.color}</Text>
                  </View> */  }
                </View>
              </View>
            </View>
             ))}
             </>
        )}

        
        
        
      </ScrollView>
    </View>
  );
};

const styles = StyleSheet.create({
  scrollableScrollViewContent: {
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "flex-start",
  },
  topbarinnerFlexBox: {
    justifyContent: "space-between",
    height: 35,
    alignItems: "center",
    alignSelf: "stretch",
  },
  detailsSpaceBlock: {
    paddingVertical: 0,
    flexDirection: "row",
  },
  titleTypo: {
    textAlign: "left",
    fontFamily: FontFamily.interSemiBold,
    fontWeight: "600",
    letterSpacing: 0,
  },
  logEventTypo: {
    textAlign: "center",
    fontFamily: FontFamily.interBold,
    fontWeight: "700",
    letterSpacing: 0,
  },
  logcardBorder: {
    padding: Padding.p_base,
    borderWidth: 1.3,
    borderColor: Color.colorGainsboro_100,
    borderStyle: "solid",
    borderRadius: Border.br_smi,
    backgroundColor: Color.systemBackgroundLightPrimary,
    flexDirection: "row",
    overflow: "hidden",
    alignSelf: "stretch",
    marginBottom: 5,
  },
  statusbar: {
    height: "62.77%",
    top: "0%",
    bottom: "37.23%",
    alignItems: "flex-end",
    justifyContent: "center",
    backgroundColor: Color.colorGold,
    left: "0%",
    right: "0%",
    position: "absolute",
    width: "100%",
  },
  icon: {
    height: "100%",
    width: "100%",
  },
  hamburger: {
    width: 20,
    height: 15,
  },
  toptext: {
    fontSize: FontSize.size_xl,
    lineHeight: 25,
    color: Color.labelColorLightPrimary,
  },
  details: {
    paddingHorizontal: Padding.p_mini,
    flex: 1,
  },
  sliderhorizontal3Icon: {
    width: 21,
    height: 18,
  },
  topbarinner: {
    paddingLeft: Padding.p_8xs,
    paddingRight: Padding.p_xs,
    paddingBottom: Padding.p_3xs,
    flexDirection: "row",
  },
  topbar: {
    height: "37.23%",
    top: "62.77%",
    bottom: "0%",
    alignItems: "center",
    overflow: "hidden",
    backgroundColor: Color.colorGold,
    left: "0%",
    right: "0%",
    position: "absolute",
    width: "100%",
  },
  top: {
    height: 94,
    alignSelf: "stretch",
  },
  sliderhorizontal3Icon1: {
    width: 14,
    height: 12,
  },
  title: {
    fontSize: FontSize.size_mini,
    lineHeight: 20,
    color: Color.colorDimgray_100,
    marginLeft: 2,
  },
  sort: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
  filter: {
    marginLeft: 20,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
  filterinner: {
    paddingHorizontal: Padding.p_8xs,
    justifyContent: "space-between",
    height: 35,
    alignItems: "center",
    alignSelf: "stretch",
  },
  filtermenu: {
    height: 30,
    backgroundColor: Color.systemBackgroundLightPrimary,
    overflow: "hidden",
    justifyContent: "center",
    alignSelf: "stretch",
  },
  logEvent: {
    lineHeight: 13,
    fontSize: FontSize.size_smi,
    fontFamily: FontFamily.interBold,
    fontWeight: "700",
    color: Color.labelColorLightPrimary,
  },
  timestamp: {
    color: Color.colorDimgray_200,
    marginLeft: 10,
    lineHeight: 13,
    fontSize: FontSize.size_smi,
    fontFamily: FontFamily.interBold,
    fontWeight: "700",
  },
  eventandtime: {
    flexDirection: "row",
    justifyContent: "center",
  },
  details2: {
    fontSize: FontSize.size_xs,
    lineHeight: 15,
    color: Color.colorGray_300,
  },
  detailsall: {
    width: 260,
    marginTop: 2,
    flexDirection: "row",
  },
  content: {
    flexDirection: "row",
    alignItems: "center",
    flex: 1,
  },
  logcard1: {
    marginTop: 11,
  },
  scrollable: {
    padding: Padding.p_2xs,
    alignSelf: "stretch",
    flex: 1,
  },
  log: {
    backgroundColor: Color.colorGhostwhite,
    width: "100%",
    flex: 1,
  },
});

export default Log;