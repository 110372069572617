import { supabase } from './supabase'

export async function getDeviceDetails(deviceId) {
  try {
    // Fetch device details
    const {
      data: device,
      error: deviceError
    } = await supabase
      .from('devices')
      .select('locationid, devicename, devicedesc, deviceimg, deviceid')
      .eq('deviceid', deviceId)
      .single();

    if (deviceError) {
      throw deviceError;
    }

    if (!device) {
      throw new Error('No device found');
    }

    // Fetch location details and device count
    const { data: locationDetails, error: locationError } = await supabase
      .from('locations')
      .select(`
        locationname, 
        locationaddress, 
        locationcity, 
        locationstate, 
        locationzip, 
        locationcountry, 
        locationimg,
        devices
      `)
      .eq('locationid', device.locationid)
      .single();

    if (locationError) {
      throw locationError;
    }

    if (!locationDetails) {
      throw new Error('Location details could not be found');
    }

    const deviceCount = locationDetails.devices.length;

    // Fetch RPC statistics
    const [monthTotalByLocation, total24Location, ...stats] = await Promise.all([
      supabase.rpc('totalmonthbylocation', { p_locationid: device.locationid }),
      supabase.rpc('total24bylocation', { p_locationid: device.locationid }),
      supabase.rpc('total24bydevice', { p_deviceid: deviceId }),
      supabase.rpc('totalweekbydevice', { p_deviceid: deviceId }),
      supabase.rpc('totalmonthbydevice', { p_deviceid: deviceId }),
      supabase.rpc('totalytdbydevice', { p_deviceid: deviceId })
    ]).then(results => results.map(result => result.data));

    // Prepare the return object
    const locationsWithTotals = {
      device,
      location: {
        ...locationDetails,
        deviceCount,
        monthTotalByLocation,
        total24Location
      },
      totals24h: stats[0],
      totalsWeek: stats[1],
      totalsMonth: stats[2],
      totalsYTD: stats[3]
    };

    return locationsWithTotals;
  } catch (error) {
    console.error('Error fetching device details:', error);
    throw error;
  }
}