import * as React from "react";
import {
  StyleSheet,
  View,
  Pressable,
  Text,
  ScrollView,
  TextInput,
  Alert
} from "react-native";
import { Image } from "expo-image";
import { DrawerNavigationProp } from "@react-navigation/drawer";
import { useNavigation, ParamListBase, useRoute } from "@react-navigation/native";
import { StackNavigationProp } from "@react-navigation/stack";
import { Color, FontFamily, FontSize, Padding, Border } from "../GlobalStyles";
import { supabase } from '../lib/supabase';
import { useState, useEffect } from 'react';
import { useOrg } from "../lib/useOrg";

const LocationEdit = () => {
  const navigation = useNavigation<DrawerNavigationProp<ParamListBase>>();
  const route = useRoute();
  const { currentOrganization } = useOrg() || {};

  const [locationData, setLocationData] = useState({
    orgid: currentOrganization?.orgid || '', // Use optional chaining with fallback
    locationname: '',
    locationaddress: '',
    locationcity: '',
    locationzip: '',
    locationcountry: '',
    locationstate: '',
    locationimg: '',
  });

  useEffect(() => {
    // This check ensures that currentOrganization is not null or undefined
    if (currentOrganization) {
      setLocationData(prev => ({
        ...prev,
        orgid: currentOrganization.orgid,
      }));
    } else {

    }
  }, [currentOrganization, navigation]);

  const handleInputChange = (field, value) => {
    setLocationData(prev => ({ ...prev, [field]: value }));
  };

  console.log("Organization ID in Location Data: ", locationData.orgid);

  const handleSubmit = async () => {
    if (!locationData.locationname.trim()) {
      Alert.alert('Validation Error', 'Location name is required', [{ text: 'OK' }]);
      return;
    }
    try {
      // Attempt to insert location data into the 'locations' table
      const { data, error } = await supabase.from('locations').insert([locationData]);
      
      // Navigate to the next page and pass the locationid. Navigate to 'Back' if no next page is specified
      navigation.navigate(route.params?.nextpage || "Back", { refresh: true });
  
      } catch (e) {
          // Handle any kind of exception here, including issues with data integrity or server errors
          Alert.alert('Error', `Failed to update location: ${e.message}`, [{ text: 'OK' }]);
      }
  };

  if (!currentOrganization) {
    return <Text>Loading organization data...</Text>;
  }

  return (
    <View style={styles.locationEdit}>
      <View style={styles.top}>
        <View style={[styles.statusbar, styles.topbarPosition]} />
        <View style={[styles.topbar, styles.saveFlexBox]}>
          <View style={styles.topbarinner}>
            <Pressable
              style={styles.hamburger}
              onPress={() => navigation.toggleDrawer()}
            >
              <Image
                style={styles.icon}
                contentFit="cover"
                source={require("../assets/hamburger.png")}
              />
            </Pressable>
            <View style={styles.details}>
              <Text style={styles.toptextTypo}>{route.params?.toptitle || "Edit Location Name"}</Text>
            </View>
          </View>
        </View>
      </View>
      <ScrollView
        style={styles.frame}
        showsVerticalScrollIndicator={false}
        showsHorizontalScrollIndicator={false}
        contentContainerStyle={styles.frameScrollViewContent}
      >
        <Pressable
          style={styles.title}
          onPress={() => navigation.goBack()}
        >
          <Image
            style={styles.arrowuturnleftsquareIcon}
            contentFit="cover"
            source={require("../assets/arrowuturnleftsquare2.png")}
          />
          <Text style={[styles.locationName, styles.toptextTypo]}>
            {route.params?.backtitle || "Back"}
          </Text>
        </Pressable>
        <View style={[styles.devicedetailsform, styles.saveBorder]}>
          <Text style={[styles.name, styles.nameTypo]}>Location Name</Text>
          <TextInput
            style={[styles.devicename, styles.detailSpaceBlock]}
            placeholder="Location Name"
            placeholderTextColor="rgba(60, 60, 67, 0.3)"
            value={locationData.locationname}
            onChangeText={text => handleInputChange('locationname', text)}
          />
          <Text style={[styles.detail, styles.detailSpaceBlock]}>Address</Text>
          <TextInput
            style={[styles.devicename, styles.detailSpaceBlock]}
            placeholder="Address"
            placeholderTextColor="rgba(60, 60, 67, 0.3)"
            value={locationData.locationaddress}
            onChangeText={text => handleInputChange('locationaddress', text)}
          />
          <Text style={[styles.detail, styles.detailSpaceBlock]}>City</Text>
          <TextInput
            style={[styles.devicename, styles.detailSpaceBlock]}
            placeholder="City"
            placeholderTextColor="rgba(60, 60, 67, 0.3)"
            value={locationData.locationcity}
            onChangeText={text => handleInputChange('locationcity', text)}
          />
          <Text style={[styles.detail, styles.detailSpaceBlock]}>State</Text>
          <TextInput
            style={[styles.devicename, styles.detailSpaceBlock]}
            placeholder="State"
            placeholderTextColor="rgba(60, 60, 67, 0.3)"
            value={locationData.locationstate}
            onChangeText={text => handleInputChange('locationstate', text)}
          />
          <Text style={[styles.detail, styles.detailSpaceBlock]}>Zip Code</Text>
          <TextInput
            style={[styles.devicename, styles.detailSpaceBlock]}
            placeholder="Zip Code"
            placeholderTextColor="rgba(60, 60, 67, 0.3)"
            value={locationData.locationzip}
            onChangeText={text => handleInputChange('locationzip', text)}
          />
          <Text style={[styles.detail, styles.detailSpaceBlock]}>Country</Text>
          <TextInput
            style={[styles.devicename, styles.detailSpaceBlock]}
            placeholder="Country"
            placeholderTextColor="rgba(60, 60, 67, 0.3)"
            value={locationData.locationcountry}
            onChangeText={text => handleInputChange('locationcountry', text)}
          />
        </View>
        <Pressable style={[styles.save, styles.saveBorder]}
          onPress={handleSubmit}>

          {/* onPress={() => navigation.navigate(route.params?.nextpage || "Back")*/}

          <Text style={styles.save1}>Save</Text>
        </Pressable>
      </ScrollView>
    </View>
  );
};

const styles = StyleSheet.create({
  frameScrollViewContent: {
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "flex-start",
  },
  topbarPosition: {
    backgroundColor: Color.colorGold,
    left: "0%",
    right: "0%",
    position: "absolute",
    width: "100%",
  },
  saveFlexBox: {
    overflow: "hidden",
    alignItems: "center",
  },
  toptextTypo: {
    textAlign: "left",
    color: Color.labelColorLightPrimary,
    fontFamily: FontFamily.interSemiBold,
    fontWeight: "600",
    lineHeight: 25,
    letterSpacing: 0,
    fontSize: FontSize.size_xl,
  },
  saveBorder: {
    marginTop: 11,
    padding: Padding.p_base,
    borderWidth: 1.3,
    borderColor: Color.colorGainsboro_100,
    borderStyle: "solid",
    borderRadius: Border.br_smi,
    alignSelf: "stretch",
  },
  nameTypo: {
    color: Color.colorGray_200,
    lineHeight: 20,
    textAlign: "left",
    fontFamily: FontFamily.interSemiBold,
    fontWeight: "600",
    letterSpacing: 0,
    fontSize: FontSize.size_xl,
  },
  detailSpaceBlock: {
    marginTop: 10,
    alignSelf: "stretch",
  },
  statusbar: {
    height: "62.77%",
    top: "0%",
    bottom: "37.23%",
    alignItems: "flex-end",
    justifyContent: "center",
  },
  icon: {
    height: "100%",
    width: "100%",
  },
  hamburger: {
    width: 20,
    height: 15,
  },
  details: {
    paddingHorizontal: Padding.p_mini,
    paddingVertical: 0,
    flexDirection: "row",
    flex: 1,
  },
  topbarinner: {
    height: 35,
    justifyContent: "space-between",
    paddingLeft: Padding.p_8xs,
    paddingRight: Padding.p_xs,
    paddingBottom: Padding.p_3xs,
    flexDirection: "row",
    alignItems: "center",
    alignSelf: "stretch",
  },
  topbar: {
    height: "37.23%",
    top: "62.77%",
    bottom: "0%",
    alignItems: "center",
    backgroundColor: Color.colorGold,
    left: "0%",
    right: "0%",
    position: "absolute",
    width: "100%",
  },
  top: {
    height: 94,
    alignSelf: "stretch",
  },
  arrowuturnleftsquareIcon: {
    width: 16,
    height: 16,
  },
  locationName: {
    marginLeft: 10,
  },
  title: {
    flexDirection: "row",
    alignItems: "center",
  },
  name: {
    alignSelf: "stretch",
  },
  devicename: {
    borderRadius: Border.br_3xs,
    backgroundColor: Color.fillColorLightTertiary,
    paddingHorizontal: Padding.p_5xs,
    paddingVertical: Padding.p_6xs,
    fontFamily: FontFamily.interRegular,
    fontSize: FontSize.size_mid,
    flexDirection: "row",
    alignItems: "center",
  },
  detail: {
    color: Color.colorGray_200,
    lineHeight: 20,
    textAlign: "left",
    fontFamily: FontFamily.interSemiBold,
    fontWeight: "600",
    letterSpacing: 0,
    fontSize: FontSize.size_xl,
  },
  devicedetailsform: {
    backgroundColor: Color.systemBackgroundLightPrimary,
  },
  save1: {
    lineHeight: 22,
    fontWeight: "500",
    fontFamily: FontFamily.interMedium,
    color: Color.systemBackgroundLightPrimary,
    textAlign: "center",
    letterSpacing: 0,
    fontSize: FontSize.size_xl,
    height: 19
  },
  save: {
    backgroundColor: Color.colorDeepskyblue,
    height: 43,
    flexDirection: "row",
    alignItems: "center",
    overflow: "hidden",
    justifyContent: "center",
  },
  frame: {
    padding: Padding.p_2xs,
    alignSelf: "stretch",
    flex: 1,
  },
  locationEdit: {
    backgroundColor: Color.colorGhostwhite,
    width: "100%",
    flex: 1,
  },
});

export default LocationEdit;