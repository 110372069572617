import { supabase } from './supabase';

export default async function getOrganizationDetails(userId) {
    if (!userId) {
        console.error("User ID is required");
        return;
    }

    let { data: organizations, error } = await supabase
      .from('orgs')
      .select('*')
      .contains('members', [userId]); 
  
    if (error) {
        console.error('Error fetching organization details', error);
        return;
    }

    return organizations;
}