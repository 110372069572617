import * as React from "react";
import {
  StyleSheet,
  View,
  Pressable,
  Text,
  ScrollView,
  TextInput,
  TouchableOpacity,
  Dimensions,
  Button,
  Alert
} from "react-native";
import { Image } from "expo-image";
import { DrawerNavigationProp } from "@react-navigation/drawer";
import { useNavigation, ParamListBase, useRoute } from "@react-navigation/native";
import { StackNavigationProp } from "@react-navigation/stack";
import { Color, Padding, Border, FontFamily, FontSize } from "../GlobalStyles";
import { CameraView, CameraType, useCameraPermissions } from 'expo-camera';
import { useState, useEffect } from "react";
import { AutoFocus } from "expo-camera/build/legacy/Camera.types";
import { supabase } from "../lib/supabase";

const windowWidth = Dimensions.get('window').width;
const cameraSize = Math.min(windowWidth, 500);

const AddQRSetup = () => {
  const navigation = useNavigation<DrawerNavigationProp<ParamListBase>>();
  const [facing, setFacing] = useState('back');
  const [permission, requestPermission] = useCameraPermissions();
  const [deviceId, setDeviceId] = useState('');
  const [error, setError] = useState('');
  const [scanned, setScanned] = useState(false);
  const route = useRoute();
  const [focus, setFocus] = useState<boolean>(true);

  console.log("locidx ", route.params?.locationId);

  //const uuidRegex = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i;
  const uuidRegex = /^([0-9a-zA-Z]{8}-[0-9a-zA-Z]{4}-[0-9a-zA-Z]{4}-[0-9a-zA-Z]{4}-[0-9a-zA-Z]{12}|[0-9a-zA-Z]{32})$/i;

  const handleBarCodeScanned = ({ type, data }) => {
    if (!scanned) {
      setScanned(true);

      if (uuidRegex.test(data)) {
        setDeviceId(data);
        validateDevice(data)
          .catch(error => {
            console.error(error);
          })
          .finally(() => {
            setTimeout(() => {
              setScanned(false);
            }, 3000);
          });
      } else {
        setError('Invalid QR Code format.');
        Alert.alert("Error", "Scanned QR Code is not a valid Device ID.", [
          { text: "OK", onPress: () => setTimeout(() => setScanned(false), 1000) } // Slight delay after the user dismisses the alert
        ]);
      }
    }
  };

  const validateDevice = async (id) => {
    if (!uuidRegex.test(id)) {
      setError('Invalid Device ID format. Please try again.');
      Alert.alert("Error", "Invalid Device ID format. Please try again.");
      return;
    }

    const { data, error } = await supabase
      .from('devices')
      .select('lastonline, locationid')
      .eq('deviceid', id)
      .single();

    if (error) {
      setError('Failed to fetch device data.');
      Alert.alert("Error", "Failed to fetch device data. Please try again.");
      return;
    }

    if (data) {
      const lastOnline = new Date(data.lastonline);
      const now = new Date();
      const oneHourAgo = new Date(now.getTime() - (60 * 60 * 1000));

      if (lastOnline > oneHourAgo) {
        navigation.navigate('AddDeviceDetails', { deviceId: id, locationId: route.params?.locationId });
      } else {
        const hoursSinceLastOnline = Math.floor((now - lastOnline) / (3600 * 1000));
        setError(`Device was last online ${hoursSinceLastOnline} hours ago.`);
        Alert.alert("Device Offline", `Device was last online ${hoursSinceLastOnline} hours ago.`, [
          { text: "Check WiFi Instructions", onPress: () => navigation.navigate('AddConnectWifi') },
          { text: "Scan Again", onPress: () => setDeviceId('') }
        ]);
      }
    } else {
      setError('Device has never been online.');
      Alert.alert("Device Offline", "Device has never been online.", [
        { text: "Check WiFi Instructions", onPress: () => navigation.navigate('WiFiInstructions') },
        { text: "Scan Again", onPress: () => setDeviceId('') }
      ]);
    }
  };

  if (permission?.status === 'undetermined') {
    return (
      <View style={{ marginTop: 300 }}>
        <Text>We need your permission to show the camera</Text>
        <Button onPress={requestPermission} title="Grant Permission" />
      </View>
    );
  }

  const focusCamera = () => {
    setFocus(false);
    console.log("focus");
    setTimeout(() => {
        setFocus(true);
    }, 200);
  }

  const handleManualEntry = () => {
    if (uuidRegex.test(deviceId)) {
      validateDevice(deviceId);
    } else {
      setError('Invalid Device ID format. Please enter a valid UUID.');
      Alert.alert("Error", "Invalid Device ID format. Please enter a valid UUID.");
    }
  }

  return (
    <View style={styles.addDevicedetails}>
      <View style={styles.top}>
        <View style={[styles.statusbar, styles.topbarPosition]} />
        <View style={[styles.topbar, styles.topbarFlexBox]}>
          <View style={styles.topbarinner}>
          <Pressable
            style={{
              width: 60, // Set a specific width for the button
              height: 60, // Set a specific height for the button
              justifyContent: 'center',
              alignItems: 'center',
              padding: 10, // Optional: additional padding
              backgroundColor: 'transparent', // Ensure background is transparent
            }}
            onPress={() => navigation.goBack()}
          >
            <Image
              style={{
                width: '60%', // Make the image fill the button
                height: '60%', // Make the image fill the button
                resizeMode: 'contain', // Ensure the image maintains its aspect ratio
              }}
              source={require("../assets/calender.png")}
            />
          </Pressable>
            <View style={styles.details}>
              <Text style={[styles.toptext, styles.toptextFlexBox]}>
                Add Board ID
              </Text>
            </View>
          </View>
        </View>
      </View>
      
      <ScrollView
        style={styles.frame}
        showsVerticalScrollIndicator={false}
        showsHorizontalScrollIndicator={false}
        contentContainerStyle={styles.frameScrollViewContent}
      >
        <CameraView 
          style={styles.camera} 
          autofocus={focus} 
          facing={facing} 
          barcodeScannerSettings={{ barcodeTypes: ["qr"], }} 
          onBarcodeScanned={scanned ? undefined : handleBarCodeScanned}>
          <TouchableOpacity
              activeOpacity={1}
              onPress={focusCamera}
              style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', backgroundColor: 'transparent' }}
          />
          <View style={styles.buttonContainer}>
            {/*}
            <TouchableOpacity style={styles.button} onPress={toggleCameraFacing}>
              <Text style={styles.text}>Flip Camera</Text>
            </TouchableOpacity>
            */}
          </View>
        </CameraView>
        <Text style={styles.deviceDetails}>Scan Device ID QR</Text>
        <Text style={[styles.toptextFlexBox]}>
            Or Enter Below
          </Text>
        <View style={[styles.devicedetailsform, styles.errorcardBorder]}>
          <Text style={[styles.name, styles.nameTypo]}>Device ID</Text>
          <TextInput
            style={[styles.devicename, styles.detailSpaceBlock]}
            placeholder="888b88b8-e888-8c8e-bbc8-8edf88f8b888"
            placeholderTextColor="rgba(60, 60, 67, 0.3)"
            value={deviceId}
            onChangeText={setDeviceId}
          />
        </View>
        <Pressable
          style={[styles.adddevicebutton, styles.errorcardBorder]}
          onPress={handleManualEntry}
        >
          <Text style={[styles.addDevice, styles.addDeviceFlexBox]}>
            Continue
          </Text>
        </Pressable>
      </ScrollView>
    </View>
  );
};


const styles = StyleSheet.create({
  camera: {
    width: cameraSize,
    height: cameraSize,
  },
  frameScrollViewContent: {
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "flex-start",
  },
  topbarPosition: {
    backgroundColor: Color.colorGold,
    left: "0%",
    right: "0%",
    position: "absolute",
    width: "100%",
  },
  topbarFlexBox: {
    overflow: "hidden",
    alignItems: "center",
  },
  toptextFlexBox: {
    textAlign: "left",
    letterSpacing: 0,
  },
  addDeviceFlexBox: {
    textAlign: "center",
    letterSpacing: 0,
  },
  errorcardBorder: {
    margin: 7,
    padding: Padding.p_base,
    borderWidth: 1.3,
    borderColor: Color.colorGainsboro_100,
    borderStyle: "solid",
    borderRadius: Border.br_smi,
    alignSelf: "stretch",
  },
  nameTypo: {
    lineHeight: 20,
    color: Color.colorGray_200,
    textAlign: "left",
    fontFamily: FontFamily.interSemiBold,
    fontWeight: "600",
    letterSpacing: 0,
    fontSize: FontSize.size_xl,
  },
  detailSpaceBlock: {
    marginTop: 10,
    alignSelf: "stretch",
  },
  statusbar: {
    height: "62.77%",
    top: "0%",
    bottom: "37.23%",
    alignItems: "flex-end",
    justifyContent: "center",
  },
  icon: {
    height: "100%",
    width: "100%",
  },
  hamburger: {
    height: 15,
    width: 20,
  },
  toptext: {
    color: Color.labelColorLightPrimary,
    fontFamily: FontFamily.interSemiBold,
    fontWeight: "600",
    lineHeight: 25,
    textAlign: "left",
    letterSpacing: 0,
    fontSize: FontSize.size_xl,
  },
  details: {
    paddingHorizontal: Padding.p_mini,
    paddingVertical: 0,
    flexDirection: "row",
    flex: 1,
  },
  topbarinner: {
    height: 35,
    justifyContent: "space-between",
    paddingLeft: Padding.p_8xs,
    paddingRight: Padding.p_xs,
    paddingBottom: Padding.p_3xs,
    flexDirection: "row",
    alignItems: "center",
    alignSelf: "stretch",
  },
  topbar: {
    height: "37.23%",
    top: "62.77%",
    bottom: "0%",
    alignItems: "center",
    backgroundColor: Color.colorGold,
    left: "0%",
    right: "0%",
    position: "absolute",
    width: "100%",
  },
  top: {
    height: 94,
    alignSelf: "stretch",
  },
  arrowleft: {
    height: 16,
    width: 20,
  },
  eventimgIcon: {
    borderRadius: Border.br_12xs,
    width: 30,
    height: 30,
    marginLeft: 8,
  },
  locationName: {
    fontSize: FontSize.size_smi,
    lineHeight: 13,
    fontFamily: FontFamily.interBold,
    fontWeight: "700",
    textAlign: "center",
    color: Color.labelColorLightPrimary,
  },
  yinzStBitsburgh: {
    fontSize: FontSize.size_xs,
    lineHeight: 15,
    color: Color.colorGray_300,
    fontFamily: FontFamily.interBold,
    fontWeight: "700",
    textAlign: "center",
  },
  yinzStBitsburghPa15001Wrapper: {
    width: 260,
    marginTop: 2,
    flexDirection: "row",
  },
  details1: {
    marginLeft: 8,
  },
  arrowleftParent: {
    width: 314,
    flexDirection: "row",
    alignItems: "center",
  },
  content: {
    flexDirection: "row",
    alignItems: "center",
    flex: 1,
  },
  locationbackcard: {
    padding: Padding.p_base,
    borderWidth: 1.3,
    borderColor: Color.colorGainsboro_100,
    borderStyle: "solid",
    borderRadius: Border.br_smi,
    backgroundColor: Color.systemBackgroundLightPrimary,
    flexDirection: "row",
    overflow: "hidden",
    alignSelf: "stretch",
  },
  errorBody: {
    fontSize: FontSize.size_base,
    lineHeight: 18,
    color: Color.labelColorLightSecondary,
    marginTop: 20,
    fontFamily: FontFamily.interRegular,
    textAlign: "left",
    letterSpacing: 0,
  },
  errorcard: {
    backgroundColor: Color.systemBackgroundLightPrimary,
    marginTop: 11,
    alignItems: "center",
    justifyContent: "center",
  },
  deviceDetails: {
    color: Color.colorGray_200,
    marginTop: 11,
    textAlign: "left",
    fontFamily: FontFamily.interSemiBold,
    fontWeight: "600",
    lineHeight: 25,
    letterSpacing: 0,
    fontSize: FontSize.size_xl,
  },
  name: {
    alignSelf: "stretch",
  },
  devicename: {
    borderRadius: Border.br_3xs,
    backgroundColor: Color.fillColorLightTertiary,
    paddingHorizontal: Padding.p_5xs,
    paddingVertical: Padding.p_6xs,
    fontSize: FontSize.size_mid,
    fontFamily: FontFamily.interRegular,
    flexDirection: "row",
    alignItems: "center",
  },
  detail: {
    lineHeight: 20,
    color: Color.colorGray_200,
    textAlign: "left",
    fontFamily: FontFamily.interSemiBold,
    fontWeight: "600",
    letterSpacing: 0,
    fontSize: FontSize.size_xl,
  },
  devicedetailsform: {
    backgroundColor: Color.systemBackgroundLightPrimary,
    marginTop: 11,
  },
  addDevice: {
    lineHeight: 21,
    fontWeight: "500",
    fontFamily: FontFamily.interMedium,
    color: Color.systemBackgroundLightPrimary,
    textAlign: "center",
    fontSize: FontSize.size_xl,
    height: 18
  },
  adddevicebutton: {
    backgroundColor: Color.colorDeepskyblue,
    height: 43,
    flexDirection: "row",
    alignItems: "center",
    overflow: "hidden",
    justifyContent: "center",
  },
  frame: {
    paddingBottom: Padding.p_2xl,
    marginBottom: 20,
    alignSelf: "stretch",
    flex: 1,
  },
  addDevicedetails: {
    backgroundColor: Color.colorGhostwhite,
    width: "100%",
    flex: 1,
  },
});

export default AddQRSetup;
