import * as React from "react";
import { StyleSheet, View, Pressable, Text, ScrollView } from "react-native";
import { Image } from "expo-image";
import { DrawerNavigationProp } from "@react-navigation/drawer";
import { useNavigation, ParamListBase } from "@react-navigation/native";
import { StackNavigationProp } from "@react-navigation/stack";
import { FontFamily, Color, FontSize, Padding, Border } from "../GlobalStyles";

const Revenue = () => {
  const navigation = useNavigation<DrawerNavigationProp<ParamListBase>>();

  return (
    <View style={styles.revenue}>
      <View style={styles.top}>
        <View style={styles.statusbar} />
        <View style={styles.topbar}>
          <View style={styles.topbarinner}>
            <Pressable
              style={styles.hamburger}
              onPress={() => navigation.toggleDrawer()}
            >
              <Image
                style={styles.icon}
                contentFit="cover"
                source={require("../assets/hamburger.png")}
              />
            </Pressable>
            <View style={styles.details}>
              <Text style={styles.toptext}>Revenue</Text>
            </View>
          </View>
        </View>
      </View>
      <ScrollView
        style={styles.scrollable}
        showsVerticalScrollIndicator={false}
        showsHorizontalScrollIndicator={false}
        contentContainerStyle={styles.scrollableScrollViewContent}
      >
        <Pressable
          style={styles.revenuecardswinsbets}
          onPress={() => navigation.navigate("RevenueDetail")}
        >
          <Pressable
            style={styles.dashWinsCard}
            onPress={() => navigation.navigate("RevenueDetail")}
          >
            <Image
              style={[styles.pluscircleIcon, styles.iconLayout]}
              contentFit="cover"
              source={require("../assets/pluscircle.png")}
            />
            <View style={styles.content}>
              <View style={styles.details1}>
                <View>
                  <Text style={[styles.today1, styles.textTypo]}>Today</Text>
                  <Text style={[styles.text, styles.textTypo]}>
                    +$124236.30
                  </Text>
                </View>
                <View style={styles.total}>
                  <Text style={[styles.today1, styles.textTypo]}>
                    Total Revenue
                  </Text>
                  <Text style={[styles.text, styles.textTypo]}>$102311.70</Text>
                </View>
              </View>
              <View style={styles.graph} />
            </View>
          </Pressable>
        </Pressable>
        <Pressable
          style={styles.revenuecardswinsbets1}
          onPress={() => navigation.navigate("RevenueDetail")}
        >
          <View style={styles.dashWinsCard}>
            <Image
              style={[styles.pluscircleIcon, styles.iconLayout]}
              contentFit="cover"
              source={require("../assets/minuscircle.png")}
            />
            <View style={styles.content}>
              <View style={styles.details1}>
                <View>
                  <Text style={[styles.today1, styles.textTypo]}>Today</Text>
                  <Text style={[styles.text, styles.textTypo]}>
                    +$124236.30
                  </Text>
                </View>
                <View style={styles.total}>
                  <Text style={[styles.today1, styles.textTypo]}>
                    Total Payouts
                  </Text>
                  <Text style={[styles.text, styles.textTypo]}>$102311.70</Text>
                </View>
              </View>
              <View style={styles.graph} />
            </View>
          </View>
        </Pressable>
        <Pressable
          style={styles.revenuecardswinsbets1}
          onPress={() => navigation.navigate("RevenueDetail")}
        >
          <View style={styles.dashWinsCard}>
            <View style={[styles.leticon, styles.leticonPosition]}>
              <Image
                style={[styles.circleIcon, styles.iconLayout]}
                contentFit="cover"
                source={require("../assets/circle1.png")}
              />
              <Text style={styles.letter}>B</Text>
            </View>
            <View style={styles.content}>
              <View style={styles.details1}>
                <View>
                  <Text style={[styles.today1, styles.textTypo]}>Today</Text>
                  <Text style={[styles.text, styles.textTypo]}>
                    +$124236.30
                  </Text>
                </View>
                <View style={styles.total}>
                  <Text style={[styles.today1, styles.textTypo]}>
                    Total Bets
                  </Text>
                  <Text style={[styles.text, styles.textTypo]}>$102311.70</Text>
                </View>
              </View>
              <View style={styles.graph} />
            </View>
          </View>
        </Pressable>
        <Pressable
          style={styles.revenuecardswinsbets1}
          onPress={() => navigation.navigate("RevenueDetail")}
        >
          <View style={styles.dashWinsCard}>
            <View style={[styles.leticon, styles.leticonPosition]}>
              <Image
                style={[styles.circleIcon, styles.iconLayout]}
                contentFit="cover"
                source={require("../assets/circle1.png")}
              />
              <Text style={styles.letter}>W</Text>
            </View>
            <View style={styles.content}>
              <View style={styles.details1}>
                <View>
                  <Text style={[styles.today1, styles.textTypo]}>Today</Text>
                  <Text style={[styles.text, styles.textTypo]}>
                    +$124236.30
                  </Text>
                </View>
                <View style={styles.total}>
                  <Text style={[styles.today1, styles.textTypo]}>
                    Total Wins
                  </Text>
                  <Text style={[styles.text, styles.textTypo]}>$102311.70</Text>
                </View>
              </View>
              <View style={styles.graph} />
            </View>
          </View>
        </Pressable>
      </ScrollView>
    </View>
  );
};

const styles = StyleSheet.create({
  scrollableScrollViewContent: {
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "flex-start",
  },
  iconLayout: {
    height: 20,
    zIndex: 0,
    width: 20,
  },
  textTypo: {
    fontFamily: FontFamily.interBold,
    fontWeight: "700",
    textAlign: "left",
    letterSpacing: 0,
  },
  leticonPosition: {
    right: 14,
    top: 14,
    position: "absolute",
  },
  statusbar: {
    height: "62.77%",
    top: "0%",
    bottom: "37.23%",
    alignItems: "flex-end",
    justifyContent: "center",
    backgroundColor: Color.colorGold,
    left: "0%",
    right: "0%",
    position: "absolute",
    width: "100%",
  },
  icon: {
    height: "100%",
    width: "100%",
  },
  hamburger: {
    height: 15,
    width: 20,
  },
  toptext: {
    textAlign: "left",
    color: Color.labelColorLightPrimary,
    fontFamily: FontFamily.interSemiBold,
    fontWeight: "600",
    lineHeight: 25,
    letterSpacing: 0,
    fontSize: FontSize.size_xl,
  },
  details: {
    paddingHorizontal: Padding.p_mini,
    paddingVertical: 0,
    flexDirection: "row",
    flex: 1,
  },
  topbarinner: {
    height: 35,
    justifyContent: "space-between",
    paddingLeft: Padding.p_8xs,
    paddingRight: Padding.p_xs,
    paddingBottom: Padding.p_3xs,
    flexDirection: "row",
    alignSelf: "stretch",
    alignItems: "center",
  },
  topbar: {
    height: "37.23%",
    top: "62.77%",
    bottom: "0%",
    overflow: "hidden",
    backgroundColor: Color.colorGold,
    left: "0%",
    right: "0%",
    position: "absolute",
    alignItems: "center",
    width: "100%",
  },
  top: {
    height: 94,
    alignSelf: "stretch",
  },
  pluscircleIcon: {
    zIndex: 0,
    right: 14,
    top: 14,
    position: "absolute",
  },
  today1: {
    fontSize: FontSize.size_base,
    color: Color.labelColorLightSecondary,
    lineHeight: 18,
  },
  text: {
    marginTop: 2,
    color: Color.labelColorLightPrimary,
    lineHeight: 25,
    fontSize: FontSize.size_xl,
    fontWeight: "700",
  },
  total: {
    marginLeft: 15,
  },
  details1: {
    flexDirection: "row",
  },
  graph: {
    backgroundColor: Color.colorGainsboro_200,
    height: 70,
    marginTop: 10,
    alignSelf: "stretch",
  },
  content: {
    marginLeft: 10,
    zIndex: 1,
    flex: 1,
  },
  dashWinsCard: {
    borderRadius: Border.br_smi,
    backgroundColor: Color.systemBackgroundLightPrimary,
    borderStyle: "solid",
    borderColor: Color.colorGainsboro_100,
    borderWidth: 1.3,
    padding: Padding.p_base,
    flexDirection: "row",
    flex: 1,
  },
  revenuecardswinsbets: {
    flexDirection: "row",
    alignSelf: "stretch",
    alignItems: "center",
  },
  revenuecardswinsbets1: {
    marginTop: 11,
    flexDirection: "row",
    alignSelf: "stretch",
    alignItems: "center",
  },
  circleIcon: {
    zIndex: 0,
  },
  letter: {
    top: 1,
    right: 0,
    fontSize: FontSize.size_sm,
    color: Color.colorGray_100,
    textAlign: "center",
    display: "flex",
    height: 17,
    lineHeight: 18,
    zIndex: 1,
    fontFamily: FontFamily.interSemiBold,
    fontWeight: "600",
    letterSpacing: 0,
    width: 20,
    justifyContent: "center",
    position: "absolute",
    alignItems: "center",
  },
  leticon: {
    zIndex: 0,
    flexDirection: "row",
    alignItems: "center",
  },
  scrollable: {
    padding: Padding.p_2xs,
    maxWidth: "100%",
    width: "100%",
    flex: 1,
  },
  revenue: {
    backgroundColor: Color.colorGhostwhite,
    alignItems: "center",
    width: "100%",
    flex: 1,
  },
});

export default Revenue;
