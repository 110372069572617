import { supabase } from './supabase'
import { useSession } from '../lib/SessionContext';
import getOrganizationDetails from '../lib/org'
import { useState, useEffect } from 'react';


export function useOrg() {
  const [currentOrganization, setOrganizationDetails] = useState(null);
  const { session } = useSession();

  useEffect(() => {
      const fetchOrganizationDetails = async () => {
          if (session?.user?.id) {
              try {
                  const details = await getOrganizationDetails(session.user.id);
                  if (details.length > 0) {
                      setOrganizationDetails(details[0]);
                  }
              } catch (error) {
                  console.error(error);
              }
          }
      };

      fetchOrganizationDetails();
  }, [session?.user?.id]);

  return currentOrganization;
}


export default async function org() {
    const { session } = useSession();
    const org = session?.user?.email;
    console.log("email ", org);
    return org;

}

export async function isAdmin(userEmail, orgid) {

  console.log("Received userEmail and orgid in isAdmin:", userEmail, orgid);

  if (!userEmail || !orgid) {
      console.error(`Invalid inputs in isAdmin: userEmail: ${userEmail}, orgid: ${orgid}`);
      return false;
  }

  try {
    const { data, error } = await supabase
      .from('orgs')
      .select('admins')
      .eq('orgid', orgid) 
      .single();

    if (error) {
      console.error('Error fetching organization:', error);
      throw error; 
    }

    if (data && Array.isArray(data.admins) && data.admins.includes(userEmail)) {
      return true;
    }
    
  } catch (error) {
    console.error('Error in isAdmin:', error);
  }
  
  return false;
}