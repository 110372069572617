import * as React from "react";
import { StyleSheet, View, Pressable, Text, ScrollView } from "react-native";
import { Image } from "expo-image";
import { DrawerNavigationProp } from "@react-navigation/drawer";
import { useNavigation, ParamListBase } from "@react-navigation/native";
import { StackNavigationProp } from "@react-navigation/stack";
import { Color, FontFamily, Padding, Border, FontSize } from "../GlobalStyles";

const Scan = () => {
  const navigation = useNavigation<DrawerNavigationProp<ParamListBase>>();

  return (
    <View style={styles.scan}>
      <View style={styles.top}>
        <View style={[styles.statusbar, styles.topbarPosition]} />
        <View style={[styles.topbar, styles.topbarPosition]}>
          <View style={styles.topbarinner}>
            <Pressable
              style={styles.hamburger}
              onPress={() => navigation.toggleDrawer()}
            >
              <Image
                style={styles.icon}
                contentFit="cover"
                source={require("../assets/hamburger.png")}
              />
            </Pressable>
            <View style={styles.details}>
              <Text style={[styles.toptext, styles.toptextFlexBox]}>Scan</Text>
            </View>
          </View>
        </View>
      </View>
      <ScrollView
        style={styles.frame}
        showsVerticalScrollIndicator={false}
        showsHorizontalScrollIndicator={false}
        contentContainerStyle={styles.frameScrollViewContent}
      >
        <View style={styles.locationbackcard}>
          <View style={styles.content}>
            <View style={styles.arrowleftParent}>
              <Pressable
                style={styles.arrowleft}
                onPress={() => navigation.goBack()}
              >
                <Image
                  style={styles.icon}
                  contentFit="cover"
                  source={require("../assets/arrowleft.png")}
                />
              </Pressable>
              <Image
                style={styles.eventimgIcon}
                contentFit="cover"
                source={require("../assets/eventimg.png")}
              />
              <View style={styles.details1}>
                <Text style={[styles.locationName, styles.locationNameTypo]}>
                  Location Name
                </Text>
                <View style={styles.yinzStBitsburghPa15001Wrapper}>
                  <Text
                    style={[styles.yinzStBitsburgh, styles.locationNameTypo]}
                  >
                    123 Yinz St, Bitsburgh, PA 15001
                  </Text>
                </View>
              </View>
            </View>
          </View>
        </View>
        <View style={[styles.errorcard, styles.errorcardBorder]}>
          <Text style={[styles.toptext, styles.toptextFlexBox]}>
            Error Title
          </Text>
          <Text style={[styles.errorBody, styles.textClr]}>Error Body</Text>
        </View>
        <View style={[styles.devicecard, styles.errorcardBorder]}>
          <Image
            style={styles.deviceimgIcon}
            contentFit="cover"
            source={require("../assets/locationimg2.png")}
          />
          <View style={styles.content1}>
            <Text style={[styles.devicetitle, styles.letterFlexBox]}>
              Device Title
            </Text>
            <View style={styles.details2}>
              <View style={styles.details3}>
                <Text style={[styles.text, styles.textClr]}>{`Location `}</Text>
                <View style={styles.moneyinframe}>
                  <Image
                    style={styles.pluscircleIcon}
                    contentFit="cover"
                    source={require("../assets/pluscircle2.png")}
                  />
                  <Text
                    style={[styles.moneyinval, styles.textClr]}
                  >{`$1098.00 `}</Text>
                </View>
                <View style={styles.moneyinframe}>
                  <Image
                    style={styles.pluscircleIcon}
                    contentFit="cover"
                    source={require("../assets/minuscircle2.png")}
                  />
                  <Text style={[styles.moneyinval, styles.textClr]}>
                    $1098.50
                  </Text>
                </View>
              </View>
              <View style={styles.details4}>
                <View style={styles.details3}>
                  <View style={styles.leticon}>
                    <Image
                      style={styles.circleIcon}
                      contentFit="cover"
                      source={require("../assets/circle.png")}
                    />
                    <Text style={[styles.letter, styles.letterFlexBox]}>B</Text>
                  </View>
                  <Text
                    style={[styles.moneyinval, styles.textClr]}
                  >{`$1098.00 `}</Text>
                </View>
                <View style={styles.moneyoutframe1}>
                  <View style={styles.leticon}>
                    <Image
                      style={styles.circleIcon}
                      contentFit="cover"
                      source={require("../assets/circle.png")}
                    />
                    <Text style={[styles.letter, styles.letterFlexBox]}>W</Text>
                  </View>
                  <Text style={[styles.moneyinval, styles.textClr]}>
                    $1098.50
                  </Text>
                </View>
              </View>
            </View>
          </View>
        </View>
      </ScrollView>
    </View>
  );
};

const styles = StyleSheet.create({
  frameScrollViewContent: {
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "flex-start",
  },
  topbarPosition: {
    backgroundColor: Color.colorGold,
    left: "0%",
    right: "0%",
    position: "absolute",
    width: "100%",
  },
  toptextFlexBox: {
    textAlign: "left",
    color: Color.labelColorLightPrimary,
  },
  locationNameTypo: {
    textAlign: "center",
    fontFamily: FontFamily.interBold,
    fontWeight: "700",
    letterSpacing: 0,
  },
  errorcardBorder: {
    marginTop: 11,
    padding: Padding.p_base,
    borderWidth: 1.3,
    borderColor: Color.colorGainsboro_100,
    borderStyle: "solid",
    backgroundColor: Color.systemBackgroundLightPrimary,
    borderRadius: Border.br_smi,
    alignSelf: "stretch",
  },
  textClr: {
    color: Color.labelColorLightSecondary,
    lineHeight: 18,
    textAlign: "left",
    letterSpacing: 0,
  },
  letterFlexBox: {
    display: "flex",
    letterSpacing: 0,
    alignItems: "center",
  },
  statusbar: {
    height: "62.77%",
    top: "0%",
    bottom: "37.23%",
    alignItems: "flex-end",
    justifyContent: "center",
  },
  icon: {
    height: "100%",
    width: "100%",
  },
  hamburger: {
    height: 15,
    width: 20,
  },
  toptext: {
    fontSize: FontSize.size_xl,
    lineHeight: 25,
    fontFamily: FontFamily.interSemiBold,
    fontWeight: "600",
    letterSpacing: 0,
    textAlign: "left",
  },
  details: {
    paddingHorizontal: Padding.p_mini,
    paddingVertical: 0,
    flexDirection: "row",
    flex: 1,
  },
  topbarinner: {
    height: 35,
    justifyContent: "space-between",
    paddingLeft: Padding.p_8xs,
    paddingRight: Padding.p_xs,
    paddingBottom: Padding.p_3xs,
    flexDirection: "row",
    alignItems: "center",
    alignSelf: "stretch",
  },
  topbar: {
    height: "37.23%",
    top: "62.77%",
    bottom: "0%",
    alignItems: "center",
    overflow: "hidden",
  },
  top: {
    height: 94,
    alignSelf: "stretch",
  },
  arrowleft: {
    height: 16,
    width: 20,
  },
  eventimgIcon: {
    width: 30,
    height: 30,
    marginLeft: 8,
    borderRadius: Border.br_12xs,
  },
  locationName: {
    lineHeight: 13,
    fontSize: FontSize.size_smi,
    color: Color.labelColorLightPrimary,
    textAlign: "center",
  },
  yinzStBitsburgh: {
    fontSize: FontSize.size_xs,
    lineHeight: 15,
    color: Color.colorGray_300,
  },
  yinzStBitsburghPa15001Wrapper: {
    width: 260,
    marginTop: 2,
    flexDirection: "row",
  },
  details1: {
    marginLeft: 8,
  },
  arrowleftParent: {
    width: 314,
    flexDirection: "row",
    alignItems: "center",
  },
  content: {
    flexDirection: "row",
    alignItems: "center",
    flex: 1,
  },
  locationbackcard: {
    padding: Padding.p_base,
    borderWidth: 1.3,
    borderColor: Color.colorGainsboro_100,
    borderStyle: "solid",
    backgroundColor: Color.systemBackgroundLightPrimary,
    borderRadius: Border.br_smi,
    flexDirection: "row",
    overflow: "hidden",
    alignSelf: "stretch",
  },
  errorBody: {
    fontSize: FontSize.size_base,
    fontFamily: FontFamily.interRegular,
    marginTop: 20,
  },
  errorcard: {
    alignItems: "center",
    justifyContent: "center",
  },
  deviceimgIcon: {
    width: 60,
    height: 60,
    borderRadius: Border.br_12xs,
  },
  devicetitle: {
    fontSize: FontSize.size_mini,
    lineHeight: 20,
    width: 262,
    fontFamily: FontFamily.interBold,
    fontWeight: "700",
    textAlign: "left",
    color: Color.labelColorLightPrimary,
  },
  text: {
    display: "none",
    width: 92,
    fontFamily: FontFamily.interBold,
    fontWeight: "700",
    fontSize: FontSize.size_smi,
    alignItems: "center",
  },
  pluscircleIcon: {
    width: 15,
    height: 15,
  },
  moneyinval: {
    marginLeft: 1,
    fontFamily: FontFamily.interBold,
    fontWeight: "700",
    fontSize: FontSize.size_smi,
  },
  moneyinframe: {
    marginLeft: 10,
    flexDirection: "row",
    alignItems: "center",
  },
  details3: {
    flexDirection: "row",
    alignItems: "center",
  },
  circleIcon: {
    zIndex: 0,
    width: 15,
    height: 15,
  },
  letter: {
    top: 2,
    left: 0,
    fontSize: FontSize.size_2xs,
    color: Color.colorGray_100,
    height: 12,
    zIndex: 1,
    width: 15,
    lineHeight: 18,
    display: "flex",
    textAlign: "center",
    fontFamily: FontFamily.interSemiBold,
    fontWeight: "600",
    justifyContent: "center",
    position: "absolute",
  },
  leticon: {
    flexDirection: "row",
    alignItems: "center",
  },
  moneyoutframe1: {
    marginLeft: 20,
    flexDirection: "row",
    alignItems: "center",
  },
  details4: {
    marginTop: 1,
    flexDirection: "row",
    alignItems: "center",
    alignSelf: "stretch",
  },
  details2: {
    width: 288,
  },
  content1: {
    marginLeft: 10,
    width: 288,
  },
  devicecard: {
    flexDirection: "row",
    overflow: "hidden",
  },
  frame: {
    padding: Padding.p_2xs,
    alignSelf: "stretch",
    flex: 1,
  },
  scan: {
    backgroundColor: Color.colorGhostwhite,
    width: "100%",
    flex: 1,
  },
});

export default Scan;
