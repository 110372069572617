import 'react-native-url-polyfill/auto'
import AsyncStorage from '@react-native-async-storage/async-storage'
import { createClient } from '@supabase/supabase-js'

const supabaseUrl = "https://zyrmgldgnrwlrtxtxcsu.supabase.co"
const supabaseAnonKey = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Inp5cm1nbGRnbnJ3bHJ0eHR4Y3N1Iiwicm9sZSI6ImFub24iLCJpYXQiOjE3MjEwMDE2NTksImV4cCI6MjAzNjU3NzY1OX0.3P45p_x4y-O9wyFMJoplx2mve79ZmGirCZYbeeP64wo"

export const supabase = createClient(supabaseUrl, supabaseAnonKey, {
  auth: {
    storage: AsyncStorage,
    autoRefreshToken: true,
    persistSession: true,
    detectSessionInUrl: false,
  },
})