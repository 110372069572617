import * as React from "react";
import { StyleSheet, View, Text, Pressable, TextInput, Alert } from "react-native";
import { Image } from "expo-image";
import { Padding, Color, Border, FontSize, FontFamily } from "../GlobalStyles";
import { supabase } from "../lib/supabase";
import { useEffect, useState } from "react";
import { Session } from '@supabase/supabase-js';
import { useNavigation, ParamListBase } from "@react-navigation/native";
import { StackNavigationProp } from '@react-navigation/stack';


const NewOrg = () => {

  const navigation = useNavigation<StackNavigationProp<ParamListBase>>();

  const [session, setSession] = useState<Session | null>(null);

  const [formData, setFormData] = useState({
    orgname: '',
    admins: [],
    members: [],
  });

  useEffect(() => {
    supabase.auth.getSession().then(({ data: { session } }) => {
      setSession(session)
    })

    supabase.auth.onAuthStateChange((_event, session) => {
      setSession(session)
    })
  }, []);

  //console.log("e ", session?.user?.email);

  

  useEffect(() => {
    if (session?.user?.email) {
      setFormData(prevFormData => ({
        ...prevFormData,
        admins: [session.user.email],
        members: [session.user.email],
      }));
    }
  }, [session?.user?.email]);

  const handleInputChange = (name, value) => {
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async () => {
    try {
      const { error } = await supabase
        .from('orgs')
        .insert([ 
        { ...formData }
      ]);
  
      if (error) throw error;
      Alert.alert(`${formData?.name} Created`, `Organization created succesfully.\nPlease login again.`, [{text: 'OK'},]);
      supabase.auth.signOut()

      // NEED TO FIX

      //navigation.navigate("DrawerRoot", { screen: "OrgSettings" });
    } catch (error) {
      Alert.alert('Error', `${error}`, [{text: 'OK'},]);
    }
  };


  return (
    <View style={styles.newOrg}>
      <View style={styles.statusbar} />
      <View style={[styles.frame, styles.frameFlexBox]}>
        <View style={styles.dashOrgWelcomeCard}>
          <View style={styles.contentFlexBox}>
            <View>
              <Text style={styles.user}>User</Text>
              <Text style={styles.emaildomaincom}>{session?.user?.email}</Text>
            </View>
            <Pressable style={styles.arrowleftParent} onPress={() => supabase.auth.signOut()}>
              <Image
                style={styles.arrowleftIcon}
                contentFit="cover"
                source={require("../assets/arrowleft1.png")}
              />
              <Text style={styles.logOut}>Log Out</Text>
            </Pressable>
          </View>
        </View>
      </View>
      <View style={styles.paymentMethods}>
        <Text style={styles.newOrganization}>New Organization</Text>
        <View style={[styles.stripePayment, styles.payButtonBorder]}>
            <>
        {/*
          <Text style={[styles.slugUrl, styles.nameTypo]}>Slug (url)</Text>
          <TextInput
            style={[styles.customername, styles.nameSpaceBlock]}
            placeholder="  Slug (url)"
            placeholderTextColor="rgba(60, 60, 67, 0.3)"
            value={formData.OrgSlug}
            onChangeText={(value) => handleInputChange('OrgSlug', value)}
          />
          */}
          <Text style={[styles.name, styles.nameSpaceBlock]}>Name</Text>
          <TextInput
            style={[styles.customername, styles.nameSpaceBlock]}
            placeholder="  Name"
            placeholderTextColor="rgba(60, 60, 67, 0.3)"
            value={formData.name}
            onChangeText={(value) => handleInputChange('orgname', value)}
          />
          {/*
          <Text style={[styles.name, styles.nameSpaceBlock]}>Description</Text>
          <TextInput
            style={[styles.customername, styles.nameSpaceBlock]}
            placeholder="  Description"
            placeholderTextColor="rgba(60, 60, 67, 0.3)"
            value={formData.desc}
            onChangeText={(value) => handleInputChange('desc', value)}
          />
          */}
         </>
        </View>
        <Pressable style={[styles.payButton, styles.payButtonBorder]} onPress={handleSubmit}>
          <Text style={[styles.createOrganization, styles.dashTypo]}>
            Create Organization
          </Text>
        </Pressable>
      </View>
      
    </View>
  );
};

const styles = StyleSheet.create({
  frameFlexBox: {
    overflow: "hidden",
    alignItems: "center",
  },
  payButtonBorder: {
    marginTop: 11,
    padding: Padding.p_base,
    borderWidth: 1.3,
    borderColor: Color.colorGainsboro,
    borderStyle: "solid",
    borderRadius: Border.br_smi,
    alignSelf: "stretch",
  },
  nameTypo: {
    lineHeight: 20,
    color: Color.colorGray_200,
    fontSize: FontSize.size_xl,
    fontFamily: FontFamily.interSemiBold,
    fontWeight: "600",
    textAlign: "left",
    letterSpacing: 0,
  },
  nameSpaceBlock: {
    marginTop: 10,
    alignSelf: "stretch",
  },
  dashTypo: {
    fontFamily: FontFamily.interMedium,
    fontWeight: "500",
    textAlign: "center",
  },
  contentFlexBox: {
    justifyContent: "space-between",
    flexDirection: "row",
    alignItems: "center",
    flex: 1,
  },
  iconLayout: {
    height: 20,
    width: 20,
  },
  statusbar: {
    width: 393,
    height: 59,
    alignItems: "flex-end",
    justifyContent: "center",
    backgroundColor: Color.colorGhostwhite,
  },
  user: {
    fontSize: FontSize.size_base,
    lineHeight: 18,
    color: Color.labelColorLightSecondary,
    textAlign: "left",
    fontFamily: FontFamily.interRegular,
    letterSpacing: 0,
  },
  emaildomaincom: {
    color: Color.labelColorLightPrimary,
    marginTop: 2,
    fontFamily: FontFamily.interSemiBold,
    fontWeight: "600",
    lineHeight: 25,
    fontSize: FontSize.size_mid,
    textAlign: "left",
    letterSpacing: 0,
  },
  arrowleftIcon: {
    height: 16,
    width: 20,
  },
  logOut: {
    fontSize: FontSize.size_6xs,
    lineHeight: 10,
    color: Color.colorGray_100,
    display: "flex",
    height: 13,
    textAlign: "center",
    width: 51,
    fontFamily: FontFamily.interRegular,
    letterSpacing: 0,
    alignItems: "center",
    justifyContent: "center",
  },
  arrowleftParent: {
    height: 45,
    justifyContent: "flex-end",
    width: 51,
    alignItems: "center",
  },
  dashOrgWelcomeCard: {
    padding: Padding.p_base,
    borderWidth: 1.3,
    borderColor: Color.colorGainsboro,
    borderStyle: "solid",
    borderRadius: Border.br_smi,
    flexDirection: "row",
    backgroundColor: Color.labelColorDarkPrimary,
    overflow: "hidden",
    alignSelf: "stretch",
  },
  frame: {
    paddingHorizontal: Padding.p_2xs,
    paddingVertical: 0,
    alignItems: "center",
    alignSelf: "stretch",
  },
  newOrganization: {
    color: Color.colorGray_200,
    fontSize: FontSize.size_xl,
    fontFamily: FontFamily.interSemiBold,
    fontWeight: "600",
    lineHeight: 25,
    textAlign: "left",
    letterSpacing: 0,
  },
  slugUrl: {
    alignSelf: "stretch",
  },
  customername: {
    borderRadius: Border.br_3xs,
    backgroundColor: Color.fillColorLightTertiary,
    paddingHorizontal: Padding.p_5xs,
    paddingVertical: Padding.p_6xs,
    fontSize: FontSize.size_mid,
    marginTop: 10,
    fontFamily: FontFamily.interRegular,
    flexDirection: "row",
    alignItems: "center",
  },
  name: {
    lineHeight: 20,
    color: Color.colorGray_200,
    fontSize: FontSize.size_xl,
    fontFamily: FontFamily.interSemiBold,
    fontWeight: "600",
    textAlign: "left",
    letterSpacing: 0,
  },
  stripePayment: {
    backgroundColor: Color.labelColorDarkPrimary,
  },
  createOrganization: {
    lineHeight: 22,
    color: Color.labelColorDarkPrimary,
    fontSize: FontSize.size_xl,
    fontWeight: "500",
    letterSpacing: 0,
    height:22
  },
  payButton: {
    backgroundColor: Color.colorDeepskyblue_100,
    height: 43,
    flexDirection: "row",
    alignItems: "center",
    overflow: "hidden",
    justifyContent: "center",
  },
  paymentMethods: {
    padding: Padding.p_2xs,
    alignItems: "center",
    alignSelf: "stretch",
  },
  dash: {
    fontSize: FontSize.size_smi,
    color: Color.colorLightslategray,
    marginTop: 14,
  },
  bottomTab: {
    width: 61,
    alignItems: "center",
  },
  eventIcon: {
    opacity: 0.8,
  },
  bottomTabs: {
    paddingHorizontal: Padding.p_base,
    paddingTop: Padding.p_base,
    paddingBottom: Padding.p_16xl,
    backgroundColor: Color.labelColorDarkPrimary,
    alignSelf: "stretch",
  },
  tabbar: {
    justifyContent: "flex-end",
    flexDirection: "row",
    alignSelf: "stretch",
  },
  newOrg: {
    width: "100%",
    flex: 1,
    backgroundColor: Color.colorGhostwhite,
  },
});

export default NewOrg;
