import * as React from "react";
import { StyleSheet, View, Pressable, Text, ScrollView } from "react-native";
import { Image } from "expo-image";
import { DrawerNavigationProp } from "@react-navigation/drawer";
import { useNavigation, ParamListBase } from "@react-navigation/native";
import { StackNavigationProp } from "@react-navigation/stack";
import { Color, Padding, Border, FontFamily, FontSize } from "../GlobalStyles";

const AddWifiConfirmation = () => {
  const navigation = useNavigation<DrawerNavigationProp<ParamListBase>>();

  return (
    <View style={styles.addWificonfirmation}>
      <View style={styles.top}>
        <View style={[styles.statusbar, styles.topbarPosition]} />
        <View style={[styles.topbar, styles.topbarFlexBox]}>
          <View style={styles.topbarinner}>
          <Pressable
            style={{
              width: 60, // Set a specific width for the button
              height: 60, // Set a specific height for the button
              justifyContent: 'center',
              alignItems: 'center',
              padding: 10, // Optional: additional padding
              backgroundColor: 'transparent', // Ensure background is transparent
            }}
            onPress={() => navigation.goBack()}
          >
            <Image
              style={{
                width: '60%', // Make the image fill the button
                height: '60%', // Make the image fill the button
                resizeMode: 'contain', // Ensure the image maintains its aspect ratio
              }}
              source={require("../assets/calender.png")}
            />
          </Pressable>
            <View style={styles.details}>
              <Text style={styles.toptext}>Device Name</Text>
            </View>
          </View>
        </View>
      </View>
      <ScrollView
        style={styles.frame}
        showsVerticalScrollIndicator={false}
        showsHorizontalScrollIndicator={false}
        contentContainerStyle={styles.frameScrollViewContent}
      >
        <View style={[styles.devicedetailscard, styles.devicebuttonBorder]}>
          <Text style={styles.toptext}>Device Name is setup</Text>
          <View style={styles.detailitemgroup}>
            <Text style={styles.title}>Title</Text>
            <Text style={[styles.bodyText, styles.detailTypo]}>Body Text</Text>
          </View>
          <Text style={[styles.detail, styles.detailTypo]}>Detail</Text>
        </View>
        <Pressable
          style={[styles.devicebutton, styles.devicebuttonBorder]}
          onPress={() => navigation.navigate("DeviceDetail")}
        >
          <Text style={styles.device}>Device</Text>
        </Pressable>
      </ScrollView>
    </View>
  );
};

const styles = StyleSheet.create({
  frameScrollViewContent: {
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "flex-start",
  },
  topbarPosition: {
    backgroundColor: Color.colorGold,
    left: "0%",
    right: "0%",
    position: "absolute",
    width: "100%",
  },
  topbarFlexBox: {
    overflow: "hidden",
    alignItems: "center",
  },
  devicebuttonBorder: {
    padding: Padding.p_base,
    borderWidth: 1.3,
    borderColor: Color.colorGainsboro_100,
    borderStyle: "solid",
    borderRadius: Border.br_smi,
    justifyContent: "center",
    alignSelf: "stretch",
  },
  detailTypo: {
    fontFamily: FontFamily.interRegular,
    color: Color.labelColorLightSecondary,
    lineHeight: 18,
    fontSize: FontSize.size_base,
    textAlign: "left",
    letterSpacing: 0,
  },
  statusbar: {
    height: "62.77%",
    top: "0%",
    bottom: "37.23%",
    alignItems: "flex-end",
    justifyContent: "center",
  },
  icon: {
    height: "100%",
    width: "100%",
  },
  hamburger: {
    width: 20,
    height: 15,
  },
  toptext: {
    lineHeight: 25,
    fontWeight: "600",
    fontFamily: FontFamily.interSemiBold,
    color: Color.labelColorLightPrimary,
    textAlign: "left",
    letterSpacing: 0,
    fontSize: FontSize.size_xl,
  },
  details: {
    paddingHorizontal: Padding.p_mini,
    paddingVertical: 0,
    flexDirection: "row",
    flex: 1,
  },
  topbarinner: {
    height: 35,
    justifyContent: "space-between",
    paddingLeft: Padding.p_8xs,
    paddingRight: Padding.p_xs,
    paddingBottom: Padding.p_3xs,
    flexDirection: "row",
    alignItems: "center",
    alignSelf: "stretch",
  },
  topbar: {
    height: "37.23%",
    top: "62.77%",
    bottom: "0%",
    alignItems: "center",
    backgroundColor: Color.colorGold,
    left: "0%",
    right: "0%",
    position: "absolute",
    width: "100%",
  },
  top: {
    height: 94,
    alignSelf: "stretch",
  },
  title: {
    fontWeight: "700",
    fontFamily: FontFamily.interBold,
    color: Color.labelColorLightSecondary,
    lineHeight: 18,
    fontSize: FontSize.size_base,
    textAlign: "left",
    letterSpacing: 0,
  },
  bodyText: {
    marginTop: 5,
  },
  detailitemgroup: {
    marginTop: 20,
    justifyContent: "center",
  },
  detail: {
    marginTop: 20,
  },
  devicedetailscard: {
    backgroundColor: Color.systemBackgroundLightPrimary,
  },
  device: {
    lineHeight: 22,
    fontWeight: "500",
    fontFamily: FontFamily.interMedium,
    color: Color.systemBackgroundLightPrimary,
    textAlign: "center",
    letterSpacing: 0,
    fontSize: FontSize.size_xl,
  },
  devicebutton: {
    backgroundColor: Color.colorDeepskyblue,
    height: 43,
    marginTop: 11,
    flexDirection: "row",
    alignItems: "center",
    overflow: "hidden",
  },
  frame: {
    padding: Padding.p_2xs,
    alignSelf: "stretch",
    flex: 1,
  },
  addWificonfirmation: {
    backgroundColor: Color.colorGhostwhite,
    width: "100%",
    flex: 1,
  },
});

export default AddWifiConfirmation;
