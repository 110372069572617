import * as React from "react";
import { useState, useEffect } from "react";
import { StyleSheet, View, Text, ScrollView, Pressable, RefreshControl, ActivityIndicator } from "react-native";
import { Image } from "expo-image";
import { DrawerNavigationProp } from "@react-navigation/drawer";
import { useNavigation, ParamListBase } from "@react-navigation/native";
import { Color, Padding, Border, FontSize, FontFamily } from "../GlobalStyles";
import { useOrg } from '../lib/useOrg'
import { getCombinedTotalsByOrg, getLocationsByOrgId, getDevicesByOrgId } from "../lib/dash";
import { useSession } from "../lib/SessionContext";
import { supabase } from '../lib/supabase';
import TopBar from '../components/TopBar';
import org from "../lib/perm";
import { LineChart } from 'react-native-chart-kit';
import { Dimensions } from "react-native";

interface Location {
  total_billing_amount: number;
  total_ticketout_amount: number;
  device_count: number;
  locationid: string;
  locationname: string;
  locationcity: string;
  locationstate: string;
  locationimg: string;
  devices: Device[];
}

interface Device {
  locationname: string;
  total_billing_amount: number;
  total_ticketout_amount: number;
  deviceid: string;
  devicename: string;
  locationid: string;
  deviceimg: string;
}

interface Totals {
  billin24Hour: number;
  billinMonthly: number;
  ticketout24Hour: number;
  ticketoutMonthly: number;
}

interface FetchError {
  message: string;
}

const formatNumber = (num: number) => {
  return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

const Dash = () => {
  
  const navigation = useNavigation<DrawerNavigationProp<ParamListBase>>();
  const { currentOrganization } = useOrg();
  const { session } = useSession();
  const [totals, setTotals] = useState<Totals>({ billin24Hour: 0, billinMonthly: 0, ticketout24Hour: 0, ticketoutMonthly: 0 });
  const [locations, setLocations] = useState<Location[]>([]);
  const [devices, setDevices] = useState<Device[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<FetchError | null>(null);
  const [refreshing, setRefreshing] = useState(false);
  const [orgTotals, setOrgTotals] = useState([]);
  const [loadingTotals, setLoadingTotals] = useState(true); 
  const [errorTotals, setErrorTotals] = useState<FetchError | null>(null); 
  const [chartData, setChartData] = useState<{ labels: string[], datasets: { data: number[], color: (opacity?: number) => string }[] }>({
    labels: [],
    datasets: [
        { data: [], color: (opacity = 1) => `rgba(0, 255, 0, ${opacity})` }, // Green for billin
        { data: [], color: (opacity = 1) => `rgba(255, 0, 0, ${opacity})` }  // Red for ticketout
    ]
  });

  const sanitizeData = (data: number[]) => {
    return data.map(num => (Number.isFinite(num) && num >= 0) ? Math.floor(num) : 0);
  };

  const fetchData = async () => {
    if (!currentOrganization?.orgid) {
      setLoading(false);
      return;
    }
  
    setLoading(true);
    setError(null); 
    try {
      const [fetchedTotals, fetchedLocations, fetchedDevices] = await Promise.all([
        getCombinedTotalsByOrg(currentOrganization.orgid),
        getLocationsByOrgId(currentOrganization.orgid, 5),
        getDevicesByOrgId(currentOrganization.orgid, 10),
      ]);
  
      const { data: deviceTotals, error: deviceTotalsError } = await supabase
          .rpc('get_org_device_totals', { org_id: currentOrganization.orgid });
      if (deviceTotalsError) throw deviceTotalsError;
  
      console.log("Fetched device totals:", deviceTotals);

      // Sanitize and process data for chart
      const labels = deviceTotals.map(item => new Date(item.day).getDate().toString());
      const sanitizedBillin = sanitizeData(deviceTotals.map(item => item.billin_total));
      const sanitizedTicketout = sanitizeData(deviceTotals.map(item => item.ticketout_total));

      console.log("Sanitized Billin Data:", sanitizedBillin);
      console.log("Sanitized Ticketout Data:", sanitizedTicketout);

      // Set chart data using sanitized values
      setChartData({ 
        labels,
        datasets: [
            { data: sanitizedBillin, color: (opacity = 1) => `rgba(0, 255, 0, ${opacity})` }, // Green for billin
            { data: sanitizedTicketout, color: (opacity = 1) => `rgba(255, 0, 0, ${opacity})` }  // Red for ticketout
        ]
      });

      if (fetchedTotals) setTotals(fetchedTotals);
      setLocations(fetchedLocations);
      setDevices(fetchedDevices);
    } catch (e: any) {
      setError({ message: e.message });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [currentOrganization?.orgid]);

  // Fetch organization totals
  useEffect(() => {
    const fetchOrgTotals = async () => {
      if (!currentOrganization?.orgid) return;
      setLoadingTotals(true);
      setErrorTotals(null);
      try {
        const { data, error } = await supabase
          .rpc('get_totals_by_org', { p_org_id: currentOrganization.orgid });
        if (error) throw error;
        setOrgTotals(data);
      } catch (err: any) {
        setErrorTotals({ message: err.message });
      } finally {
        setLoadingTotals(false);
      }
    };

    fetchOrgTotals();
  }, [currentOrganization?.orgid]);

  console.log("orgt", orgTotals);

  const onRefresh = React.useCallback(() => {
    setRefreshing(true);
    fetchData().then(() => setRefreshing(false));
  }, [fetchData]);

  return (
    <View style={[styles.dash, styles.dashLayout]}>
      <TopBar>
      <Pressable 
        style={{ padding: 20, paddingLeft: 0 }} 
        onPress={() => navigation.toggleDrawer()}
      >
        <Image
          style={styles.hamburgerIcon}
          contentFit="cover"
          source={require("../assets/hamburger.png")}
        />
      </Pressable>
      <View style={styles.details}>
        <Text style={styles.organizationName}>{currentOrganization?.orgname}</Text>
      </View>
    </TopBar>
      <ScrollView
        style={[styles.dashscrollable, styles.dashLayout]}
        showsVerticalScrollIndicator={false}
        showsHorizontalScrollIndicator={false}
        contentContainerStyle={styles.dashScrollableScrollViewContent}
      >
        <RefreshControl
          refreshing={refreshing}
          onRefresh={onRefresh}
        />

        {/* Line Chart */}
        <LineChart
          data={{
            labels: chartData.labels,
            datasets: chartData.datasets.length > 0 ? chartData.datasets : [{ data: [], color: (opacity = 1) => `rgba(0, 0, 0, ${opacity})` }]
          }}
          width={Dimensions.get("window").width - 16}
          height={220}
          chartConfig={{
            backgroundColor: "#ffffff",
            backgroundGradientFrom: "#ffffff",
            backgroundGradientTo: "#ffffff",
            decimalPlaces: 0,
            color: (opacity = 1) => `rgba(0, 0, 0, ${opacity})`,
            labelColor: (opacity = 1) => `rgba(0, 0, 0, ${opacity})`,
            propsForDots: {
              r: '6',
              strokeWidth: '2',
              stroke: '#ffa726',
            },
          }}
          style={{
            marginVertical: 8,
            borderRadius: 16,
          }}
        />

        <View style={[styles.revenuecards, styles.locationsFlexBox]}>


        { /*      Bill in      */ }

          <Pressable
            style={[styles.dashRevenueCard, styles.dashCardBorder]}
            onPress={() => navigation.navigate("RevenueDetail")}
          >
            <View style={[styles.content, styles.contentFlexBox]}>
              <View>
                <Text style={[styles.today, styles.todayClr]}>Bill-inx 24h</Text>
                {loading ? (
                  <Text style={styles.text}>Loading...</Text>
                ) /*: error ? (
                  <Text style={styles.text}>Error: {error.message}</Text>
                ) */: (
                  <>
                    <Text style={[styles.text, styles.textTypo]}>
                      +${formatNumber(orgTotals.find(total => total.source === 'billin' && total.time_period === '24h')?.total_amount ?? 0)}
                    </Text>
                    <Text style={[styles.totalRevenue, styles.textTypo]}>
                      Weekly
                    </Text>
                    <Text style={[styles.text, styles.textTypo]}>
                      +${formatNumber(orgTotals.find(total => total.source === 'billin' && total.time_period === '7d')?.total_amount ?? 0)}
                    </Text>
                    <Text style={[styles.totalRevenue, styles.textTypo]}>
                      Monthly
                    </Text>
                    <Text style={[styles.text, styles.textTypo]}>
                      +${formatNumber(orgTotals.find(total => total.source === 'billin' && total.time_period === '1m')?.total_amount ?? 0)}
                    </Text>
                  </>
                )}
              </View>
              <Image
                style={styles.pluscircleIcon}
                contentFit="cover"
                source={require("../assets/pluscircle.png")}
              />
            </View>
          </Pressable>


          { /*      Ticket out      */ }

          <Pressable
            style={[styles.dashPayoutCard, styles.dashCardBorder]}
            onPress={() => navigation.navigate("RevenueDetail")}
          >
            <View style={[styles.content, styles.contentFlexBox]}>
              <View>
                <Text style={[styles.today, styles.todayClr]}>Ticket-out 24h</Text>
                {loading ? (
                  <Text style={styles.text}>Loading...</Text>
                ) /*: error ? (
                  <Text style={styles.text}>Error: {error.message}</Text>
                ) */: (
                  <>
                    <Text style={[styles.text, styles.textTypo]}>
                      +${formatNumber(orgTotals.find(total => total.source === 'ticketout' && total.time_period === '24h')?.total_amount ?? 0)}
                    </Text>
                    <Text style={[styles.totalRevenue, styles.textTypo]}>
                      Weekly
                    </Text>
                    <Text style={[styles.text, styles.textTypo]}>
                    +${formatNumber(orgTotals.find(total => total.source === 'ticketout' && total.time_period === '7d')?.total_amount ?? 0)}
                    </Text>
                    <Text style={[styles.totalRevenue, styles.textTypo]}>
                      Monthly
                    </Text>
                    <Text style={[styles.text, styles.textTypo]}>
                      +${formatNumber(orgTotals.find(total => total.source === 'ticketout' && total.time_period === '1m')?.total_amount ?? 0)}
                    </Text>
                  </>
                )}
              </View>
              <Image
                style={styles.pluscircleIcon}
                contentFit="cover"
                source={require("../assets/minuscircle.png")}
              />
            </View>
          </Pressable>
        </View>
          


        { /*    Locations       */  }

        {locations.map((location) => (
        <Pressable
          key={location.locationid}
          style={styles.locationsFlexBox}
          onPress={() => navigation.navigate("LocationDetail", { locationid: location.locationid })}
        >
          <Pressable
            style={[styles.locationcard, styles.dashCardBorder]}
            onPress={() => navigation.navigate("LocationDetail", { locationid: location.locationid })}
          >
            <Image
              style={styles.locationimgIcon}
              contentFit="cover"
              source={{ uri: location.locationimg }}
            />
            <View
              style={[
                styles.locationcardcontent,
                styles.devicecardcontentLayout,
              ]}
            >
              <Text style={[styles.locationName, styles.locationNameLayout]}>
                {location.locationname}
              </Text>
              <View style={styles.details3}>
                <View style={styles.nodevicesframe}>
                  <Image
                    style={styles.lanyardcardIcon}
                    contentFit="cover"
                    source={require("../assets/lanyardcard.png")}
                  />
                  <Text style={[styles.nodevicesval, styles.text4Typo]}>
                    {location.device_count}
                  </Text>
                </View>
                <View style={styles.moneyinframe}>
                  <Image
                    style={styles.pluscircleIcon1}
                    contentFit="cover"
                    source={require("../assets/pluscircle1.png")}
                  />
                  <Text
                    style={[styles.nodevicesval, styles.text4Typo]}
                  >{`$${location.total_billin_amount.toFixed(0)} `}</Text>
                </View>
                <View style={styles.moneyinframe}>
                  <Image
                    style={styles.pluscircleIcon1}
                    contentFit="cover"
                    source={require("../assets/minuscircle1.png")}
                  />
                  <Text style={[styles.nodevicesval, styles.text4Typo]}>
                    ${location.total_ticketout_amount.toFixed(0)}  /month
                  </Text>
                </View>
              </View>
            </View>
          </Pressable>
        </Pressable>
        ))}
      



        <Pressable
          style={[styles.alllocationsbtn, styles.dashCardBorder]}
          onPress={() =>
            navigation.navigate("DrawerRoot", { screen: "Locations" })
          }
        >
          <Text style={[styles.alllocationstxt, styles.locationNameLayout]}>
            All Locations
          </Text>
        </Pressable>


          {     /*  Devices   */    }

        {devices.map((device) => (
        <Pressable
          key={device.deviceid}
          style={styles.locationsFlexBox}
          onPress={() => navigation.navigate("DeviceDetail", { deviceid: device.deviceid })}
        >
          <Pressable
            style={[styles.locationcard, styles.dashCardBorder]}
            onPress={() => navigation.navigate("DeviceDetail", { deviceid: device.deviceid })}
          >
            <Image
              style={styles.locationimgIcon}
              contentFit="cover"
              source={require("../assets/deviceimg.png")}
            />
            <View
              style={[styles.devicecardcontent, styles.devicecardcontentLayout]}
            >
              <Text 
                style={[styles.locationName, styles.locationNameLayout]} 
                numberOfLines={1} // 1 line max
              >
                {device.devicename}
              </Text>
              <View style={styles.details3}>
                <View style={styles.moneyinframe2}>
                  <Text style={[styles.text4Typo, { lineHeight: styles.text4Typo.lineHeight - 1, fontSize: styles.text4Typo.fontSize - 1 }]}>
                    {device.locationname}
                  </Text>
                </View>
                <View style={styles.moneyinframe}>
                  <Image
                    style={styles.pluscircleIcon1}
                    contentFit="cover"
                    source={require("../assets/pluscircle2.png")}
                  />
                  <Text
                    style={[styles.nodevicesval, styles.text4Typo, { lineHeight: styles.text4Typo.lineHeight - 1, fontSize: styles.text4Typo.fontSize - 1 }]}
                  >${device?.total_billin_amount}</Text>
                </View>
                <View style={styles.moneyinframe}>
                  <Image
                    style={styles.pluscircleIcon1}
                    contentFit="cover"
                    source={require("../assets/minuscircle2.png")}
                  />
                  <Text style={[styles.nodevicesval, styles.text4Typo, { lineHeight: styles.text4Typo.lineHeight - 1, fontSize: styles.text4Typo.fontSize - 1 }]}>
                    ${device.total_ticketout_amount}
                  </Text>
                </View>
              </View>
            </View>
          </Pressable>
        </Pressable>
        ))}

        <Pressable
          style={[styles.alllocationsbtn, styles.dashCardBorder]}
          onPress={() =>
            navigation.navigate("DrawerRoot", { screen: "Devices" })
          }
        >
          <Text style={[styles.alllocationstxt, styles.locationNameLayout]}>
            All Devices
          </Text>
        </Pressable>
        <Pressable
          style={[styles.alllocationsbtn, styles.dashCardBorder, { marginBottom: 30 }]}
          onPress={() =>
            navigation.navigate("DrawerRoot", { screen: "AddSelectLocation" })
          }
        >
          <Text style={[styles.alllocationstxt, styles.locationNameLayout]}>
            Add Device
          </Text>
        </Pressable>

          {/*
        <Pressable
          style={[styles.alllocationsbtn, styles.dashCardBorder, { marginBottom: 30 }]}
          onPress={() =>
            navigation.navigate("Inventory" )
          }
        >
          <Text style={[styles.alllocationstxt, styles.locationNameLayout]}>
            Inventory
          </Text>
        </Pressable> */}

        <Text>{session?.user?.email}</Text>
        <Pressable 
          style={[{ marginBottom: 50 }]}
          onPress={() => supabase.auth.signOut()}>
            <Text style={{ color: '#0000FF' }}>Log Out</Text>
        </Pressable> 

        
      </ScrollView>
    </View>
  );
};

const styles = StyleSheet.create({
  dashScrollableScrollViewContent: {
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "flex-start",
  },
  dashLayout: {
    width: "100%",
    flex: 1,
  },
  topbarPosition: {
    backgroundColor: Color.colorGold,
    left: "0%",
    right: "0%",
    position: "absolute",
    width: "100%",
  },
  contentFlexBox: {
    justifyContent: "space-between",
    flexDirection: "row",
  },
  locationsFlexBox: {
    marginTop: 11,
    alignSelf: "stretch",
    alignItems: "center",
  },
  dashCardBorder: {
    padding: Padding.p_base,
    borderWidth: 1.3,
    borderColor: Color.colorGainsboro_100,
    borderStyle: "solid",
    borderRadius: Border.br_smi,
    flexDirection: "row",
  },
  todayClr: {
    color: Color.labelColorLightSecondary,
    lineHeight: 18,
    fontSize: FontSize.size_base,
  },
  textTypo: {
    marginTop: 2,
    fontFamily: FontFamily.interBold,
    fontWeight: "700",
    textAlign: "left",
    letterSpacing: 0,
  },
  devicecardcontentLayout: {
    width: 288,
    marginLeft: 10,
  },
  locationNameLayout: {
    lineHeight: 20,
    letterSpacing: 0,
  },
  text4Typo: {
    fontSize: FontSize.size_smi,
    color: Color.labelColorLightSecondary,
    fontFamily: FontFamily.interBold,
    fontWeight: "700",
    lineHeight: 18,
    textAlign: "left",
    letterSpacing: 0,
  },
  statusbar: {
    height: "62.77%",
    top: "0%",
    bottom: "37.23%",
    alignItems: "flex-end",
    justifyContent: "center",
  },
  hamburgerIcon: {
    height: 15,
    width: 20,
  },
  organizationName: {
    fontWeight: "600",
    fontFamily: FontFamily.interSemiBold,
    textAlign: "left",
    color: Color.labelColorLightPrimary,
    lineHeight: 25,
    letterSpacing: 0,
    fontSize: FontSize.size_xl,
  },
  details: {
    paddingHorizontal: Padding.p_mini,
    paddingVertical: 0,
    flex: 1,
  },
  topbarinner: {
    height: 35,
    paddingHorizontal: Padding.p_8xs,
    paddingBottom: Padding.p_3xs,
    alignSelf: "stretch",
    alignItems: "center",
  },
  topbar: {
    height: "37.23%",
    top: "62.77%",
    bottom: "0%",
    overflow: "hidden",
    alignItems: "center",
  },
  top: {
    height: 94,
    alignSelf: "stretch",
  },
  dashGraphCard: {
    height: 243,
    borderWidth: 1.3,
    borderColor: Color.colorGainsboro_100,
    borderStyle: "solid",
    borderRadius: Border.br_smi,
    backgroundColor: Color.systemBackgroundLightPrimary,
    alignSelf: "stretch",
  },
  today: {
    fontFamily: FontFamily.interBold,
    fontWeight: "700",
    textAlign: "left",
    letterSpacing: 0,
    color: Color.labelColorLightSecondary,
    lineHeight: 18,
    fontSize: FontSize.size_base,
  },
  text: {
    color: Color.labelColorLightPrimary,
    lineHeight: 25,
    marginTop: 2,
    fontSize: FontSize.size_xl,
  },
  totalRevenue: {
    color: Color.labelColorLightSecondary,
    lineHeight: 18,
    fontSize: FontSize.size_base,
  },
  pluscircleIcon: {
    height: 20,
    width: 20,
  },
  content: {
    height: 99,
    flex: 1,
  },
  dashRevenueCard: {
    height: 170,
    padding: Padding.p_base,
    backgroundColor: Color.systemBackgroundLightPrimary,
    flex: 1,
  },
  dashPayoutCard: {
    marginLeft: 9,
    height: 170,
    padding: Padding.p_base,
    backgroundColor: Color.systemBackgroundLightPrimary,
    flex: 1,
  },
  revenuecards: {
    flexDirection: "row",
  },
  locationimgIcon: {
    borderRadius: Border.br_12xs,
    width: 42,
    height: 42,
  },
  locationName: {
    fontSize: FontSize.size_mini,
    display: "flex",
    width: 262,
    fontFamily: FontFamily.interBold,
    fontWeight: "700",
    textAlign: "left",
    color: Color.labelColorLightPrimary,
    alignItems: "center",
  },
  lanyardcardIcon: {
    width: 11,
    height: 15,
  },
  nodevicesval: {
    marginLeft: 1,
  },
  nodevicesframe: {
    flexDirection: "row",
    alignItems: "center",
  },
  pluscircleIcon1: {
    width: 15,
    height: 15,
  },
  moneyinframe: {
    marginLeft: 10,
    flexDirection: "row",
    alignItems: "center",
  },
  moneyinframe2: {
    marginLeft: 1,
    flexDirection: "row",
    alignItems: "center",
  },
  details3: {
    marginTop: 4,
    flexDirection: "row",
    alignItems: "center",
  },
  locationcardcontent: {
    marginLeft: 10,
  },
  locationcard: {
    backgroundColor: Color.systemBackgroundLightPrimary,
    padding: Padding.p_base,
    alignSelf: "stretch",
  },
  alllocationstxt: {
    fontWeight: "500",
    fontFamily: FontFamily.interMedium,
    color: Color.colorGray_300,
    textAlign: "center",
    fontSize: FontSize.size_xl,
    lineHeight: 20,
    height: 17,
  },
  alllocationsbtn: {
    backgroundColor: Color.colorWhitesmoke,
    height: 37,
    marginTop: 11,
    alignSelf: "stretch",
    alignItems: "center",
    overflow: "hidden",
    justifyContent: "center",
  },
  text4: {
    display: "none",
    width: 92,
    alignItems: "center",
  },
  devicecardcontent: {
    height: 41,
    marginLeft: 10,
  },
  dashscrollable: {
    padding: Padding.p_2xs,
    maxWidth: "100%",
  },
  dash: {
    backgroundColor: Color.colorGhostwhite,
    alignItems: "center",
  },
  orgTotalsContainer: {
    padding: 16,
    backgroundColor: Color.systemBackgroundLightPrimary,
    borderRadius: Border.br_smi,
    marginBottom: 16,
  },
  totalItem: {
    marginBottom: 8,
  },
  totalSource: {
    fontWeight: 'bold',
  },
  totalPeriod: {
    color: Color.labelColorLightSecondary,
  },
  totalAmount: {
    color: Color.labelColorLightPrimary,
  },
  billInContainer: {
    flex: 1,
    marginRight: 8,
  },
  ticketOutContainer: {
    flex: 1,
    marginLeft: 8,
  },
});

export default Dash;