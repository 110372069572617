import { supabase } from './supabase'

export async function get24HourTotalsByOrg(p_orgid) {
  const { data, error } = await supabase.rpc('total24byorg', { p_orgid });

  if (error) {
    console.error('Error fetching 24-hour totals by organization:', error);
    throw error;
  }

  return data;
}

export async function getMonthlyTotalsByOrg(p_orgid) {
    const { data, error } = await supabase.rpc('totalmonthbyorg', { p_orgid });
  
      console.log("in func ", p_orgid);
  
    if (error) {
      console.error('Error fetching Monthly totals by organization:', error);
      throw error;
    }
  
    return data;
}

export async function getCombinedTotalsByOrg(p_orgid) {
    try {
      const [totals24Hour, totalsMonthly] = await Promise.all([
        get24HourTotalsByOrg(p_orgid),
        getMonthlyTotalsByOrg(p_orgid)
      ]);
  
      const combinedTotals = {
        billin24Hour: totals24Hour[0]?.total_billin_amount,
        ticketout24Hour: totals24Hour[0]?.total_ticketout_amount,
        billinMonthly: totalsMonthly[0]?.total_billin_amount,
        ticketoutMonthly: totalsMonthly[0]?.total_ticketout_amount
      };
  
      return combinedTotals;
    } catch (error) {
      console.error('Error fetching combined totals by organization:', error);
      throw error;
    }
  }

  export async function getLocationsByOrgId(orgId, limit) {
    const { data: locations, error: locationError } = await supabase
      .from('locations')
      .select('locationid, locationname, locationcity, locationstate, locationimg, devices, locationaddress')
      .eq('orgid', orgId)
      .limit(limit);
  
    if (locationError) {
      console.error('Error fetching locations:', locationError);
      throw locationError;
    }
  
    const locationsWithTotals = await Promise.all(
      locations.map(async (location) => {
        // Updated to use the new RPC function
        const { data: totalsData, error: totalsError } = await supabase.rpc('get_totals_by_location', { location_id: location.locationid });

        if (totalsError) {
          console.error(`Error fetching totals for location ${location.locationid}:`, totalsError);
          throw totalsError;
        }

        const deviceCount = location.devices ? location.devices.length : 0;

        return {
          ...location,
          total_billin_amount: totalsData[2].billin_total, // Updated to format the total
          total_ticketout_amount: totalsData[2].ticketout_total, // Updated to format the total
          device_count: deviceCount,
        };
      })
    );
  
    return locationsWithTotals;
  }


  export async function getDevicesByOrgId(orgId, limit) {
    const { data: devices, error: deviceError } = await supabase
      .from('devices')
      .select('deviceid, devicename, locationid, deviceimg')
      .eq('orgid', orgId)
      .limit(limit);

    if (deviceError) {
      throw deviceError;
    }

    const devicesWithDetails = await Promise.all(
      devices.map(async (device) => {
        const { data: locationData } = await supabase
          .from('locations')
          .select('locationname')
          .eq('locationid', device.locationid)
          .single();
        
        const { data: [totals], error: totalsError } = await supabase.rpc('totalmonthbydevice', { p_deviceid: device.deviceid });

        console.log("mon sad ", totals);

        if (totalsError) {
          throw totalsError;
        }

        return {
          ...device,
          locationname: locationData.locationname,
          total_billin_amount: totals?.total_billin_amount || 0,
          total_ticketout_amount: totals?.total_ticketout_amount || 0,
        };
      })
    );

    // Sort by top billin first
    return devicesWithDetails.sort((a, b) => b.total_billin_amount - a.total_billin_amount);
  }


// getMonthlyTotalsByOrg
// supabase function totalmonthbyorg    p_orgid uuid    TABLE(total_billin_amount real, total_ticketout_amount real)


// need new sql function that gets 