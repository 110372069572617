import React, { useState } from "react";
import {
  StyleSheet,
  View,
  Pressable,
  Text,
  ScrollView,
  TextInput,
} from "react-native";
import { Image } from "expo-image";
import DropDownPicker from "react-native-dropdown-picker";
import { DrawerNavigationProp } from "@react-navigation/drawer";
import { useNavigation, ParamListBase } from "@react-navigation/native";
import { StackNavigationProp } from "@react-navigation/stack";
import { Color, FontFamily, FontSize, Padding, Border } from "../GlobalStyles";

const DeviceEdit = () => {
  const [editlocationcardOpen, setEditlocationcardOpen] = useState(false);
  const [editlocationcardValue, setEditlocationcardValue] = useState();
  const navigation = useNavigation<DrawerNavigationProp<ParamListBase>>();

  return (
    <View style={styles.deviceEdit}>
      <View style={styles.top}>
        <View style={styles.statusbar} />
        <View style={styles.topbar}>
          <View style={styles.topbarinner}>
            <Pressable
              style={styles.hamburger}
              onPress={() => navigation.toggleDrawer()}
            >
              <Image
                style={styles.icon}
                contentFit="cover"
                source={require("../assets/hamburger.png")}
              />
            </Pressable>
            <View style={styles.details}>
              <Text style={styles.toptextTypo}>Edit Device Name</Text>
            </View>
          </View>
        </View>
      </View>
      <ScrollView
        style={styles.frame}
        showsVerticalScrollIndicator={false}
        showsHorizontalScrollIndicator={false}
        contentContainerStyle={styles.frameScrollViewContent}
      >
        <Pressable
          style={styles.title}
          onPress={() => navigation.navigate("DeviceDetail")}
        >
          <Image
            style={styles.arrowuturnleftsquareIcon}
            contentFit="cover"
            source={require("../assets/arrowuturnleftsquare.png")}
          />
          <Text style={[styles.deviceName, styles.toptextTypo]}>
            Device Name
          </Text>
        </Pressable>
        <View style={[styles.devicedetailsform, styles.saveSpaceBlock]}>
          <Text style={[styles.name, styles.nameTypo]}>Name</Text>
          <TextInput
            style={[styles.devicename, styles.detailSpaceBlock]}
            placeholder="  Slug (url)"
            placeholderTextColor="rgba(60, 60, 67, 0.3)"
          />
          <Text style={[styles.detail, styles.detailSpaceBlock]}>Detail</Text>
          <TextInput
            style={[styles.devicename, styles.detailSpaceBlock]}
            placeholder="  Name"
            placeholderTextColor="rgba(60, 60, 67, 0.3)"
          />
          <Text style={[styles.detail, styles.detailSpaceBlock]}>Detail</Text>
          <TextInput
            style={[styles.devicename, styles.detailSpaceBlock]}
            placeholder="  Name"
            placeholderTextColor="rgba(60, 60, 67, 0.3)"
          />
          <Text style={[styles.detail, styles.detailSpaceBlock]}>Detail</Text>
          <TextInput
            style={[styles.devicename, styles.detailSpaceBlock]}
            placeholder="  Name"
            placeholderTextColor="rgba(60, 60, 67, 0.3)"
          />
          <Text style={[styles.detail, styles.detailSpaceBlock]}>Detail</Text>
          <TextInput
            style={[styles.devicename, styles.detailSpaceBlock]}
            placeholder="  Name"
            placeholderTextColor="rgba(60, 60, 67, 0.3)"
          />
        </View>
        <View style={[styles.editlocationcard, styles.saveSpaceBlock]}>
          <DropDownPicker
            style={[styles.dropdownpicker, styles.saveBorder]}
            open={editlocationcardOpen}
            setOpen={setEditlocationcardOpen}
            value={editlocationcardValue}
            setValue={setEditlocationcardValue}
            placeholder="Location Name"
            items={[]}
            labelStyle={styles.editlocationcardValue}
            textStyle={styles.editlocationcardText}
            dropDownContainerStyle={styles.editlocationcarddropDownContainer}
          />
        </View>
        <Pressable style={[styles.save, styles.saveSpaceBlock]}>
          <Text style={styles.save1}>Save</Text>
        </Pressable>
      </ScrollView>
    </View>
  );
};

const styles = StyleSheet.create({
  editlocationcardValue: {
    color: "#000",
    fontSize: 13,
    fontWeight: "700",
    fontFamily: "Inter-Bold",
  },
  editlocationcardText: {
    color: "rgba(0, 0, 0, 0.5)",
    fontSize: 12,
    fontWeight: "700",
    fontFamily: "Inter-Bold",
  },
  editlocationcarddropDownContainer: {
    backgroundColor: "#fff",
    borderStyle: "solid",
    borderColor: "#e5e5e5",
    borderWidth: 1.3,
  },
  frameScrollViewContent: {
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "flex-start",
  },
  toptextTypo: {
    textAlign: "left",
    color: Color.labelColorLightPrimary,
    fontFamily: FontFamily.interSemiBold,
    fontWeight: "600",
    lineHeight: 25,
    letterSpacing: 0,
    fontSize: FontSize.size_xl,
  },
  saveSpaceBlock: {
    marginTop: 11,
    padding: Padding.p_base,
    borderRadius: Border.br_smi,
    borderStyle: "solid",
    alignSelf: "stretch",
  },
  nameTypo: {
    color: Color.colorGray_200,
    lineHeight: 20,
    textAlign: "left",
    fontFamily: FontFamily.interSemiBold,
    fontWeight: "600",
    letterSpacing: 0,
    fontSize: FontSize.size_xl,
  },
  detailSpaceBlock: {
    marginTop: 10,
    alignSelf: "stretch",
  },
  saveBorder: {
    borderWidth: 1.3,
    borderColor: Color.colorGainsboro_100,
  },
  statusbar: {
    height: "62.77%",
    top: "0%",
    bottom: "37.23%",
    alignItems: "flex-end",
    justifyContent: "center",
    backgroundColor: Color.colorGold,
    left: "0%",
    right: "0%",
    position: "absolute",
    width: "100%",
  },
  icon: {
    height: "100%",
    width: "100%",
  },
  hamburger: {
    width: 20,
    height: 15,
  },
  details: {
    paddingHorizontal: Padding.p_mini,
    paddingVertical: 0,
    flexDirection: "row",
    flex: 1,
  },
  topbarinner: {
    height: 35,
    justifyContent: "space-between",
    paddingLeft: Padding.p_8xs,
    paddingRight: Padding.p_xs,
    paddingBottom: Padding.p_3xs,
    flexDirection: "row",
    alignItems: "center",
    alignSelf: "stretch",
  },
  topbar: {
    height: "37.23%",
    top: "62.77%",
    bottom: "0%",
    alignItems: "center",
    overflow: "hidden",
    backgroundColor: Color.colorGold,
    left: "0%",
    right: "0%",
    position: "absolute",
    width: "100%",
  },
  top: {
    height: 94,
    alignSelf: "stretch",
  },
  arrowuturnleftsquareIcon: {
    width: 16,
    height: 16,
  },
  deviceName: {
    marginLeft: 10,
  },
  title: {
    flexDirection: "row",
    alignItems: "center",
  },
  name: {
    alignSelf: "stretch",
  },
  devicename: {
    borderRadius: Border.br_3xs,
    backgroundColor: Color.fillColorLightTertiary,
    paddingHorizontal: Padding.p_5xs,
    paddingVertical: Padding.p_6xs,
    fontFamily: FontFamily.interRegular,
    fontSize: FontSize.size_mid,
    flexDirection: "row",
    alignItems: "center",
  },
  detail: {
    color: Color.colorGray_200,
    lineHeight: 20,
    textAlign: "left",
    fontFamily: FontFamily.interSemiBold,
    fontWeight: "600",
    letterSpacing: 0,
    fontSize: FontSize.size_xl,
  },
  devicedetailsform: {
    borderWidth: 1.3,
    borderColor: Color.colorGainsboro_100,
    backgroundColor: Color.systemBackgroundLightPrimary,
    padding: Padding.p_base,
    borderRadius: Border.br_smi,
  },
  dropdownpicker: {
    borderStyle: "solid",
    borderColor: Color.colorGainsboro_100,
    backgroundColor: Color.systemBackgroundLightPrimary,
  },
  editlocationcard: {
    padding: Padding.p_base,
    borderRadius: Border.br_smi,
    flexDirection: "row",
    overflow: "hidden",
  },
  save1: {
    lineHeight: 22,
    fontWeight: "500",
    fontFamily: FontFamily.interMedium,
    color: Color.systemBackgroundLightPrimary,
    textAlign: "center",
    letterSpacing: 0,
    fontSize: FontSize.size_xl,
  },
  save: {
    backgroundColor: Color.colorDeepskyblue,
    height: 43,
    borderWidth: 1.3,
    borderColor: Color.colorGainsboro_100,
    padding: Padding.p_base,
    borderRadius: Border.br_smi,
    flexDirection: "row",
    overflow: "hidden",
    alignItems: "center",
    justifyContent: "center",
  },
  frame: {
    padding: Padding.p_2xs,
    alignSelf: "stretch",
    flex: 1,
  },
  deviceEdit: {
    backgroundColor: Color.colorGhostwhite,
    width: "100%",
    flex: 1,
  },
});

export default DeviceEdit;
