import { useState, useEffect } from 'react';
import { isAdmin } from '../lib/perm';
import getOrganizationDetails from '../lib/org';
import { useSession } from '../lib/SessionContext';

const useOrg = () => {
  const { session } = useSession();
  const [isAdminStatus, setAdminStatus] = useState(false);
  const [currentOrganization, setCurrentOrganization] = useState(null);

  useEffect(() => {
    const fetchOrganizationInfo = async () => {
      if (session?.user?.email) {
        const details = await getOrganizationDetails(session.user.email);
        if (details && details.length > 0) {
          setCurrentOrganization(details[0]);

          if (details[0]?.orgid) {
            const adminStatus = await isAdmin(session?.user?.email, details[0].orgid);
            setAdminStatus(adminStatus);
          } else {
            console.error("OrgSlug is undefined; cannot check admin status");
            setAdminStatus(false); 
          }
        }
      }
    };

    fetchOrganizationInfo();
  }, [session?.user?.email]);

  return { isAdminStatus, currentOrganization };
};

export { useOrg };