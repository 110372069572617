import React, { useState, useEffect, useCallback } from 'react';
import { View, TextInput, Button, StyleSheet, ScrollView, RefreshControl, Alert, Text, Pressable, Image } from 'react-native';
import { useNavigation, useRoute, ParamListBase } from "@react-navigation/native";
import { DrawerNavigationProp } from "@react-navigation/drawer";
import { Color, Padding, Border, FontSize, FontFamily } from "../GlobalStyles";

const API_URL = 'https://script.google.com/macros/s/AKfycbw4vElmq7Yylc0u4s_xjCNZMssYQRHC5iR_mHR2FCz7jkxh_r9ISGO0mubHBY5rGVaWxQ/exec';

const ItemDetails = () => {
  const navigation = useNavigation<DrawerNavigationProp<ParamListBase>>();
  const route = useRoute();
  const { qrId, name, serial, details } = route.params || {}; // Ensure you're reading qrId

  const [qrIdField, setQrId] = useState(qrId || '');
  const [itemName, setItemName] = useState(name || '');
  const [itemSerial, setItemSerial] = useState(serial || '');
  const [itemDetails, setItemDetails] = useState(details || '');
  const [hasChanged, setHasChanged] = useState(false);
  const [loading, setLoading] = useState(false);
  const [refreshing, setRefreshing] = useState(false);

  const fetchItemData = async () => {
    setRefreshing(true);
    try {
      console.log(`Fetching item data for ID: ${qrIdField}`);
      const response = await fetch(`${API_URL}?username=chowx&key=xxx&id=${qrIdField}`);
      if (response.ok) {
        const data = await response.json();
        console.log(`Fetched data: ${JSON.stringify(data)}`);
        setItemName(data.ColumnB || '');
        setItemSerial(data.ColumnC || '');
        setItemDetails(data.ColumnD || '');
        setHasChanged(false);
      } else {
        console.error(`Failed to fetch data: ${response.statusText}`);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setRefreshing(false);
    }
  };

  useEffect(() => {
    if (qrId) {
      fetchItemData();
    }
  }, [qrId]);


  const handleSave = async () => {
    try {
      setLoading(true);
      console.log('Preparing to save item...');
      console.log(`Current Data: ID: ${qrId}, Name: ${itemName}, Serial: ${itemSerial}, Details: ${itemDetails}`);
      
      const requestBody = JSON.stringify({
        action: "update",
        username: "chowx",
        key: "xxx",
        id: qrId,
        b: itemName,
        c: itemSerial,
        d: itemDetails
      });
      
      console.log(`Sending data: ${requestBody}`);
      
      const response = await fetch(API_URL, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: requestBody
      });
      
      if (response.ok) {
        console.log('Item saved successfully.');
        Alert.alert('Success', 'Item saved successfully!', [{ text: 'OK' }]);
        setHasChanged(false);
      } else {
        const errorText = await response.text();
        console.error(`Failed to save item: ${errorText}`);
        Alert.alert('Error', `Failed to save item: ${errorText}`);
      }
    } catch (error) {
      console.error('Error saving item:', error);
      Alert.alert('Error', 'An error occurred while saving the item.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <View style={styles.deviceDetail}>
      <View style={styles.top}>
        <View style={[styles.statusbar, styles.topbarPosition]} />
        <View style={[styles.topbar, styles.topbarPosition]}>
          <View style={styles.topbarinner}>
          <Pressable
            style={{
              width: 60, // Set a specific width for the button
              height: 60, // Set a specific height for the button
              justifyContent: 'center',
              alignItems: 'center',
              padding: 10, // Optional: additional padding
              backgroundColor: 'transparent', // Ensure background is transparent
            }}
            onPress={() => navigation.goBack()}
          >
            <Image
              style={{
                width: '60%', // Make the image fill the button
                height: '60%', // Make the image fill the button
                resizeMode: 'contain', // Ensure the image maintains its aspect ratio
              }}
              source={require("../assets/calender.png")}
            />
          </Pressable>
            <View style={styles.details}>
              <Text style={styles.toptext}>{itemName}</Text>
            </View>
            <Image
              style={styles.sliderhorizontal3Icon}
              contentFit="cover"
              source={require("../assets/sliderhorizontal3.png")}
            />
          </View>
        </View>
      </View>
      <View style={styles.container}>
      <ScrollView
        refreshControl={
          <RefreshControl refreshing={refreshing} onRefresh={fetchItemData} />
        }
      >
        <TextInput
          style={styles.input}
          placeholder="Name"
          value={itemName}
          onChangeText={text => { setItemName(text); setHasChanged(true); }}
        />
        <TextInput
          style={styles.input}
          placeholder="QR ID"
          value={qrId}
          onChangeText={text => { setQrId(text); setHasChanged(true); }}
        />
        <TextInput
          style={styles.input}
          placeholder="Serial"
          value={itemSerial}
          onChangeText={text => { setItemSerial(text); setHasChanged(true); }}
        />
        <TextInput
          style={styles.detailsInput}
          placeholder="Details"
          value={itemDetails}
          multiline
          numberOfLines={10}
          onChangeText={text => { setItemDetails(text); setHasChanged(true); }}
        />
        <Button
          title={hasChanged ? "Save" : "Saved"}
          onPress={handleSave}
          disabled={!hasChanged || loading}
          color={hasChanged ? "blue" : "green"}
        />
      </ScrollView></View>
    </View>
  );
};

const styles = StyleSheet.create({
  deviceDetail: {
    backgroundColor: Color.colorGhostwhite,
    alignItems: "center",
    width: "100%",
    flex: 1,
  },
  topbarPosition: {
    backgroundColor: Color.colorGold,
    left: "0%",
    right: "0%",
    position: "absolute",
    width: "100%",
  },
  detailTypo: {
    fontFamily: FontFamily.interRegular,
    fontSize: FontSize.size_base,
    color: Color.labelColorLightSecondary,
    lineHeight: 18,
    textAlign: "left",
    letterSpacing: 0,
  },
  statusbar: {
    height: "62.77%",
    top: "0%",
    bottom: "37.23%",
    alignItems: "flex-end",
    justifyContent: "center",
  },
  icon: {
    height: "100%",
    width: "100%",
  },
  hamburger: {
    height: 15,
    width: 20,
  },
  toptext: {
    textAlign: "left",
    color: Color.labelColorLightPrimary,
    fontFamily: FontFamily.interSemiBold,
    fontWeight: "600",
    lineHeight: 25,
    letterSpacing: 0,
    fontSize: FontSize.size_xl,
  },
  details: {
    paddingHorizontal: Padding.p_mini,
    paddingVertical: 0,
    flexDirection: "row",
    flex: 1,
  },
  sliderhorizontal3Icon: {
    width: 21,
    height: 18,
  },
  topbarinner: {
    height: 35,
    justifyContent: "space-between",
    paddingLeft: Padding.p_8xs,
    paddingRight: Padding.p_xs,
    paddingBottom: Padding.p_3xs,
    flexDirection: "row",
    alignSelf: "stretch",
    alignItems: "center",
  },
  topbar: {
    height: "37.23%",
    top: "62.77%",
    bottom: "0%",
    overflow: "hidden",
    alignItems: "center",
  },
  top: {
    height: 94,
    alignSelf: "stretch",
  },
  locationimgIcon: {
    borderRadius: Border.br_12xs,
    width: 60,
    height: 60,
  },
  locationtitle: {
    fontSize: FontSize.size_mini,
    lineHeight: 20,
    width: 262,
    display: "flex",
    fontFamily: FontFamily.interBold,
    fontWeight: "700",
    textAlign: "left",
    color: Color.labelColorLightPrimary,
    letterSpacing: 0,
    alignItems: "center",
  },
  container: {
    padding: 16,
    width: "100%",
    backgroundColor: Color.systemBackgroundLightPrimary,
    flex: 1,
  },
  input: {
    paddingHorizontal: Padding.p_mini,
    paddingVertical: Padding.p_8xs,
    borderColor: Color.colorGainsboro_100,
    borderWidth: 1,
    borderRadius: Border.br_smi,
    marginBottom: 16,
    fontSize: FontSize.size_base,
    fontFamily: FontFamily.interRegular,
  },
  detailsInput: {
    height: 150,
    paddingHorizontal: Padding.p_mini,
    paddingVertical: Padding.p_8xs,
    borderColor: Color.colorGainsboro_100,
    borderWidth: 1,
    borderRadius: Border.br_smi,
    marginBottom: 16,
    fontSize: FontSize.size_base,
    fontFamily: FontFamily.interRegular,
    textAlignVertical: "top",
  },
});

export default ItemDetails;