const Stack = createNativeStackNavigator();
import * as React from "react";
import { useState, useEffect, useRef } from 'react';
import { NavigationContainer } from "@react-navigation/native";
import { useFonts } from "expo-font";
import Dash from "./screens/Dash";
import Devices from "./screens/Devices";
import Splash from "./screens/Splash";
import Login from "./screens/Login";
import AddConnectWifi from "./screens/AddConnectWifi";
import AddDeviceConfirmation from "./screens/AddDeviceConfirmation";
import AddSelectLocation from "./screens/AddSelectLocation";
import AddDeviceDetails from "./screens/AddDeviceDetails";
import AddWifDetails from "./screens/AddWifDetails";
import AddWifiConfirmation from "./screens/AddWifiConfirmation";
import DrawerMenu from "./components/DrawerMenu";
import Locations from "./screens/Locations";
import DeviceDetail from "./screens/DeviceDetail";
import DeviceEdit from "./screens/DeviceEdit";
import Log from "./screens/Log";
import LogDetail from "./screens/LogDetail";
import LocationDetail from "./screens/LocationDetail";
import LocationEdit from "./screens/LocationEdit";
import Revenue from "./screens/Revenue";
import RevenueDetail from "./screens/RevenueDetail";
import MyAccount from "./screens/MyAccount";
import AppSettings from "./screens/AppSettings";
import Scan from "./screens/Scan";
import Inventory from "./screens/Inventory";
import NewOrg from "./screens/NewOrg";
import MenuItem from "./components/MenuItem";
import MenuItem1 from "./components/MenuItem1";
import MenuItem2 from "./components/MenuItem2";
import MenuItem3 from "./components/MenuItem3";
import MenuItem4 from "./components/MenuItem4";
import MenuItem5 from "./components/MenuItem5";
import MenuItem6 from "./components/MenuItem6";
import MenuItem7 from "./components/MenuItem7";
import MenuItem8 from "./components/MenuItem8";
import MenuItem9 from "./components/MenuItem9";
import MenuItem10 from "./components/MenuItem10";
import getOrganizationDetails from "./lib/org";
import { isAdmin } from "./lib/perm";
import AddQRSetup from "./screens/AddQRSetup";
import NewBoardSuccess from "./screens/NewBoardSuccess";

import { createNativeStackNavigator } from "@react-navigation/native-stack";
import { View, Text, Pressable, TouchableOpacity } from "react-native";

import { createDrawerNavigator } from "@react-navigation/drawer";

import SessionContext from './lib/SessionContext';
import { Session } from '@supabase/supabase-js';
import { supabase } from './lib/supabase';
import ItemDetails from "./screens/ItemDetails";
import InventoryQR from "./screens/InventoryQR";
import NewBoardSetup from "./screens/NewBoardSetup";

const Drawer = createDrawerNavigator();
function DrawerRoot({ navigation }: any) {
  return (
    <Drawer.Navigator
      screenOptions={{ headerShown: false, drawerStyle: { width: 320 } }}
      initialRouteName="Dash"
      drawerContent={(props: any) => <DrawerMenu {...props} />}
    >
      <Drawer.Screen
        name="Dash"
        component={Dash}
        options={{ headerShown: false }}
      />
      <Drawer.Screen
        name="Locations"
        component={Locations}
        options={{ headerShown: false }}
      />
      <Drawer.Screen
        name="Devices"
        component={Devices}
        options={{ headerShown: false }}
      />
      <Drawer.Screen
        name="Revenue"
        component={Revenue}
        options={{ headerShown: false }}
      />
      <Drawer.Screen
        name="AddSelectLocation"
        component={AddSelectLocation}
        options={{ headerShown: false }}
      />
      <Drawer.Screen
        name="Scan"
        component={Scan}
        options={{ headerShown: false }}
      />
      <Drawer.Screen
        name="AppSettings"
        component={AppSettings}
        options={{ headerShown: false }}
      />
      <Drawer.Screen
        name="MyAccount"
        component={MyAccount}
        options={{ headerShown: false }}
      />
    </Drawer.Navigator>
  );
}

const App = () => {
  const [session, setSession] = useState<Session | null>(null);
  const [currentOrganization, setOrganizationDetails] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [fontsLoaded, error] = useFonts({
    "Inter-Regular": require("./assets/fonts/Inter-Regular.ttf"),
    "Inter-Medium": require("./assets/fonts/Inter-Medium.ttf"),
    "Inter-SemiBold": require("./assets/fonts/Inter-SemiBold.ttf"),
    "Inter-Bold": require("./assets/fonts/Inter-Bold.ttf"),
    "Roboto-Medium": require("./assets/fonts/Roboto-Medium.ttf"),
    "Roboto-Bold": require("./assets/fonts/Roboto-Bold.ttf"),
  });

  const linking = {
    prefixes: ['https://sgkfyp.com', 'sgkfyp://', 'http://localhost:8081'],
    config: {
      screens: {
        DrawerRoot: {
          path: 'dash',
          screens: {
            Dash: '',
            Locations: 'locations',
            Devices: 'devices',
            Revenue: 'revenue',
            AddSelectLocation: 'add-location',
            Scan: 'scan',
            AppSettings: 'settings',
            MyAccount: 'account',
            NewBoardSuccess: 'nbs',
          },
        },
        BS: {
          path: 'bs',
          parse: {
            did: (did: string) => did
          },
        },
        Login: 'login',
        NewOrg: 'new-organization',
      },
    },
  };

  const getInitialRoute = () => {
    if (window.location.pathname.startsWith('/bs')) {
      return 'BS';
    }
    return session?.user ? (currentOrganization ? 'DrawerRoot' : 'NewOrg') : 'Login';
  };

  useEffect(() => {
    const fetchSession = async () => {
      setIsLoading(true);
      const sessionResponse = await supabase.auth.getSession();
      setSession(sessionResponse.data.session);
      setIsLoading(false);

      const authListener = supabase.auth.onAuthStateChange((_event, session) => {
        setSession(session);
      });

      return () => {
        authListener.unsubscribe();
      };
    };

    fetchSession();
  }, []);

  // Effect for loading organization details
  useEffect(() => {
    const fetchAndUpdateOrganizationDetails = async () => {
      if (session?.user?.email) {
        const details = await getOrganizationDetails(session.user.email);
        if (details && details.length > 0) {
          setOrganizationDetails(details[0]);
        }
      }
    };

    fetchAndUpdateOrganizationDetails();
  }, [session?.user?.email]);

  if (!fontsLoaded && !error) {
    return null;
  }

  if (isLoading) {
    return null;
  }

  return (
    <SessionContext.Provider value={{ session }}>
      <NavigationContainer linking={linking}>
        <Stack.Navigator 
          screenOptions={{ headerShown: false }}
          initialRouteName={getInitialRoute()}
        >
          <Stack.Screen name="BS" component={NewBoardSetup} />
          {session && session.user ? (
            currentOrganization ? (
              <>
                <Stack.Screen name="DrawerRoot" component={DrawerRoot} />
                <Stack.Screen name="Splash" component={Splash} />
                <Stack.Screen name="AddConnectWifi" component={AddConnectWifi} />
                <Stack.Screen name="AddDeviceConfirmation" component={AddDeviceConfirmation} />
                <Stack.Screen name="AddDeviceDetails" component={AddDeviceDetails} />
                <Stack.Screen name="AddWifDetails" component={AddWifDetails} />
                <Stack.Screen name="AddWifiConfirmation" component={AddWifiConfirmation} />
                <Stack.Screen name="DeviceDetail" component={DeviceDetail} />
                <Stack.Screen name="AddQRSetup" component={AddQRSetup} />
                <Stack.Screen name="DeviceEdit" component={DeviceEdit} />
                <Stack.Screen name="Log" component={Log} />
                <Stack.Screen name="LogDetail" component={LogDetail} />
                <Stack.Screen name="LocationDetail" component={LocationDetail} />
                <Stack.Screen name="LocationEdit" component={LocationEdit} />
                <Stack.Screen name="RevenueDetail" component={RevenueDetail} />
                <Stack.Screen name="Inventory" component={Inventory} />
                <Stack.Screen name="InventoryQR" component={InventoryQR} />
                <Stack.Screen name="ItemDetails" component={ItemDetails} />
                <Stack.Screen name="NewBoardSuccess" component={NewBoardSuccess} />
              </>
            ) : (
              <Stack.Screen name="NewOrg" component={NewOrg} />
            )
          ) : (
            <Stack.Screen name="Login" component={Login} />
          )}
        </Stack.Navigator>
      </NavigationContainer>
    </SessionContext.Provider>
  );
};
export default App;
