import * as React from "react";
import { StyleSheet, View, Text } from "react-native";
import { FontFamily, FontSize, Color, Padding } from "../GlobalStyles";

const Splash = () => {
  return (
    <View style={styles.splash}>
      <View style={styles.statusbar} />
      <View style={styles.paymentMethods}>
        <Text style={[styles.skillGamesKiosk, styles.companionAppTypo]}>
          Skill Games Kiosk
        </Text>
        <Text style={[styles.companionApp, styles.companionAppTypo]}>
          Companion App
        </Text>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  companionAppTypo: {
    textAlign: "left",
    fontFamily: FontFamily.interSemiBold,
    fontWeight: "600",
    lineHeight: 25,
    letterSpacing: 0,
    fontSize: FontSize.size_xl,
  },
  statusbar: {
    width: 393,
    height: 59,
    alignItems: "flex-end",
    justifyContent: "center",
  },
  skillGamesKiosk: {
    color: Color.labelColorLightPrimary,
  },
  companionApp: {
    color: Color.colorGray_200,
  },
  paymentMethods: {
    alignSelf: "stretch",
    alignItems: "center",
    padding: Padding.p_2xs,
    minHeight: 400,
    justifyContent: "center",
  },
  splash: {
    backgroundColor: Color.colorGold,
    flex: 1,
    width: "100%",
  },
});

export default Splash;
