import * as React from "react";
import {
  StyleSheet,
  View,
  Pressable,
  Text,
  ScrollView,
  Alert,
} from "react-native";
import { useState, useEffect } from "react";
import { DrawerNavigationProp } from "@react-navigation/drawer";
import { useNavigation, ParamListBase, useRoute, RouteProp } from "@react-navigation/native";
import { Color } from "../GlobalStyles";
import { supabase } from '../lib/supabase';
import SessionContext from "../lib/SessionContext";

type RootStackParamList = {
  NewBoardSuccess: { deviceId: string };
};

type NewBoardSuccessRouteProp = RouteProp<RootStackParamList, 'NewBoardSuccess'>;

const BoardStatusCard = ({ deviceId }: { deviceId: string }) => {
  const [status, setStatus] = useState<'online' | 'offline' | 'loading'>('loading');
  const [lastSeen, setLastSeen] = useState<string | null>(null);

  const checkBoardStatus = async () => {
    try {
      const { data: deviceData, error: deviceError } = await supabase
        .from('devices')
        .select('lastonline')
        .eq('deviceid', deviceId)
        .single();

      if (deviceError) {
        setStatus('offline');
        return;
      }

      if (deviceData && deviceData.lastonline) {
        const lastOnlineTime = new Date(deviceData.lastonline);
        const now = new Date();
        const diffMinutes = (now.getTime() - lastOnlineTime.getTime()) / (1000 * 60);

        setLastSeen(lastOnlineTime.toLocaleString());
        setStatus(diffMinutes <= 45 ? 'online' : 'offline');
        return;
      }

      const { data, error } = await supabase
        .from('v2_analyticsuptime')
        .select('device_time, function_time')
        .eq('deviceid', deviceId)
        .order('function_time', { ascending: false })
        .limit(1)
        .single();

      if (error) {
        setStatus('offline');
        return;
      }

      if (data) {
        const lastEventTime = new Date(data.function_time * 1000);
        const now = new Date();
        const diffMinutes = (now.getTime() - lastEventTime.getTime()) / (1000 * 60);

        setLastSeen(lastEventTime.toLocaleString());
        setStatus(diffMinutes <= 45 ? 'online' : 'offline');
      } else {
        setStatus('offline');
      }
    } catch (error) {
      setStatus('offline');
    }
  };

  useEffect(() => {
    checkBoardStatus();
    const interval = setInterval(checkBoardStatus, 60000);
    return () => clearInterval(interval);
  }, [deviceId]);

  return (
    <View style={styles.card}>
      <Text style={styles.cardTitle}>Board Status</Text>
      <Text style={styles.deviceIdText}>Device ID: {deviceId}</Text>
      <View style={[
        styles.statusIndicator, 
        status === 'online' ? styles.statusOnline : 
        status === 'offline' ? styles.statusOffline : 
        styles.statusLoading
      ]}>
        <Text style={styles.statusText}>
          {status.charAt(0).toUpperCase() + status.slice(1)}
        </Text>
      </View>
      {lastSeen && (
        <Text style={styles.lastSeenText}>Last seen: {lastSeen}</Text>
      )}
    </View>
  );
};

const NewBoardSuccess = () => {
  const navigation = useNavigation<DrawerNavigationProp<ParamListBase>>();
  const route = useRoute<NewBoardSuccessRouteProp>();
  const { session } = React.useContext(SessionContext);
  const [deviceDetails, setDeviceDetails] = useState<any>(null);
  const [locationName, setLocationName] = useState<string | null>(null);

  const deviceId = route.params?.deviceId;

  useEffect(() => {
    const fetchDeviceDetails = async () => {
      const { data, error } = await supabase
        .from('devices')
        .select(`
          devicename,
          devicedesc,
          locationid
        `)
        .eq('deviceid', deviceId)
        .single();

      if (error) {
        console.error('Error fetching device details:', error.message);
        return; // Handle error appropriately
      }

      if (data) {
        setDeviceDetails(data);
        // Fetch location name using locationid
        fetchLocationName(data.locationid);
      } else {
        console.warn('No device details found for deviceId:', deviceId);
      }
    };

    const fetchLocationName = async (locationId: string) => {
      const { data, error } = await supabase
        .from('locations')
        .select('locationname')
        .eq('locationid', locationId)
        .single();

      if (error) {
        console.error('Error fetching location name:', error.message);
        return; // Handle error appropriately
      }

      if (data) {
        setLocationName(data.locationname);
      }
    };

    fetchDeviceDetails();
  }, [deviceId]);

  if (!session?.user || !deviceDetails) {
    return (
      <View style={styles.container}>
        <Text>Loading...</Text>
      </View>
    );
  }

  return (
    <ScrollView style={styles.scrollContainer}>
      <View style={styles.container}>
        <View style={styles.content}>
          <BoardStatusCard deviceId={deviceId} />

          <View style={styles.card}>
            <Text style={styles.cardTitle}>Device Details</Text>
            <View style={styles.detailRow}>
              <Text style={styles.detailLabel}>Name:</Text>
              <Text style={styles.detailValue}>{deviceDetails.devicename}</Text>
            </View>
            <View style={styles.detailRow}>
              <Text style={styles.detailLabel}>Description:</Text>
              <Text style={styles.detailValue}>{deviceDetails.devicedesc}</Text>
            </View>
            <View style={styles.detailRow}>
              <Text style={styles.detailLabel}>Location:</Text>
              <Text style={styles.detailValue}>{locationName || 'Unknown Location'}</Text>
            </View>

            <View style={styles.buttonContainer}>
              <Pressable
                style={[styles.button, styles.updateButton]}
                onPress={() => Alert.alert('Coming soon', 'Update functionality will be available soon.')}
              >
                <Text style={styles.buttonText}>Update {locationName || 'Unknown Location'}</Text>
              </Pressable>

              <Pressable
                style={[styles.button, styles.returnButton]}
                onPress={() => navigation.navigate('DrawerRoot')}
              >
                <Text style={styles.buttonText}>Return to Dashboard</Text>
              </Pressable>
            </View>
          </View>
        </View>
      </View>
    </ScrollView>
  );
};

const styles = StyleSheet.create({
  scrollContainer: {
    flex: 1,
    backgroundColor: '#f5f5f5',
  },
  container: {
    flex: 1,
    padding: 20,
  },
  content: {
    flex: 1,
    alignItems: 'center',
    maxWidth: 600,
    width: '100%',
    alignSelf: 'center',
  },
  card: {
    backgroundColor: 'white',
    borderRadius: 10,
    padding: 20,
    marginBottom: 20,
    width: '100%',
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
    elevation: 5,
  },
  cardTitle: {
    fontSize: 20,
    fontWeight: 'bold',
    marginBottom: 15,
    textAlign: 'center',
  },
  deviceIdText: {
    fontSize: 16,
    marginBottom: 10,
  },
  statusIndicator: {
    padding: 10,
    borderRadius: 5,
    alignItems: 'center',
    marginBottom: 10,
  },
  statusOnline: {
    backgroundColor: '#4CAF50',
  },
  statusOffline: {
    backgroundColor: '#f44336',
  },
  statusLoading: {
    backgroundColor: '#FFA500',
  },
  statusText: {
    color: 'white',
    fontWeight: 'bold',
  },
  lastSeenText: {
    fontSize: 14,
    color: '#666',
    marginTop: 5,
  },
  detailRow: {
    flexDirection: 'row',
    paddingVertical: 12,
    borderBottomWidth: 1,
    borderBottomColor: '#eee',
    alignItems: 'center',
  },
  detailLabel: {
    fontSize: 16,
    fontWeight: 'bold',
    color: '#666',
    width: 120,
  },
  detailValue: {
    fontSize: 16,
    color: '#333',
    flex: 1,
  },
  buttonContainer: {
    marginTop: 20,
    gap: 10,
  },
  button: {
    padding: 15,
    borderRadius: 5,
    alignItems: 'center',
  },
  updateButton: {
    backgroundColor: '#4CAF50',
  },
  returnButton: {
    backgroundColor: Color.colorGold,
  },
  buttonText: {
    color: 'white',
    fontSize: 16,
    fontWeight: '500',
  },
});

export default NewBoardSuccess;