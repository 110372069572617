import * as React from "react";
import { useState, useEffect } from 'react';
import { StyleSheet, View, Pressable, Text, ScrollView, ActivityIndicator } from "react-native";
import { Image } from "expo-image";
import { DrawerNavigationProp } from "@react-navigation/drawer";
import { useNavigation, ParamListBase, useRoute } from "@react-navigation/native";
import { StackNavigationProp } from "@react-navigation/stack";
import { Color, Padding, Border, FontSize, FontFamily } from "../GlobalStyles";
import { getLocationBasic } from "../lib/loc";
import { getDeviceDetails } from "../lib/dev";
import { supabase } from "../lib/supabase";

async function getTenDaysByLocation(locationId) {
  const { data, error } = await supabase.rpc('ten_days_by_location', { input_locationid: locationId });
  if (error) {
    console.error('Error calling function:', error);
    return null;
  }
  return data;
}

async function getTotalsByLocation(locationId: string) { // Ensure locationId is a string
  const { data, error } = await supabase.rpc('get_totals_by_location', { location_id: locationId });
  if (error) {
    console.error('Error calling function:', error);
    return null;
  }
  return data;
}

const LocationDetail = () => {
  const navigation = useNavigation<DrawerNavigationProp<ParamListBase>>();
  const route = useRoute();
  const locationid = route.params?.locationid;
  const [locationDetails, setLocationDetails] = useState(null);
  const [deviceDetails, setDeviceDetails] = useState(null);
  const [isLoading, setLoading] = useState(true);
  const [error, setError] = useState(''); 
  const [tenDaysData, setTenDaysData] = useState(null);
  const [totalsData, setTotalsData] = useState(null); 


  useEffect(() => {
    async function fetchDeviceDetails() {
      const deviceIds = locationDetails?.devices || []; // Ensure deviceIds is defined as an empty array if undefined
      const validDeviceIds = deviceIds;
      //.filter((id: any) => id && typeof id === 'string' && /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/.test(id)); 

      const deviceDetailsPromises = validDeviceIds.map(id => {
          return getDeviceDetails(id);
      });
      
      const deviceDetails = await Promise.all(deviceDetailsPromises);
      setDeviceDetails(deviceDetails.filter(detail => detail !== null));
      console.log(deviceDetails);
    }

    fetchDeviceDetails();
  }, [locationDetails]); // Add locationDetails as a dependency

  console.log(" locid ", deviceDetails);

  useEffect(() => {
    async function fetchData() {
      try {
        const details = await getLocationBasic(locationid);
        setLocationDetails(details);

        // Fetch ten days data
        const tenDays = await getTenDaysByLocation(locationid);
        setTenDaysData(tenDays); // Store the ten days data

        // Fetch totals data
        const totals = await getTotalsByLocation(locationid); // Fetch totals
        setTotalsData(totals); // Store the totals data
      } catch (err) {
        setError('Failed to fetch device details');
        console.error("Failed to fetch device details:", err);
      } finally {
        setLoading(false);
      }
    }

    fetchData();
  }, [locationid]);

  if (isLoading) {
    return (
      <View style={styles.centered}>
        <ActivityIndicator size="large" />
      </View>
    );
  }

  if (error) {
    return (
      <View style={styles.centered}>
        <Text>{error}</Text>
      </View>
    );
  }


  return (
    <View style={styles.locationDetail}>
      <View style={styles.top}>
        <View style={[styles.statusbar, styles.topbarPosition]} />
        <View style={[styles.topbar, styles.topbarPosition]}>
          <View style={[styles.topbarinner, styles.content2FlexBox]}>
          <Pressable
            style={{
              width: 60, // Set a specific width for the button
              height: 60, // Set a specific height for the button
              justifyContent: 'center',
              alignItems: 'center',
              padding: 10, // Optional: additional padding
              backgroundColor: 'transparent', // Ensure background is transparent
            }}
            onPress={() => navigation.goBack()}
          >
            <Image
              style={{
                width: '60%', // Make the image fill the button
                height: '60%', // Make the image fill the button
                resizeMode: 'contain', // Ensure the image maintains its aspect ratio
              }}
              source={require("../assets/calender.png")}
            />
          </Pressable>
            <View style={styles.details}>
              <Text style={styles.toptext}>{locationDetails?.locationname}</Text>
            </View>
            <Image
              style={styles.sliderhorizontal3Icon}
              contentFit="cover"
              source={require("../assets/sliderhorizontal3.png")}
            />
          </View>
        </View>
      </View>
      <ScrollView
        style={styles.scrollable}
        showsVerticalScrollIndicator={false}
        showsHorizontalScrollIndicator={false}
        contentContainerStyle={styles.scrollableScrollViewContent}
      >
        <Pressable
          style={styles.revenuecardswinsbets}
          onPress={() => navigation.navigate("Log")}
        >
          <View style={[styles.dashWinsCard, styles.devicecardBorder]}>
            <Image
              style={[styles.pluscircleIcon, styles.iconLayout]}
              contentFit="cover"
              source={require("../assets/pluscircle.png")}
            />
            <View style={styles.content}>
              <View style={styles.details1}>
                <View>
                  <Text style={[styles.today1, styles.today1Clr]}>Today</Text>
                  <Text style={[styles.text, styles.textTypo]}>
                    +${totalsData[3].billin_total}.00
                  </Text>
                </View>
                <View style={styles.total}>
                  <Text style={[styles.today1, styles.today1Clr]}>
                    Past 7 Days
                  </Text>
                  <Text style={[styles.text, styles.textTypo]}>+${totalsData[0].billin_total}.00</Text>
                </View>
              </View>
              <View style={styles.details1}>
                <View>
                  <Text style={[styles.today1, styles.today1Clr]}>Past Month</Text>
                  <Text style={[styles.text, styles.textTypo]}>
                    +${totalsData[1].billin_total}.00
                  </Text>
                </View>
                <View style={styles.total}>
                  <Text style={[styles.today1, styles.today1Clr]}>
                    All Time
                  </Text>
                  <Text style={[styles.text, styles.textTypo]}>+${totalsData[2].billin_total}.00</Text>
                </View>
              </View>
              {/*   <View style={styles.graph} />   */}
            </View>
          </View>
        </Pressable>
        <View
          style={[styles.revenuecardswinsbets1, styles.devicecardSpaceBlock]}
        >
          <View style={[styles.dashWinsCard, styles.devicecardBorder]}>
            <Image
              style={[styles.pluscircleIcon, styles.iconLayout]}
              contentFit="cover"
              source={require("../assets/minuscircle.png")}
            />
            <View style={styles.content}>
              <View style={styles.details1}>
                <View>
                  <Text style={[styles.today1, styles.today1Clr]}>Ticket Out Today</Text>
                  <Text style={[styles.text, styles.textTypo]}>
                    -${totalsData[3].ticketout_total}.00
                  </Text>
                </View>
                <View style={styles.total}>
                  <Text style={[styles.today1, styles.today1Clr]}>
                    Past 7 Days
                  </Text>
                  <Text style={[styles.text, styles.textTypo]}>-${totalsData[0].ticketout_total}.00</Text>
                </View>
              </View>
              <View style={styles.details1}>
                <View>
                  <Text style={[styles.today1, styles.today1Clr]}>Past Month</Text>
                  <Text style={[styles.text, styles.textTypo]}>
                    -${totalsData[1].ticketout_total}.00
                  </Text>
                </View>
                <View style={styles.total}>
                  <Text style={[styles.today1, styles.today1Clr]}>
                    All Time
                  </Text>
                  <Text style={[styles.text, styles.textTypo]}>-${totalsData[2].ticketout_total}.00</Text>
                </View>
              </View>
              {/*   <View style={styles.graph} />   */}
            </View>
          </View>
        </View>


      { /*

        <View
          style={[styles.revenuecardswinsbets1, styles.devicecardSpaceBlock]}
        >
          <View style={styles.winscardBorder}>
            <View style={[styles.content2, styles.content2FlexBox]}>
              <View style={styles.details3}>
                <Text style={[styles.today1, styles.today1Clr]}>Today</Text>
                <Text style={[styles.text, styles.textTypo]}>+$126.30</Text>
                <Text style={[styles.totalWins, styles.textTypo]}>
                  Total Wins
                </Text>
                <Text style={[styles.text, styles.textTypo]}>$1011.70</Text>
              </View>
              <View style={styles.leticon}>
                <Image
                  style={[styles.circleIcon, styles.iconLayout]}
                  contentFit="cover"
                  source={require("../assets/circle1.png")}
                />
                <Text style={[styles.letter, styles.letterFlexBox]}>W</Text>
              </View>
            </View>
          </View>
          <View style={[styles.betscard, styles.winscardBorder]}>
            <View style={[styles.content2, styles.content2FlexBox]}>
              <View style={styles.details3}>
                <Text style={[styles.today1, styles.today1Clr]}>Today</Text>
                <Text style={[styles.text, styles.textTypo]}>-$120.25</Text>
                <Text style={[styles.totalWins, styles.textTypo]}>
                  Total Bets
                </Text>
                <Text style={[styles.text, styles.textTypo]}>-$1203.49</Text>
              </View>
              <View style={styles.leticon}>
                <Image
                  style={[styles.circleIcon, styles.iconLayout]}
                  contentFit="cover"
                  source={require("../assets/circle1.png")}
                />
                <Text style={[styles.letter, styles.letterFlexBox]}>B</Text>
              </View>
            </View>
          </View>
        </View>

        */}

      {deviceDetails.map((item, index) => (
        <Pressable
          key={item.device.deviceid}
          style={[styles.devicecard, styles.devicecardSpaceBlock]}
          onPress={() => navigation.navigate("DeviceDetail", { deviceid: item.device.deviceid })}
        >
          <Image
            style={styles.deviceimgIcon}
            contentFit="cover"
            source={require("../assets/locationimg2.png")}
          />
          <View style={styles.content4}>
            <Text style={[styles.devicetitle, styles.letterFlexBox]}>
            {item.device.devicename}
            </Text>
            <View style={styles.details5}>
              <View style={styles.details6}>
                <Text
                  style={[styles.text8, styles.text8Typo]}
                >{`Location `}</Text>
                <View style={styles.moneyinframe2}>
                  <Image
                    style={styles.pluscircleIcon1}
                    contentFit="cover"
                    source={require("../assets/pluscircle2.png")}
                  />
                  <Text
                    style={[styles.moneyinval, styles.text8Typo]}
                  > ${item.totals24h[0].total_billin_amount} /24h</Text>
                </View>
                <View style={styles.moneyinframe}>
                  <Text style={[styles.moneyinval, styles.text8Typo]}>
                  ${item.totalsMonth[0].total_billin_amount} /month
                  </Text>
                </View>
              </View>
              <View style={styles.details7}>
                <View style={styles.details6}>
                  <View style={styles.leticon2}>
                    <Image
                      style={styles.circleIcon2}
                      contentFit="cover"
                      source={require("../assets/minuscircle1.png")}
                    />
                  </View>
                  <Text
                    style={[styles.moneyinval, styles.text8Typo]}
                  > ${item.totals24h[0].total_ticketout_amount} /24h</Text>
                </View>
                <View style={styles.moneyoutframe1}>
                  <Text style={[styles.moneyinval, styles.text8Typo]}>
                  ${item.totalsMonth[0].total_ticketout_amount} /month
                  </Text>
                </View>
              </View>
            </View>
          </View>
        </Pressable>
        ))}



        {/*}
        <Pressable
          style={[styles.moredevicesbtn, styles.devicecardSpaceBlock]}
          onPress={() => {}}
        >
          <Text style={styles.moreDevices}>More Devices</Text>
        </Pressable>
        */}



        <View style={[styles.devicedetailscard, styles.devicecardSpaceBlock]}>
          <Text style={styles.toptext}>{locationDetails?.locationname} details</Text>
          <View style={styles.detailitemgroup}>
            <Text style={[styles.today1, styles.today1Clr]}>{locationDetails?.locationname}</Text>
            <Text style={[styles.bodyText, styles.detailTypo]}>{locationDetails?.locationaddress}</Text>
            <Text style={[styles.bodyText, styles.detailTypo]}>{locationDetails?.locationcity}</Text>
            <Text style={[styles.bodyText, styles.detailTypo]}>{locationDetails?.locationstate}</Text>
            <Text style={[styles.bodyText, styles.detailTypo]}>{locationDetails?.locationzip}</Text>
            <Text style={[styles.bodyText, styles.detailTypo]}>{locationDetails?.locationcountry}</Text>
          </View>
          {/*<Text style={[styles.detail, styles.detailTypo]}>Detail</Text>*/}
          
          {tenDaysData && (
        <View style={styles.tableContainer}>
          <Text style={styles.tableHeader}>Ten Days Data</Text>
          <ScrollView horizontal>
            <View style={styles.table}>
              {tenDaysData.map((item, index) => (
                <View key={index} style={styles.tableRow}>
                  <Text>{new Date(item.day).toLocaleDateString('en-US', { month: 'numeric', day: 'numeric' })}</Text>
                  <Text>{item.billin_total}</Text>
                  <Text>{item.ticketout_total}</Text>
                  <Text>{item.net_total}</Text>
                </View>
              ))}


            </View>
          </ScrollView>
        </View>
      )}
        </View>
      <View style={{ height: 60 }}></View>
      </ScrollView>
    </View>
  );
};

const styles = StyleSheet.create({
  totalsContainer: {
    marginTop: 20,
    padding: 10,
    backgroundColor: Color.colorGainsboro_100,
    borderRadius: Border.br_smi,
  },
  totalsHeader: {
    fontWeight: 'bold',
    fontSize: 18,
    marginBottom: 10,
  },
  tableContainer: {
    marginTop: 20,
    width: '100%',
  },
  tableHeader: {
    fontWeight: 'bold',
    fontSize: 18,
    marginBottom: 10,
  },
  table: {
    borderWidth: 1,
    borderColor: Color.colorGainsboro_100,
  },
  tableRow: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: 10,
    borderBottomWidth: 1,
    borderBottomColor: Color.colorGainsboro_100,
    width: 300,
  },
  centered: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  scrollableScrollViewContent: {
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "flex-start",
  },
  topbarPosition: {
    backgroundColor: Color.colorGold,
    left: "0%",
    right: "0%",
    position: "absolute",
    width: "100%",
  },
  content2FlexBox: {
    justifyContent: "space-between",
    flexDirection: "row",
  },
  devicecardBorder: {
    padding: Padding.p_base,
    borderWidth: 1.3,
    borderColor: Color.colorGainsboro_100,
    borderStyle: "solid",
    borderRadius: Border.br_smi,
  },
  iconLayout: {
    height: 20,
    zIndex: 0,
    width: 20,
  },
  today1Clr: {
    color: Color.labelColorLightSecondary,
    lineHeight: 18,
    fontSize: FontSize.size_base,
  },
  textTypo: {
    marginTop: 2,
    fontFamily: FontFamily.interBold,
    fontWeight: "700",
    textAlign: "left",
    letterSpacing: 0,
  },
  devicecardSpaceBlock: {
    marginTop: 11,
    alignSelf: "stretch",
  },
  letterFlexBox: {
    display: "flex",
    letterSpacing: 0,
    alignItems: "center",
  },
  winscardBorder: {
    height: 124,
    padding: Padding.p_base,
    borderWidth: 1.3,
    borderColor: Color.colorGainsboro_100,
    borderStyle: "solid",
    backgroundColor: Color.systemBackgroundLightPrimary,
    borderRadius: Border.br_smi,
    flexDirection: "row",
    flex: 1,
  },
  text8Typo: {
    fontSize: FontSize.size_smi,
    color: Color.labelColorLightSecondary,
    fontFamily: FontFamily.interBold,
    fontWeight: "700",
    lineHeight: 18,
    textAlign: "left",
    letterSpacing: 0,
  },
  detailTypo: {
    fontFamily: FontFamily.interRegular,
    color: Color.labelColorLightSecondary,
    lineHeight: 18,
    fontSize: FontSize.size_base,
    textAlign: "left",
    letterSpacing: 0,
  },
  statusbar: {
    height: "62.77%",
    top: "0%",
    bottom: "37.23%",
    alignItems: "flex-end",
    justifyContent: "center",
  },
  icon: {
    height: "100%",
    width: "100%",
  },
  hamburger: {
    height: 15,
    width: 20,
  },
  toptext: {
    textAlign: "left",
    fontFamily: FontFamily.interSemiBold,
    fontWeight: "600",
    color: Color.labelColorLightPrimary,
    lineHeight: 25,
    letterSpacing: 0,
    fontSize: FontSize.size_xl,
  },
  details: {
    paddingHorizontal: Padding.p_mini,
    paddingVertical: 0,
    flexDirection: "row",
    flex: 1,
  },
  sliderhorizontal3Icon: {
    width: 21,
    height: 18,
  },
  topbarinner: {
    height: 35,
    paddingLeft: Padding.p_8xs,
    paddingRight: Padding.p_xs,
    paddingBottom: Padding.p_3xs,
    alignSelf: "stretch",
    alignItems: "center",
  },
  topbar: {
    height: "37.23%",
    top: "62.77%",
    bottom: "0%",
    overflow: "hidden",
    alignItems: "center",
  },
  top: {
    height: 94,
    alignSelf: "stretch",
  },
  pluscircleIcon: {
    top: 14,
    right: 14,
    zIndex: 0,
    position: "absolute",
  },
  today1: {
    fontFamily: FontFamily.interBold,
    fontWeight: "700",
    textAlign: "left",
    letterSpacing: 0,
  },
  text: {
    color: Color.labelColorLightPrimary,
    lineHeight: 25,
    marginTop: 2,
    fontSize: FontSize.size_xl,
  },
  total: {
    marginLeft: 15,
  },
  details1: {
    flexDirection: "row",
  },
  graph: {
    backgroundColor: Color.colorGainsboro_200,
    height: 70,
    marginTop: 10,
    alignSelf: "stretch",
  },
  content: {
    marginLeft: 10,
    zIndex: 1,
    flex: 1,
  },
  dashWinsCard: {
    backgroundColor: Color.systemBackgroundLightPrimary,
    borderWidth: 1.3,
    borderColor: Color.colorGainsboro_100,
    borderStyle: "solid",
    borderRadius: Border.br_smi,
    flexDirection: "row",
    flex: 1,
  },
  revenuecardswinsbets: {
    flexDirection: "row",
    alignSelf: "stretch",
    alignItems: "center",
  },
  revenuecardswinsbets1: {
    flexDirection: "row",
    alignItems: "center",
  },
  totalWins: {
    color: Color.labelColorLightSecondary,
    lineHeight: 18,
    fontSize: FontSize.size_base,
  },
  details3: {
    zIndex: 0,
    flex: 1,
  },
  circleIcon: {
    zIndex: 0,
  },
  letter: {
    top: 1,
    right: 0,
    fontSize: FontSize.size_sm,
    height: 17,
    textAlign: "center",
    color: Color.colorGray_100,
    display: "flex",
    lineHeight: 18,
    zIndex: 1,
    fontFamily: FontFamily.interSemiBold,
    fontWeight: "600",
    justifyContent: "center",
    position: "absolute",
    width: 20,
  },
  leticon: {
    top: 0,
    left: 136,
    zIndex: 1,
    flexDirection: "row",
    position: "absolute",
    alignItems: "center",
  },
  content2: {
    height: 99,
    flex: 1,
  },
  betscard: {
    marginLeft: 9,
  },
  deviceimgIcon: {
    borderRadius: Border.br_12xs,
    width: 60,
    height: 60,
  },
  devicetitle: {
    fontSize: FontSize.size_mini,
    lineHeight: 20,
    width: 262,
    fontFamily: FontFamily.interBold,
    fontWeight: "700",
    textAlign: "left",
    color: Color.labelColorLightPrimary,
  },
  text8: {
    display: "none",
    width: 92,
    alignItems: "center",
  },
  pluscircleIcon1: {
    width: 15,
    height: 15,
  },
  moneyinval: {
    marginLeft: 1,
  },
  moneyinframe: {
    marginLeft: 10,
    flexDirection: "row",
    alignItems: "center",
  },
  moneyinframe2: {
    marginLeft: 0,
    flexDirection: "row",
    alignItems: "center",
  },
  details6: {
    flexDirection: "row",
    alignItems: "center",
  },
  circleIcon2: {
    width: 15,
    zIndex: 0,
    height: 15,
  },
  letter2: {
    top: 2,
    left: 0,
    fontSize: FontSize.size_2xs,
    height: 12,
    width: 15,
    textAlign: "center",
    color: Color.colorGray_100,
    display: "flex",
    lineHeight: 18,
    zIndex: 1,
    fontFamily: FontFamily.interSemiBold,
    fontWeight: "600",
    justifyContent: "center",
    position: "absolute",
  },
  leticon2: {
    flexDirection: "row",
    alignItems: "center",
  },
  moneyoutframe1: {
    marginLeft: 20,
    flexDirection: "row",
    alignItems: "center",
  },
  details7: {
    marginTop: 1,
    flexDirection: "row",
    alignSelf: "stretch",
    alignItems: "center",
  },
  details5: {
    width: 288,
  },
  content4: {
    width: 288,
    marginLeft: 10,
  },
  devicecard: {
    padding: Padding.p_base,
    borderWidth: 1.3,
    borderColor: Color.colorGainsboro_100,
    borderStyle: "solid",
    borderRadius: Border.br_smi,
    backgroundColor: Color.systemBackgroundLightPrimary,
    flexDirection: "row",
    overflow: "hidden",
  },
  moreDevices: {
    lineHeight: 22,
    fontWeight: "500",
    fontFamily: FontFamily.interMedium,
    color: Color.colorGray_300,
    textAlign: "center",
    letterSpacing: 0,
    fontSize: FontSize.size_xl,
  },
  moredevicesbtn: {
    backgroundColor: Color.colorGainsboro_100,
    height: 39,
    padding: Padding.p_base,
    borderWidth: 1.3,
    borderColor: Color.colorGainsboro_100,
    borderStyle: "solid",
    borderRadius: Border.br_smi,
    marginTop: 11,
    flexDirection: "row",
    overflow: "hidden",
    justifyContent: "center",
    alignItems: "center",
  },
  bodyText: {
    marginTop: 5,
  },
  detailitemgroup: {
    marginTop: 20,
    justifyContent: "center",
  },
  detail: {
    marginTop: 20,
  },
  devicedetailscard: {
    padding: Padding.p_base,
    borderWidth: 1.3,
    borderColor: Color.colorGainsboro_100,
    borderStyle: "solid",
    borderRadius: Border.br_smi,
    backgroundColor: Color.systemBackgroundLightPrimary,
    justifyContent: "center",
  },
  scrollable: {
    padding: Padding.p_2xs,
    maxWidth: "100%",
    width: "100%",
    flex: 1,
  },
  locationDetail: {
    backgroundColor: Color.colorGhostwhite,
    alignItems: "center",
    width: "100%",
    flex: 1,
  },
});

export default LocationDetail;