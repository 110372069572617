import { supabase } from './supabase'

export async function getLocationBasic(locationId) {
  try {

    const { data: locationDetails, error: locationError } = await supabase
      .from('locations')
      .select(`
        locationname, 
        locationaddress, 
        locationcity, 
        locationstate, 
        locationzip, 
        locationcountry, 
        locationimg,
        devices
      `)
      .eq('locationid', locationId)
      .single();

    if (locationError) {
      throw locationError;
    }

    if (!locationDetails) {
      throw new Error('Location details could not be found');
    }

    return locationDetails;
  } catch (error) {
    console.error('Error fetching location details:', error);
    throw error;
  }
}