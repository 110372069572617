import React from 'react';
import { View, StyleSheet, Platform } from 'react-native';
import { Color } from "../GlobalStyles";

const TopBar = ({ children }) => {
  return (
    <View style={styles.top}>
      {Platform.OS !== 'web' && <View style={styles.statusbar} />}
      <View style={styles.topbar}>
        <View style={styles.topbarinner}>
          {children}
        </View>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  top: {
    height: Platform.OS === 'web' ? 50 : 94,
    alignSelf: "stretch",
  },
  statusbar: {
    height: 20,
    backgroundColor: Color.colorGold,
  },
  topbar: {
    flex: 1,
    backgroundColor: Color.colorGold,
    justifyContent: "center",
    alignItems: "center",
  },
  topbarinner: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    paddingHorizontal: 16,
    paddingVertical: 10,
  },
});

export default TopBar;