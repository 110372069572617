import React, { useState, useEffect } from "react";
import { Linking, StyleSheet, View, Text, Pressable, TextInput, Alert, Platform } from "react-native";
import { supabase } from "../lib/supabase";
import { Image } from "expo-image";
import { Padding, Color, Border, FontSize, FontFamily } from "../GlobalStyles";
import { AppState } from 'react-native';

const Login = ({ navigation }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);

  // Handling AppState change for Supabase Auth 
  useEffect(() => {
    const subscriber = AppState.addEventListener("change", (state) => {
      if (state === "active") {
        supabase.auth.startAutoRefresh();
      } else {
        supabase.auth.stopAutoRefresh();
      }
    });

    // Cleanup subscription on unmount
    return () => {
      subscriber.remove();
    };
  }, []);

  async function signInWithEmail() {
    setLoading(true);
    const { error } = await supabase.auth.signInWithPassword({
      email: email,
      password: password,
    });

    if (!error) {
      // Use platform-specific alert
      Platform.OS === 'web' ? alert('Success') : Alert.alert('Success');
      navigation.navigate('DrawerRoot');
    }

    if (error) {
      Platform.OS === 'web' ? alert(error.message) : Alert.alert(error.message);
    }
    setLoading(false);
  }

  async function signUpWithEmail() {
    setLoading(true);
    const { error } = await supabase.auth.signUp({
      email: email,
      password: password,
    });

    if (error) {
      Platform.OS === 'web' ? alert(error.message) : Alert.alert(error.message);
    }
    if (!error) {
      Platform.OS === 'web' 
        ? alert("Please check your inbox for email verification!") 
        : Alert.alert("Please check your inbox for email verification!");
    }
    setLoading(false);
  }
  return (
    <View style={styles.login}>
      <View style={styles.statusbar} />
      <View style={styles.frame}>
        <View style={styles.dashOrgWelcomeCard}>
          <View style={styles.contentFlexBox}>
            <View style={styles.details}>
              <Text style={styles.indietix}>SGK</Text>
              <Text style={styles.smartvenue}>Analytics Login</Text>
            </View>
          </View>
          <Pressable
            style={styles.globebadgechevronbackwardParent}
            onPress={() => Linking.openURL("https://skillgamekiosk.com")}
          >
            <Image
              style={styles.globebadgechevronbackwardIcon}
              contentFit="cover"
              source={require("../assets/globebadgechevronbackward.png")}
            />
            <Text style={styles.indietixorg}>SGK</Text>
          </Pressable>
        </View>
      </View>
      <View style={styles.login1}>
        <Text style={styles.logIn}>Log In</Text>
        <View style={[styles.stripePayment, styles.payButtonBorder]}>
          <Text style={[styles.email, styles.emailTypo]}>Email</Text>
          <TextInput
            style={[styles.customername, styles.passwordSpaceBlock]}
            onChangeText={(text) => setEmail(text)}
            value={email}
            placeholder="Email"
            keyboardType="email-address"
            placeholderTextColor="rgba(60, 60, 67, 0.3)"
          />
          <Text style={[styles.password, styles.passwordSpaceBlock]}>
            Password
          </Text>
          <TextInput
            style={[styles.customername, styles.passwordSpaceBlock]}
            onChangeText={(text) => setPassword(text)}
            value={password}
            placeholder="Password"
            secureTextEntry={true}
            keyboardType="default"
            autoCapitalize="none"
            placeholderTextColor="rgba(60, 60, 67, 0.3)"
          />
        </View>
        <Pressable
          style={[styles.payButton, styles.payButtonBorder]}
          disabled={loading}
          onPress={signInWithEmail}
        >
          <Text style={[styles.logIn1, styles.dashTypo]}>Log In</Text>
        </Pressable>

        {/* Added a simple button for signing up */}
        <Pressable
          style={[styles.payButton, styles.payButtonBorder, { marginTop: 10 }]}
          disabled={loading}
          onPress={signUpWithEmail}
        >
          <Text style={[styles.logIn1, styles.dashTypo]}>Sign Up</Text>
        </Pressable>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  payButtonBorder: {
    marginTop: 11,
    padding: Padding.p_base,
    borderWidth: 1.3,
    borderColor: Color.colorGainsboro,
    borderStyle: "solid",
    borderRadius: Border.br_smi,
    alignSelf: "stretch",
  },
  emailTypo: {
    lineHeight: 20,
    color: Color.colorGray_200,
    fontSize: FontSize.size_xl,
    fontFamily: FontFamily.interSemiBold,
    fontWeight: "600",
    textAlign: "left",
    letterSpacing: 0,
  },
  passwordSpaceBlock: {
    marginTop: 10,
    alignSelf: "stretch",
  },
  dashTypo: {
    fontFamily: FontFamily.interMedium,
    fontWeight: "500",
    textAlign: "center",
  },
  contentFlexBox: {
    justifyContent: "space-between",
    alignItems: "center",
    flexDirection: "row",
    flex: 1,
  },
  iconLayout: {
    height: 20,
    width: 20,
  },
  statusbar: {
    width: 393,
    height: 59,
    alignItems: "flex-end",
    justifyContent: "center",
    backgroundColor: Color.colorGhostwhite,
  },
  indietix: {
    fontSize: FontSize.size_base,
    lineHeight: 18,
    color: Color.labelColorLightSecondary,
    textAlign: "left",
    fontFamily: FontFamily.interRegular,
    letterSpacing: 0,
  },
  smartvenue: {
    color: Color.labelColorLightPrimary,
    marginTop: 2,
    fontFamily: FontFamily.interSemiBold,
    fontWeight: "600",
    lineHeight: 25,
    fontSize: FontSize.size_mid,
    textAlign: "left",
    letterSpacing: 0,
  },
  details: {
    flex: 1,
  },
  globebadgechevronbackwardIcon: {
    width: 21,
    height: 18,
  },
  indietixorg: {
    fontSize: FontSize.size_6xs,
    lineHeight: 10,
    color: Color.colorGray_100,
    display: "flex",
    height: 13,
    textAlign: "center",
    width: 51,
    fontFamily: FontFamily.interRegular,
    letterSpacing: 0,
    alignItems: "center",
    justifyContent: "center",
  },
  globebadgechevronbackwardParent: {
    height: 45,
    marginLeft: 10,
    justifyContent: "flex-end",
    width: 51,
    alignItems: "center",
  },
  dashOrgWelcomeCard: {
    padding: Padding.p_base,
    borderWidth: 1.3,
    borderColor: Color.colorGainsboro,
    borderStyle: "solid",
    borderRadius: Border.br_smi,
    flexDirection: "row",
    backgroundColor: Color.labelColorDarkPrimary,
    overflow: "hidden",
    alignSelf: "stretch",
  },
  frame: {
    paddingHorizontal: Padding.p_2xs,
    paddingVertical: 0,
    overflow: "hidden",
    alignSelf: "stretch",
  },
  logIn: {
    color: Color.colorGray_200,
    fontSize: FontSize.size_xl,
    fontFamily: FontFamily.interSemiBold,
    fontWeight: "600",
    lineHeight: 25,
    textAlign: "left",
    letterSpacing: 0,
  },
  email: {
    alignSelf: "stretch",
  },
  customername: {
    borderRadius: Border.br_3xs,
    backgroundColor: Color.fillColorLightTertiary,
    paddingHorizontal: Padding.p_5xs,
    paddingVertical: Padding.p_6xs,
    fontSize: FontSize.size_mid,
    marginTop: 10,
    fontFamily: FontFamily.interRegular,
    alignItems: "center",
    flexDirection: "row",
  },
  password: {
    lineHeight: 20,
    color: Color.colorGray_200,
    fontSize: FontSize.size_xl,
    fontFamily: FontFamily.interSemiBold,
    fontWeight: "600",
    textAlign: "left",
    letterSpacing: 0,
  },
  stripePayment: {
    backgroundColor: Color.labelColorDarkPrimary,
  },
  logIn1: {
    lineHeight: 22,
    color: Color.labelColorDarkPrimary,
    fontSize: FontSize.size_xl,
    fontWeight: "500",
    letterSpacing: 0,
    height: 22,
  },
  payButton: {
    backgroundColor: Color.colorDeepskyblue_100,
    height: 43,
    alignItems: "center",
    flexDirection: "row",
    overflow: "hidden",
    justifyContent: "center",
  },
  login1: {
    padding: Padding.p_2xs,
    alignItems: "center",
    alignSelf: "stretch",
    justifyContent: "center",
  },
  dash: {
    fontSize: FontSize.size_smi,
    color: Color.colorLightslategray,
    marginTop: 14,
  },
  bottomTab: {
    width: 61,
    alignItems: "center",
  },
  eventIcon: {
    opacity: 0.8,
  },
  bottomTabs: {
    paddingHorizontal: Padding.p_base,
    paddingTop: Padding.p_base,
    paddingBottom: Padding.p_16xl,
    backgroundColor: Color.labelColorDarkPrimary,
    alignSelf: "stretch",
  },
  tabbar: {
    justifyContent: "flex-end",
    flexDirection: "row",
    alignSelf: "stretch",
  },
  login: {
    width: "100%",
    flex: 1,
    backgroundColor: Color.colorGhostwhite,
  },
});

export default Login;
