import * as React from "react";
import { useState, useEffect, useCallback } from 'react';
import { getDeviceDetails } from "../lib/dev";
import { StyleSheet, View, Pressable, Text, ScrollView, ActivityIndicator, RefreshControl, Alert } from "react-native";
import { Image } from "expo-image";
import { DrawerNavigationProp } from "@react-navigation/drawer";
import { useNavigation, ParamListBase, useRoute, useFocusEffect  } from "@react-navigation/native";
import { StackNavigationProp } from "@react-navigation/stack";
import { Color, Padding, Border, FontSize, FontFamily } from "../GlobalStyles";
import { useOrg } from '../lib/useOrg'
import { getCombinedTotalsByOrg, getLocationsByOrgId, getDevicesByOrgId } from "../lib/dash";

const Locations = () => {
  const navigation = useNavigation<DrawerNavigationProp<ParamListBase>>();
  const route = useRoute();
  const { currentOrganization } = useOrg();
  const [locations, setLocations] = useState([]);
  const [loading, setLoading] = useState(true);
  const [refreshing, setRefreshing] = useState(false);
  const [error, setError] = useState('');

  console.log(JSON.stringify(currentOrganization?.orgid));

  const fetchData = async () => {
    if (!currentOrganization?.orgid) {
      setError("Waiting for organization ID...");
      return;
    }

    setLoading(true);
    setRefreshing(true); 
    try {
      const [fetchedTotals, fetchedLocations] = await Promise.all([
        getCombinedTotalsByOrg(currentOrganization.orgid),
        getLocationsByOrgId(currentOrganization.orgid, 1000),
      ]);
      setLocations(fetchedLocations);
      setError('');
    } catch (e) {
      console.error(e);
      setError("Error fetching data, will retry...");
    } finally {
      setLoading(false);
      setRefreshing(false); // Stop the refresh animation
    }
  };

  /*
  useEffect(() => {
    let intervalId;
    // Set up an interval to keep trying
    intervalId = setInterval(fetchData, 5000); // Retry every 5 seconds
    fetchData(); // Also invoke immediately
    // Clean up the interval on component unmount
    return () => clearInterval(intervalId);
  }, [currentOrganization?.orgid]);
  */

  useFocusEffect(
    useCallback(() => {
      fetchData();
    }, [currentOrganization?.orgid])
  );

  const onRefresh = () => {
    fetchData();
  };

  if (loading) {
    return (
      <View style={styles.centered}>
        <ActivityIndicator size="large" color="#0000ff" />
      </View>
    );
  }

  if (error) {
    return (
      <View style={styles.centered}>
        <Text>{error}</Text>
      </View>
    );
  }

  return (
    <View style={styles.deviceDetail}>
      <View style={styles.top}>
        <View style={[styles.statusbar, styles.topbarPosition]} />
        <View style={[styles.topbar, styles.topbarPosition]}>
          <View style={styles.topbarinner}>
          <Pressable
            style={{
              width: 60, // Set a specific width for the button
              height: 60, // Set a specific height for the button
              justifyContent: 'center',
              alignItems: 'center',
              padding: 10, // Optional: additional padding
              backgroundColor: 'transparent', // Ensure background is transparent
            }}
            onPress={() => navigation.goBack()}
          >
            <Image
              style={{
                width: '60%', // Make the image fill the button
                height: '60%', // Make the image fill the button
                resizeMode: 'contain', // Ensure the image maintains its aspect ratio
              }}
              source={require("../assets/calender.png")}
            />
          </Pressable>
            <View style={styles.details}>
              <Text style={styles.toptext}>All Locations</Text>
            </View>

            {/*}
            <Image
              style={styles.sliderhorizontal3Icon}
              contentFit="cover"
              source={require("../assets/sliderhorizontal3.png")}
            />*/}
          </View>
        </View>
      </View>
      <ScrollView
        style={styles.scrollable}
        showsVerticalScrollIndicator={false}
        showsHorizontalScrollIndicator={false}
        contentContainerStyle={styles.scrollableScrollViewContent}
        refreshControl={
          <RefreshControl
            refreshing={refreshing}
            onRefresh={onRefresh}
          />
        }
      >
        {locations.map((location) => (
          <Pressable
            key={location.locationid}
            style={styles.locationsFlexBox}
            onPress={() => navigation.navigate("LocationDetail", { locationid: location.locationid })}
          >
            <Pressable
              style={[styles.locationcard, styles.dashCardBorder]}
              onPress={() => navigation.navigate("LocationDetail", { locationid: location.locationid })}
            >
              <Image
                style={styles.locationimgIcon}
                contentFit="cover"
                source={require("../assets/deviceimg.png")}
              />
              <View
                style={[styles.devicecardcontent, styles.devicecardcontentLayout]}
              >
                <Text style={[styles.locationName, styles.locationNameLayout]}>
                  {location.locationname}
                </Text>
                <View style={styles.details3}>
                  <View style={styles.moneyinframe}>
                    <Text style={styles.text4Typo}>{location.locationname}</Text>
                  </View>
                  <View style={styles.moneyinframe}>
                    <Image
                      style={styles.pluscircleIcon1}
                      contentFit="cover"
                      source={require("../assets/pluscircle2.png")}
                    />
                    <Text
                      style={[styles.nodevicesval, styles.text4Typo]}
                    >${/* device?.total_billin_amount */}</Text>
                  </View>
                  <View style={styles.moneyinframe}>
                    <Image
                      style={styles.pluscircleIcon1}
                      contentFit="cover"
                      source={require("../assets/minuscircle2.png")}
                    />
                    <Text style={[styles.nodevicesval, styles.text4Typo]}>
                      ${/* device.total_ticketout_amount */}
                    </Text>
                  </View>
                </View>
              </View>
            </Pressable>
          </Pressable>
        ))}
      </ScrollView>
    </View>
  );
};

const styles = StyleSheet.create({
  centered: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  scrollableScrollViewContent: {
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "flex-start",
  },
  moneyinframe: {
    marginLeft: 10,
    flexDirection: "row",
    alignItems: "center",
  },
  text4Typo: {
    fontSize: FontSize.size_smi,
    color: Color.labelColorLightSecondary,
    fontFamily: FontFamily.interBold,
    fontWeight: "700",
    lineHeight: 18,
    textAlign: "left",
    letterSpacing: 0,
  },
  locationName: {
    fontSize: FontSize.size_mini,
    display: "flex",
    width: 262,
    fontFamily: FontFamily.interBold,
    fontWeight: "700",
    textAlign: "left",
    color: Color.labelColorLightPrimary,
    alignItems: "center",
  },
  locationNameLayout: {
    lineHeight: 20,
    letterSpacing: 0,
  },
  devicecardcontentLayout: {
    width: 288,
    marginLeft: 10,
  },
  devicecardcontent: {
    height: 41,
    marginLeft: 10,
  },
  dashCardBorder: {
    padding: Padding.p_base,
    borderWidth: 1.3,
    borderColor: Color.colorGainsboro_100,
    borderStyle: "solid",
    borderRadius: Border.br_smi,
    flexDirection: "row",
  },
  locationsFlexBox: {
    marginTop: 11,
    alignSelf: "stretch",
    alignItems: "center",
  },
  topbarPosition: {
    backgroundColor: Color.colorGold,
    left: "0%",
    right: "0%",
    position: "absolute",
    width: "100%",
  },
  locationcardBorder: {
    padding: Padding.p_base,
    borderWidth: 1.3,
    borderColor: Color.colorGainsboro_100,
    borderStyle: "solid",
    backgroundColor: Color.systemBackgroundLightPrimary,
    borderRadius: Border.br_smi,
  },
  textTypo: {
    color: Color.labelColorLightSecondary,
    lineHeight: 18,
    fontSize: FontSize.size_smi,
    fontFamily: FontFamily.interBold,
    fontWeight: "700",
    textAlign: "left",
    letterSpacing: 0,
  },
  letterFlexBox: {
    textAlign: "center",
    color: Color.colorGray_100,
    zIndex: 1,
    lineHeight: 18,
    display: "flex",
    fontFamily: FontFamily.interSemiBold,
    fontWeight: "600",
    letterSpacing: 0,
    justifyContent: "center",
    position: "absolute",
    alignItems: "center",
  },
  devicedetailscardSpaceBlock: {
    marginTop: 11,
    alignSelf: "stretch",
  },
  iconLayout: {
    height: 20,
    zIndex: 0,
    width: 20,
  },
  leticon2Position: {
    right: 14,
    top: 14,
    position: "absolute",
  },
  detailTypo: {
    fontFamily: FontFamily.interRegular,
    fontSize: FontSize.size_base,
    color: Color.labelColorLightSecondary,
    lineHeight: 18,
    textAlign: "left",
    letterSpacing: 0,
  },
  statusbar: {
    height: "62.77%",
    top: "0%",
    bottom: "37.23%",
    alignItems: "flex-end",
    justifyContent: "center",
  },
  icon: {
    height: "100%",
    width: "100%",
  },
  hamburger: {
    height: 15,
    width: 20,
  },
  toptext: {
    textAlign: "left",
    color: Color.labelColorLightPrimary,
    fontFamily: FontFamily.interSemiBold,
    fontWeight: "600",
    lineHeight: 25,
    letterSpacing: 0,
    fontSize: FontSize.size_xl,
  },
  details: {
    paddingHorizontal: Padding.p_mini,
    paddingVertical: 0,
    flexDirection: "row",
    flex: 1,
  },
  sliderhorizontal3Icon: {
    width: 21,
    height: 18,
  },
  topbarinner: {
    height: 35,
    justifyContent: "space-between",
    paddingLeft: Padding.p_8xs,
    paddingRight: Padding.p_xs,
    paddingBottom: Padding.p_3xs,
    flexDirection: "row",
    alignSelf: "stretch",
    alignItems: "center",
  },
  topbar: {
    height: "37.23%",
    top: "62.77%",
    bottom: "0%",
    overflow: "hidden",
    alignItems: "center",
  },
  top: {
    height: 94,
    alignSelf: "stretch",
  },
  locationimgIcon: {
    borderRadius: Border.br_12xs,
    width: 42,
    height: 42,
  },
  locationtitle: {
    fontSize: FontSize.size_mini,
    lineHeight: 20,
    width: 262,
    display: "flex",
    fontFamily: FontFamily.interBold,
    fontWeight: "700",
    textAlign: "left",
    color: Color.labelColorLightPrimary,
    letterSpacing: 0,
    alignItems: "center",
  },
  text: {
    display: "none",
    width: 92,
    alignItems: "center",
  },
  lanyardcardIcon: {
    width: 11,
    height: 15,
  },
  nodevicesval: {
    marginLeft: 1,
  },
  nodevicesframe: {
    marginLeft: 10,
    flexDirection: "row",
    alignItems: "center",
  },
  pluscircleIcon: {
    height: 20,
    width: 20,
  },
  details2: {
    flexDirection: "row",
    alignItems: "center",
  },
  circleIcon: {
    zIndex: 0,
    width: 15,
    height: 15,
  },
  letter: {
    top: 2,
    left: 0,
    fontSize: FontSize.size_2xs,
    height: 12,
    zIndex: 1,
    width: 15,
  },
  leticon: {
    flexDirection: "row",
    alignItems: "center",
  },
  moneyoutframe1: {
    marginLeft: 20,
    flexDirection: "row",
    alignItems: "center",
  },
  details3: {
    marginTop: 1,
    flexDirection: "row",
    alignSelf: "stretch",
    alignItems: "center",
  },
  details1: {
    width: 288,
  },
  content: {
    marginLeft: 10,
    width: 288,
  },
  locationcard: {
    backgroundColor: Color.systemBackgroundLightPrimary,
    padding: Padding.p_base,
    alignSelf: "stretch",
  },
  pluscircleIcon1: {
    width: 15,
    height: 15,
  },
  today1: {
    fontSize: FontSize.size_base,
    color: Color.labelColorLightSecondary,
    lineHeight: 18,
    fontFamily: FontFamily.interBold,
    fontWeight: "700",
    textAlign: "left",
    letterSpacing: 0,
  },
  text1: {
    marginTop: 2,
    fontFamily: FontFamily.interBold,
    fontWeight: "700",
    textAlign: "left",
    color: Color.labelColorLightPrimary,
    lineHeight: 25,
    letterSpacing: 0,
    fontSize: FontSize.size_xl,
  },
  total: {
    marginLeft: 15,
  },
  details4: {
    flexDirection: "row",
  },
  graph: {
    backgroundColor: Color.colorGainsboro_200,
    height: 70,
    marginTop: 10,
    alignSelf: "stretch",
  },
  content1: {
    zIndex: 1,
    marginLeft: 10,
    flex: 1,
  },
  dashWinsCard: {
    flexDirection: "row",
    flex: 1,
  },
  revenuecardswinsbets: {
    flexDirection: "row",
    alignItems: "center",
  },
  letter2: {
    top: 1,
    right: 0,
    fontSize: FontSize.size_sm,
    height: 17,
    zIndex: 1,
    width: 20,
  },
  leticon2: {
    zIndex: 0,
    flexDirection: "row",
    alignItems: "center",
  },
  bodyText: {
    marginTop: 5,
  },
  detailitemgroup: {
    marginTop: 20,
    justifyContent: "center",
  },
  detail: {
    marginTop: 20,
  },
  devicedetailscard: {
    padding: Padding.p_base,
    borderWidth: 1.3,
    borderColor: Color.colorGainsboro_100,
    borderStyle: "solid",
    backgroundColor: Color.systemBackgroundLightPrimary,
    borderRadius: Border.br_smi,
    justifyContent: "center",
  },
  scrollable: {
    padding: Padding.p_2xs,
    maxWidth: "100%",
    width: "100%",
    flex: 1,
  },
  deviceDetail: {
    backgroundColor: Color.colorGhostwhite,
    alignItems: "center",
    width: "100%",
    flex: 1,
  },
});

export default Locations;
