import React, { useState, useEffect } from "react";
import {
  StyleSheet,
  View,
  Pressable,
  Text,
  ScrollView,
  TextInput,
  TouchableOpacity,
  Dimensions,
  Button,
  Alert
} from "react-native";
import { Image } from "expo-image";
import { useNavigation, ParamListBase, useRoute } from "@react-navigation/native";
import { DrawerNavigationProp } from "@react-navigation/drawer";
import { Color, Padding, Border, FontFamily, FontSize } from "../GlobalStyles";
//import { BarCodeScanner } from 'expo-barcode-scanner';
import { supabase } from "../lib/supabase";

const windowWidth = Dimensions.get('window').width;
const cameraSize = Math.min(windowWidth, 500);
const API_URL = 'https://script.google.com/macros/s/AKfycbw4vElmq7Yylc0u4s_xjCNZMssYQRHC5iR_mHR2FCz7jkxh_r9ISGO0mubHBY5rGVaWxQ/exec';

const InventoryQR = () => {
  const navigation = useNavigation<DrawerNavigationProp<ParamListBase>>();
  const route = useRoute();

  /*
  const [deviceId, setDeviceId] = useState('');
  const [scanned, setScanned] = useState(false);
  const [hasPermission, setHasPermission] = useState(false);

  useEffect(() => {
    const getPermissions = async () => {
      const { status } = await BarCodeScanner.requestPermissionsAsync();
      setHasPermission(status === 'granted');
    };
    getPermissions();
  }, []);

  const parseQRData = (data) => {
    const match = data.match(/http:\/\/website.com\/page\/(\w+)/);
    return match ? match[1] : null;
  };

  const handleBarCodeScanned = async ({ type, data }) => {
    if (scanned) return;
    setScanned(true);
  
    const qrIdFromCode = parseQRData(data);
    const identifier = qrIdFromCode || data;
    const param = qrIdFromCode ? 'id' : 'serial';
  
    try {
      const response = await fetch(`${API_URL}?username=chowx&key=xxx&${param}=${identifier}`);
      const result = await response.json();
  
      if (!result.error) {
        navigation.navigate('ItemDetails', {
          qrId: qrIdFromCode ? identifier : result.ColumnA, // Ensure qrId is set correctly
          name: result.ColumnB,
          serial: result.ColumnC,
          details: result.ColumnD
        });
      } else {
        navigation.navigate('ItemDetails', {
          qrId: qrIdFromCode ? identifier : '',
          serial: qrIdFromCode ? '' : identifier,
          name: '',
          details: ''
        });
        Alert.alert("New Entry", "The item is not in the database, please add new details.");
      }
    } catch (err) {
      console.error('Error fetching item details:', err);
      Alert.alert('Error', 'Failed to fetch item details.');
    } finally {
      setTimeout(() => setScanned(false), 3000);
    }
  };

  const handleManualEntry = () => {
    if (!deviceId) {
      Alert.alert("Error", "Please enter a valid QRID or Serial Number");
      return;
    }
    const idType = parseQRData(deviceId) ? 'QRID' : 'serial';
    fetchItemDetails(deviceId, idType);
  };

  if (!hasPermission) {
    return (
      <View style={{ marginTop: 300 }}>
        <Text>We need your permission to show the camera</Text>
        <Button onPress={() => setHasPermission(true)} title="Grant Permission" />
      </View>
    );
  }*/

  return (
    <View style={styles.addDevicedetails}>
      <View style={styles.top}>
        <View style={[styles.statusbar, styles.topbarPosition]} />
        <View style={[styles.topbar, styles.topbarFlexBox]}>
          <View style={styles.topbarinner}>
            <Pressable
              style={{
                width: 60,
                height: 60,
                justifyContent: 'center',
                alignItems: 'center',
                padding: 10,
                backgroundColor: 'transparent',
              }}
              onPress={() => navigation.goBack()}
            >
              <Image
                style={{
                  width: '60%',
                  height: '60%',
                  resizeMode: 'contain',
                }}
                source={require("../assets/calender.png")}
              />
            </Pressable>
            <View style={styles.details}>
              <Text style={[styles.toptext, styles.toptextFlexBox]}>
                Add Board ID
              </Text>
            </View>
          </View>
        </View>
      </View>

      <ScrollView
        style={styles.frame}
        showsVerticalScrollIndicator={false}
        showsHorizontalScrollIndicator={false}
        contentContainerStyle={styles.frameScrollViewContent}
      >
        {/*}
        <BarCodeScanner
          onBarCodeScanned={scanned ? undefined : handleBarCodeScanned}
          style={styles.camera}
        />
        <Text style={styles.deviceDetails}>Scan Device ID QR</Text>
        <Text style={[styles.toptextFlexBox]}>Or Enter Below</Text>
        <View style={[styles.devicedetailsform, styles.errorcardBorder]}>
          <Text style={[styles.name, styles.nameTypo]}>Device ID</Text>
          <TextInput
            style={[styles.devicename, styles.detailSpaceBlock]}
            placeholder="QRID or Serial"
            placeholderTextColor="rgba(60, 60, 67, 0.3)"
            value={deviceId}
            onChangeText={setDeviceId}
          />
        </View>
        <Pressable
          style={[styles.adddevicebutton, styles.errorcardBorder]}
          onPress={handleManualEntry}
        >
          <Text style={[styles.addDevice, styles.addDeviceFlexBox]}>Continue</Text>
        </Pressable>*/}
      </ScrollView>
    </View>
  );
};

const styles = StyleSheet.create({
  camera: {
    width: cameraSize,
    height: cameraSize,
  },
  frameScrollViewContent: {
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "flex-start",
  },
  topbarPosition: {
    backgroundColor: Color.colorGold,
    position: "absolute",
    width: "100%",
  },
  topbarFlexBox: {
    overflow: "hidden",
    alignItems: "center",
  },
  toptextFlexBox: {
    textAlign: "left",
    letterSpacing: 0,
  },
  addDeviceFlexBox: {
    textAlign: "center",
    letterSpacing: 0,
  },
  errorcardBorder: {
    margin: 7,
    padding: Padding.p_base,
    borderWidth: 1.3,
    borderColor: Color.colorGainsboro_100,
    borderStyle: "solid",
    borderRadius: Border.br_smi,
    alignSelf: "stretch",
  },
  nameTypo: {
    lineHeight: 20,
    color: Color.colorGray_200,
    textAlign: "left",
    fontFamily: FontFamily.interSemiBold,
    fontWeight: "600",
    letterSpacing: 0,
    fontSize: FontSize.size_xl,
  },
  detailSpaceBlock: {
    marginTop: 10,
    alignSelf: "stretch",
  },
  statusbar: {
    height: "62.77%",
    top: "0%",
    bottom: "37.23%",
    alignItems: "flex-end",
    justifyContent: "center",
  },
  toptext: {
    color: Color.labelColorLightPrimary,
    fontFamily: FontFamily.interSemiBold,
    fontWeight: "600",
    lineHeight: 25,
    textAlign: "left",
    letterSpacing: 0,
    fontSize: FontSize.size_xl,
  },
  details: {
    paddingHorizontal: Padding.p_mini,
    paddingVertical: 0,
    flexDirection: "row",
    flex: 1,
  },
  topbarinner: {
    height: 35,
    justifyContent: "space-between",
    paddingLeft: Padding.p_8xs,
    paddingRight: Padding.p_xs,
    paddingBottom: Padding.p_3xs,
    flexDirection: "row",
    alignItems: "center",
    alignSelf: "stretch",
  },
  topbar: {
    height: "37.23%",
    top: "62.77%",
    bottom: "0%",
    alignItems: "center",
    backgroundColor: Color.colorGold,
    left: "0%",
    right: "0%",
    position: "absolute",
    width: "100%",
  },
  top: {
    height: 94,
    alignSelf: "stretch",
  },
  deviceDetails: {
    color: Color.colorGray_200,
    marginTop: 11,
    textAlign: "left",
    fontFamily: FontFamily.interSemiBold,
    fontWeight: "600",
    lineHeight: 25,
    letterSpacing: 0,
    fontSize: FontSize.size_xl,
  },
  name: {
    alignSelf: "stretch",
  },
  devicename: {
    borderRadius: Border.br_3xs,
    backgroundColor: Color.fillColorLightTertiary,
    paddingHorizontal: Padding.p_5xs,
    paddingVertical: Padding.p_6xs,
    fontSize: FontSize.size_mid,
    fontFamily: FontFamily.interRegular,
    flexDirection: "row",
    alignItems: "center",
  },
  devicedetailsform: {
    backgroundColor: Color.systemBackgroundLightPrimary,
    marginTop: 11,
  },
  addDevice: {
    lineHeight: 21,
    fontWeight: "500",
    fontFamily: FontFamily.interMedium,
    color: Color.systemBackgroundLightPrimary,
    textAlign: "center",
    fontSize: FontSize.size_xl,
    height: 18
  },
  adddevicebutton: {
    backgroundColor: Color.colorDeepskyblue,
    height: 43,
    flexDirection: "row",
    alignItems: "center",
    overflow: "hidden",
    justifyContent: "center",
  },
  frame: {
    paddingBottom: Padding.p_2xl,
    marginBottom: 20,
    alignSelf: "stretch",
    flex: 1,
  },
  addDevicedetails: {
    backgroundColor: Color.colorGhostwhite,
    width: "100%",
    flex: 1,
  },
});

export default InventoryQR;